import { gql } from "@apollo/client"

export const ACTIVE_GROUPS = gql`
  query ActiveGroups($take: Int, $skip: Int) {
    aggregateCompany(where: { AND: [{ group: { equals: true } }, { active: { equals: true } }] }) {
      _count {
        _all
      }
    }
    companies(where: { AND: [{ group: { equals: true } }, { active: { equals: true } }] }, orderBy: { name: asc }, take: $take, skip: $skip) {
      id
      active
      name
      urlName
      groupies {
        id
      }
      category
      careerPage
      createdAt
      policyAccepted
      employeeCount
      activeRecruitmentsCount
      closedRecruitmentsCount
      messageLogCount
      hasApplications
      hasAddons
      hasChannels
      jobbetContacts {
        id
      }
      subscription
    }
  }
`
export const INACTIVE_GROUPS = gql`
  query InactiveGroups {
    companies(where: { AND: [{ group: { equals: true } }, { active: { equals: false } }] }, orderBy: { name: asc }) {
      id
      active
      name
      urlName
      category
      careerPage
      createdAt
      policyAccepted
      employeeCount
      activeRecruitmentsCount
      closedRecruitmentsCount
      messageLogCount
      hasApplications
      hasAddons
      hasChannels
      jobbetContacts {
        id
      }
      subscription
    }
  }
`
export const DELETE_GROUP = gql`
  mutation DeleteCompany($companyId: String!) {
    deleteFullCompany(id: $companyId) {
      id
    }
  }
`

export const JOBBET_USERS_QUERY = gql`
  query UserQuery {
    users(where: { role: { in: [SUPERADMIN, SITEADMIN] }, disabled: { equals: false } }) {
      id
      firstName
      lastName
    }
  }
`
