import React from "react"
import { graphql, withApollo } from "@apollo/client/react/hoc"
import { compose } from "react-recompose"
import { NetworkStatus } from "@apollo/client"
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import sv from "date-fns/locale/sv"
import styled from "styled-components"
import map from "lodash/fp/map"
import isEmpty from "lodash/fp/isEmpty"
import orderBy from "lodash/fp/orderBy"
import find from "lodash/fp/find"
import remove from "lodash/fp/remove"
import filter from "lodash/fp/filter"
import flow from "lodash/fp/flow"
import { format, parseISO, isAfter, isBefore } from "date-fns"
import { ACTIVE_COMPANIES, INACTIVE_COMPANIES, DELETE_COMPANY, JOBBET_USERS_QUERY } from "./companies-ql"
import { isLive } from "_services/util"
import to from "_services/await.to"
import inject from "_services/inject"
import MainLayout from "_layout/main-layout"
import { Spinner, Checkbox, Radio, SmallSpinner, FlexRow } from "_layout/form-elements"
import { SecondaryButton, CancelButton } from "_layout/buttons"
import ObjectPicker from "_components/object-picker"
import DropDown from "_components/drop-down"
import Modal from "_components/modal"
import DateButton from "_components/date-button"
import message from "_components/message"
import confirm from "_components/confirm"
import TrashIcon from "_images/trash.svg"
import CompanyMailing from "_root/containers/admin/company-mailing"
import CompanyMessageLog from "_root/containers/admin/company-messagelog"
import { PrimaryButton } from "_layout/buttons"
import { HAS_SUBSCRIPTION, NO_SUBSCRIPTION, UserRoles, symbols } from "_root/constants"

//#region Styles
const Error = styled.p`
  margin: -1em 0 1em 0;
  font-size: 0.9em;
  color: var(--color-brand-red);
`
const Arrow = styled.div`
  display: none;
  width: 8px;
  height: 8px;
  margin-top: -4px;
  margin-left: auto;
  background-color: var(--color-bg-white);
  border-bottom: 2px solid var(--color-line-dark);
  border-right: 2px solid var(--color-line-dark);

  &.asc {
    display: inline-block;
    transform: rotate(45deg);
  }

  &.desc {
    display: inline-block;
    margin-top: 4px;
    transform: rotate(225deg);
  }
`
const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1;

  .heading {
    display: block;
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
  }

  .shadow {
    box-shadow: 0px 3px 5px var(--color-bg-grey);
  }

  .moreButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    margin: 20px auto 0 auto;
  }
`
const Rows = styled.div`
  flex: 1;
  margin: 0;
  overflow-y: scroll;
`
const Row = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
  height: 56px;

  &.header {
    height: 35px;
    background: var(--color-bg-white);
    color: var(--color-text-dark);
    box-shadow: 0px 3px 5px var(--color-bg-grey);
    z-index: 1;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    cursor: pointer;
  }
`
const Trash = styled.a`
  display: inline-block;
  visibility: hidden;
  margin-left: 1em;
  width: 20px;
  height: 20px;
  opacity: 0.4;
  background: url(${TrashIcon}) no-repeat scroll 50% 50%;
  background-size: 16px 16px;

  &:hover {
    opacity: 1;
  }
`
const TitleCell = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 15px 5px 10px;
  color: var(--color-text-dark);
  background: var(--color-bg-lighter);
  border-left: 1px solid var(--color-line-light);
  border-bottom: 1px solid var(--color-line-light);

  .header & {
    border: none;
    background: var(--color-bg-white);

    &:hover {
      background: var(--color-bg-light);

      ${Arrow} {
        background-color: var(--color-bg-light);
      }
    }
  }
  .actions {
    position: absolute;
    top: 15px;
    right: 7px;
    bottom: 50%;
    width: 25px;
    height: 25px;

    .actionSymbol {
      display: inline-block;
      width: 20px;
      height: 20px;
      margin: 0 5px 5px 0;
      cursor: pointer;

      > img {
        max-width: 100%;
      }

      svg {
        width: 100%;
        height: 100%;
        fill: none;
        stroke: var(--color-bg-grey);
        stroke-miterlimit: 10;
        stroke-width: 0.5px;
      }

      .message svg {
        stroke: #027874;
        fill: #027874;
      }
    }
  }

  &:hover {
    ${Trash} {
      visibility: visible;
    }
  }
`
const CompanyLink = styled.a`
  color: ${props => (!props.$active ? "var(--color-text-medium)" : props.$groups ? "var(--color-brand-green)" : null)};
  text-decoration: ${props => (props.$active ? null : "line-through")};
`
const InfoArea = styled.div`
  flex: 2;
  display: flex;
  flex-flow: row nowrap;
  justify-content: stretch;
`
const InfoCell = styled.div`
  position: relative;
  flex: ${props => props.$flex || "1"};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid var(--color-line-light);
  border-left: 1px solid var(--color-line-light);
  background: var(--color-bg-white);

  ${Row}.header & {
    border: none;
  }

  &:last-child {
    border-right: 1px solid var(--color-line-light);
  }

  &.date {
    color: var(--color-text);
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
  }

  &.grade {
    .grade-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      color: var(--color-text);
      background: var(--color-bg-light);
      font-size: 18px;
      line-height: 24px;
      font-weight: 200;
    }
  }

  & .drop-down {
    visibility: hidden;
    position: absolute;
    top: 50%;
    right: 5px;
    margin-top: -16px;

    &.open {
      visibility: visible;
    }
  }

  &:hover {
    .drop-down {
      visibility: visible;
    }
  }

  .header & {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    cursor: pointer;
    padding: 0 10px;

    &:hover {
      background: var(--color-bg-light);

      ${Arrow} {
        background: var(--color-bg-light);
      }
    }
  }

  .content & {
    padding: 5px 10px;
  }

  .menu {
    pointer-events: auto;
    opacity: 0;
    visibility: hidden;
    padding: 5px 0;
    box-shadow: 1px 1px 3px var(--color-line-dark);

    &.type-light.border {
      border: 1px solid var(--color-line-dark);

      &.place-bottom:before {
        border-bottom-color: var(--color-line-dark);
      }
      &.place-top:before {
        border-top-color: var(--color-line-dark);
      }

      &.show {
        opacity: 1;
        visibility: visible;
      }
    }

    &:hover {
      opacity: 1;
      visibility: visible;
    }
  }
`
const Actions = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: stretch;
  align-items: stretch;
  margin-bottom: 20px;
`
const ButtonBox = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  flex: 1 1 auto;

  button {
    margin-right: 1rem;
    border-radius: 20px;
  }
`
const SelectionBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  padding: 30px;
  background: var(--color-bg-light);
  box-shadow: 0px 0px 10px var(--color-bg-light);
  ${props => (props.$flex ? `flex: ${props.$flex};` : "")}

  &:first-child {
    margin-right: 5px;
  }

  .react-datepicker-popper {
    z-index: 1000;
  }

  .right {
    margin-left: auto;
  }
`
const BoxTitle = styled.h4`
  align-self: flex-start;
  margin-top: 0;
  margin-bottom: 20px;
  font-weight: 400;
  text-transform: uppercase;

  .spinner-container {
    display: inline-flex;
    margin-left: 20px;
  }
`
const FilterList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;

  li {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 0;
    white-space: nowrap;
  }
`
//#endregion Styles

const BATCH = isLive() ? 100 : 50

const messageLogSymbol = {
  id: "__autoML",
  editable: false,
  name: "message",
  text: "Meddelande skickat",
}

const getCompanyCategory = c => {
  switch (c.category) {
    case "Okategoriserad":
      return 0
    case "Idealkund":
      return 1
    case "JA-kund":
      return 2
    case "K-kund":
      return 3
    default:
      return 0
  }
}

const getCompanySubscription = c => {
  switch (c.subscription) {
    case NO_SUBSCRIPTION:
      return 0
    case HAS_SUBSCRIPTION:
      return 1
    default:
      return 0
  }
}

const getCompanyState = c => {
  if (!c.policyAccepted) return 0
  if (c.hasAddons) return 4
  if (c.hasChannels) return 3
  if (c.hasApplications) return 2
  return 1
}

const actionSymbols = companies => {
  const aSymbols = companies.map(c => {
    let symbols = []
    if (c.messageLogCount > 0) {
      symbols.push(messageLogSymbol)
    }
    return {
      id: c.id,
      symbols,
    }
  })
  return remove(a => a.symbols.length === 0)(aSymbols)
}

@compose(
  withApollo,
  inject("user"),
  graphql(JOBBET_USERS_QUERY, { name: "jobbetUsersQuery" }),
  graphql(ACTIVE_COMPANIES, { name: "companiesQuery", options: props => ({ fetchPolicy: "network-only", notifyOnNetworkStatusChange: true, variables: { take: BATCH, skip: 0 } }) }),
  graphql(DELETE_COMPANY, { name: "deleteCompany" })
)
export default class AdminCompanies extends React.Component {
  constructor(props) {
    super(props)
    registerLocale("sv", sv)
    this.state = this.initialState
  }

  initialFilter = {
    createdAfter: null,
    createdBefore: null,
    contact: null,
    category: {
      0: true,
      1: true,
      2: true,
      3: true,
    },
    subscription: {
      0: true,
      1: true,
    },
    hiddenSubscription: [],
    hiddenCategory: [],
    state: {
      0: true,
      1: true,
      2: true,
      3: true,
      4: true,
    },
    careerPage: {
      true: true,
      false: true,
    },
    hiddenState: [],
    hiddenCareerPage: [],
    enabled: true,
    disabled: false,
  }

  initialState = {
    loading: true,
    allLoaded: false,
    errors: [],
    companies: [],
    allCompanies: [],
    allCompaniesCount: 0,
    activeCompaniesCount: 0,
    selected: [],
    sortOrder: {
      name: "asc",
    },
    selectAll: false,
    categorySelect: false,
    subscriptionSelect: false,
    stateSelect: false,
    careerPageSelect: false,
    filter: { ...this.initialFilter },
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    let nextState = {}
    const error = nextProps.companiesQuery.error
    if (error) {
      nextState.errors = [
        {
          key: "companiesQuery.error",
          message: "Kunder kunde inte laddas",
          details: error,
        },
      ]
      console.error("companiesQuery.error: " + error)
    } else {
      if (!nextProps.companiesQuery.loading && nextProps.companiesQuery.aggregateCompany._count._all !== prevState.activeCompaniesCount) {
        nextState.allCompaniesCount = nextState.activeCompaniesCount = nextProps.companiesQuery.aggregateCompany._count._all
        if (nextProps.companiesQuery.companies && nextProps.companiesQuery.companies.length === nextState.allCompaniesCount) {
          nextState.allLoaded = true
        }
      }
      const loading = nextProps.companiesQuery.networkStatus !== NetworkStatus.ready
      if (prevState.loading !== loading) {
        nextState.loading = loading
      }
      if (prevState.networkStatus !== nextProps.companiesQuery.networkStatus) {
        nextState.networkStatus = nextProps.companiesQuery.networkStatus
        if (!loading) {
          if (prevState.allCompanies.length !== nextProps.companiesQuery.companies.length || !!nextState.allCompaniesCount) {
            nextState.allCompanies = nextProps.companiesQuery.companies
            if (nextState.allCompanies.length === prevState.activeCompaniesCount) {
              nextState.allLoaded = true
            }
          }
        }
      }
    }
    return !isEmpty(nextState) ? nextState : null
  }

  componentDidMount = () => {
    if (!this.state.loading && !this.state.companies) {
      this.filterCompanies()
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if ((!this.state.loading && prevState.loading) || this.state.allCompanies.length !== prevState.allCompanies.length) {
      this.filterCompanies()
    }
  }

  onFetchMoreResults = () => {
    const { companies, fetchMore } = this.props.companiesQuery

    fetchMore({
      variables: {
        take: BATCH,
        skip: companies.length,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult || !fetchMoreResult.companies || !fetchMoreResult.companies.length) {
          this.setState({ allLoaded: true })
          return prev
        }
        return Object.assign({}, prev, {
          companies: [...prev.companies, ...fetchMoreResult.companies],
        })
      },
    })
  }

  filterCompanies() {
    const { state } = this
    if (state.loading || !state.allCompanies.length) return
    const funcs = [
      filter(c => {
        let included = (state.filter.enabled && c.active) || (state.filter.disabled && !c.active)
        if (included && state.filter.createdAfter) {
          included = isAfter(parseISO(c.createdAt), state.filter.createdAfter)
        }
        if (included && state.filter.createdBefore) {
          included = isBefore(parseISO(c.createdAt), state.filter.createdBefore)
        }
        return included
      }),
      map(c => {
        return {
          ...c,
          subscription: getCompanySubscription(c),
          category: getCompanyCategory(c),
          state: getCompanyState(c),
        }
      }),
    ]
    if (state.filter.hiddenSubscription.length) {
      funcs.push(filter(c => state.filter.hiddenSubscription.indexOf(c.subscription) < 0))
    }
    if (state.filter.hiddenCategory.length) {
      funcs.push(filter(c => state.filter.hiddenCategory.indexOf(c.category) < 0))
    }
    if (state.filter.hiddenState.length) {
      funcs.push(filter(c => state.filter.hiddenState.indexOf(c.state) < 0))
    }
    if (state.filter.hiddenCareerPage.length) {
      funcs.push(filter(c => state.filter.hiddenCareerPage.indexOf(c.careerPage) < 0))
    }
    if (state.filter.contact) {
      funcs.push(filter(c => c.jobbetContacts.map(jc => jc.id).includes(state.filter.contact.id)))
    }
    if (state.sortOrder.name) {
      funcs.push(orderBy(c => c.name.toLowerCase())([state.sortOrder.name]))
    }
    if (state.sortOrder.createdAt) {
      funcs.push(orderBy(c => c.createdAt)([state.sortOrder.createdAt]))
    }
    if (state.sortOrder.employees) {
      funcs.push(orderBy(c => c.employeeCount)([state.sortOrder.employees]))
    }
    if (state.sortOrder.activeRecruitments) {
      funcs.push(orderBy(c => c.activeRecruitmentsCount)([state.sortOrder.activeRecruitments]))
    }
    if (state.sortOrder.closedRecruitments) {
      funcs.push(orderBy(c => c.closedRecruitmentsCount)([state.sortOrder.closedRecruitments]))
    }
    if (state.sortOrder.category) {
      funcs.push(orderBy(c => c.category)([state.sortOrder.category]))
    }
    if (state.sortOrder.state) {
      funcs.push(orderBy(c => c.state)([state.sortOrder.state]))
    }
    if (state.sortOrder.careerPage) {
      funcs.push(orderBy(c => c.careerPage)([state.sortOrder.careerPage]))
    }
    let companies = flow(funcs)(state.allCompanies)
    const companyActionSymbols = actionSymbols(companies)
    if (companyActionSymbols.length) {
      companies = map(c => {
        let actions = [].concat.apply([], map("symbols")(filter(s => s.id === c.id)(companyActionSymbols)))
        return { ...c, actions }
      })(companies)
    }
    this.setState({ companies })
  }

  setSortOrder = prop => {
    const order = this.state.sortOrder[prop] !== "desc" ? "desc" : "asc"
    let sortOrder = {}
    sortOrder[prop] = order
    this.setState({ sortOrder }, this.filterCompanies)
  }

  selectAll = e => {
    let selected = []
    if (e.target.checked) {
      selected = [...this.state.companies]
    }
    this.setState({ selectAll: e.target.checked, selected })
  }

  selectRow = e => {
    let selected = [...this.state.selected]
    const id = e.target.value
    if (e.target.checked) {
      if (!find(f => f.id === id)(selected)) {
        const company = find(f => f.id === id)(this.state.companies)
        selected.push(company)
      }
    } else {
      selected = remove(c => c.id === id)(selected)
    }
    this.setState({ selectAll: false, selected })
  }

  filterCreatedAt = (createdAtFilter, value) => {
    const filter = this.state.filter
    filter[createdAtFilter] = value
    this.setState({ filter }, this.filterCompanies)
  }

  filterSubscription = e => {
    const filter = { ...this.state.filter, subscription: { ...this.state.filter.subscription } }
    filter.subscription[e.target.value] = e.target.checked
    filter.hiddenSubscription = Object.entries(filter.subscription)
      .filter(e => !e[1])
      .map(e => parseInt(e[0], 10))
    this.setState({ filter }, this.filterCompanies)
  }

  filterCategory = e => {
    const filter = { ...this.state.filter, category: { ...this.state.filter.category } }
    filter.category[e.target.value] = e.target.checked
    filter.hiddenCategory = Object.entries(filter.category)
      .filter(e => !e[1])
      .map(e => parseInt(e[0], 10))
    this.setState({ filter }, this.filterCompanies)
  }

  filterState = e => {
    const filter = { ...this.state.filter, state: { ...this.state.filter.state } }
    filter.state[e.target.value] = e.target.checked
    filter.hiddenState = Object.entries(filter.state)
      .filter(e => !e[1])
      .map(e => parseInt(e[0], 10))
    this.setState({ filter }, this.filterCompanies)
  }

  filterCareerPage = e => {
    const filter = { ...this.state.filter, careerPage: { ...this.state.filter.careerPage } }
    filter.careerPage[e.target.value] = e.target.checked
    filter.hiddenCareerPage = Object.entries(filter.careerPage)
      .filter(e => !e[1])
      .map(e => e[0] === "true")
    this.setState({ filter }, this.filterCompanies)
  }

  filterJobbetContact = contact => {
    const filter = { ...this.state.filter, contact: contact }
    this.setState({ filter }, this.filterCompanies)
  }

  clearFilter = () => {
    this.setState({ filter: { ...this.initialFilter } }, this.filterCompanies)
  }

  hasWriteAccess = () => {
    return this.props.user && [UserRoles.GROUPVIEWER, UserRoles.VIEWER].includes(this.props.user.role) === false
  }

  onModalClose = e => {
    this.closeModal()
  }

  sendEmail = e => {
    this.openModal("email")
  }

  openModal = dialog => {
    let newState = {
      emailModalOpen: false,
      messageLogModalOpen: false,
    }
    switch (dialog) {
      case "email":
        newState.emailModalOpen = true
        break
      case "messageLog":
        newState.messageLogModalOpen = true
        break
      default:
        break
    }
    this.setState(newState)
  }

  closeModal = () => {
    this.openModal(null)
  }

  onCompanyAction(row, action) {
    switch (action) {
      case "message":
        this.setState({ messageLogParentId: row.id })
        this.openModal("messageLog")
        return
      default:
        return
    }
  }

  setEnabled = () => {
    const filter = { ...this.state.filter, disabled: false, enabled: true }
    const allCompanies = this.props.companiesQuery.companies
    this.setState({ filter: filter, allCompanies, allCompaniesCount: allCompanies.length }, this.filterCompanies)
  }

  setDisabled = async () => {
    const filter = { ...this.state.filter, disabled: true, enabled: false }
    let allCompanies
    const [error, result] = await to(this.props.client.query({ query: INACTIVE_COMPANIES }))
    if (error) {
      console.error("companies:setDisabled:error: ", error)
    }
    allCompanies = result && result.data ? result.data.companies : this.props.companiesQuery.companies
    this.setState({ filter: filter, allCompanies: [...allCompanies], allCompaniesCount: allCompanies.length }, this.filterCompanies)
  }

  toggleSubscription = () => {
    this.setState({ subscriptionSelect: !this.state.subscriptionSelect })
  }

  toggleCategory = () => {
    this.setState({ categorySelect: !this.state.categorySelect })
  }

  toggleState = () => {
    this.setState({ stateSelect: !this.state.stateSelect })
  }

  toggleCareerPage = () => {
    this.setState({ careerPageSelect: !this.state.careerPageSelect })
  }

  deleteCompany = async e => {
    const company = find(c => c.id === e.target.dataset.company)(this.state.companies)
    const confirmed = await confirm('Vill du ta bort företaget "' + company.name + '"?', { confirmText: "Ta bort" })
    if (!confirmed) return
    const [error] = await to(this.props.deleteCompany({ variables: { companyId: company.id } }))
    if (error) {
      message("Företaget kunde inte raderas. Kontrollera så att inte företaget är kopplat till en rekrytering el. dyl.")
    } else {
      message(`Företaget "${company.name}" har raderats.`)
      const { companies, allCompanies } = this.state
      remove(c => c.id === company.id)(companies)
      remove(c => c.id === company.id)(allCompanies)
      this.setState({ companies, allCompanies })
      this.props.companiesQuery.refetch()
    }
  }

  mailingOnUpdate = () => {
    this.setState({ selectAll: false, selected: [], loading: true }, () => {
      this.props.companiesQuery.refetch()
    })
  }

  categoryText = category => {
    switch (category) {
      case 0:
        return "Okategoriserad"
      case 1:
        return "Idealkund"
      case 2:
        return "JA-kund"
      case 3:
        return "K-kund"
      default:
        return "-"
    }
  }

  subscriptionText = subscription => {
    switch (subscription) {
      case 0:
        return NO_SUBSCRIPTION
      case 1:
        return HAS_SUBSCRIPTION
      default:
        return "-"
    }
  }

  stateText = state => {
    switch (state) {
      case 0:
        return "Ej godkänt"
      case 1:
        return "Godkänt"
      case 2:
        return "Aktiv rekrytering"
      case 3:
        return "Annonsering / search"
      case 4:
        return "Rekryteringstjänst"
      default:
        return "-"
    }
  }

  render() {
    const {
      loading,
      errors,
      sortOrder,
      companies,
      selectAll,
      categorySelect,
      subscriptionSelect,
      stateSelect,
      careerPageSelect,
      selected,
      filter,
      emailModalOpen,
      messageLogModalOpen,
      messageLogParentId,
      allLoaded,
      networkStatus,
      allCompaniesCount,
    } = this.state

    const initialLoading = networkStatus === NetworkStatus.loading
    const noActions = loading ? true : selected.length < 1

    if (errors.length > 0) {
      return (
        <MainLayout padding maxHeight scrollAlways>
          {map(e => <Error key={e.key}>{e.message}</Error>)(errors)}
        </MainLayout>
      )
    }

    let jobbetUsers = this.props.jobbetUsersQuery.users || []
    jobbetUsers = jobbetUsers.map(u => {
      return { ...u, name: `${u.firstName} ${u.lastName}` }
    })

    return (
      <MainLayout padding maxHeight scrollAlways>
        <Wrapper>
          <h2>Företag</h2>
          <Actions>
            <SelectionBox $flex="1">
              <BoxTitle>
                Filter
                {loading && <SmallSpinner />}
                {!loading && (
                  <span>
                    {" "}
                    (visar {companies.length} av {allCompaniesCount})
                  </span>
                )}
              </BoxTitle>
              {allLoaded && (
                <React.Fragment>
                  <FlexRow $middle $nowrap className="mb3">
                    <span className="ttu mr3">Skapat:</span>
                    <DatePicker
                      isClearable={true}
                      customInput={<DateButton label="Från" />}
                      selected={filter.createdAfter}
                      maxDate={filter.createdBefore}
                      onChange={date => this.filterCreatedAt("createdAfter", date)}
                      locale="sv"
                      dateFormat="yy-MM-dd"
                      showWeekNumbers
                    />
                    <div className="mh3">&mdash;</div>
                    <DatePicker
                      isClearable={true}
                      customInput={<DateButton label="Till" />}
                      selected={filter.createdBefore}
                      minDate={filter.createdAfter}
                      onChange={date => this.filterCreatedAt("createdBefore", date)}
                      locale="sv"
                      dateFormat="yy-MM-dd"
                      showWeekNumbers
                    />
                  </FlexRow>
                  <FlexRow>
                    <DropDown small label="Avtal" open={subscriptionSelect} toggle={this.toggleSubscription}>
                      <FilterList>
                        {map(n => (
                          <li key={n}>
                            <Checkbox value={n} onChange={this.filterSubscription} checked={filter.subscription[n]} label={this.subscriptionText(n)} />
                          </li>
                        ))([0, 1])}
                      </FilterList>
                    </DropDown>
                    <DropDown small label="Kundkategori" open={categorySelect} toggle={this.toggleCategory}>
                      <FilterList>
                        {map(n => (
                          <li key={n}>
                            <Checkbox value={n} onChange={this.filterCategory} checked={filter.category[n]} label={this.categoryText(n)} />
                          </li>
                        ))([0, 1, 2, 3])}
                      </FilterList>
                    </DropDown>
                    <DropDown className="ml2" small label="Företagsstatus" open={stateSelect} toggle={this.toggleState}>
                      <FilterList>
                        {map(n => (
                          <li key={n}>
                            <Checkbox value={n} onChange={this.filterState} checked={filter.state[n]} label={this.stateText(n)} />
                          </li>
                        ))([0, 1, 2, 3, 4])}
                      </FilterList>
                    </DropDown>
                    <DropDown className="ml2" small label="Karriärsida" open={careerPageSelect} toggle={this.toggleCareerPage}>
                      <FilterList>
                        {map(n => (
                          <li key={n}>
                            <Checkbox value={n} onChange={this.filterCareerPage} checked={filter.careerPage[n]} label={n ? "Ja" : "Nej"} />
                          </li>
                        ))([true, false])}
                      </FilterList>
                    </DropDown>
                    <ObjectPicker
                      form={"true"}
                      className="mb3 shadow"
                      current={filter.contact}
                      values={jobbetUsers}
                      placeholder="Välj person..."
                      onSelected={this.filterJobbetContact}
                      noMatch="Inga träffar"
                    />
                  </FlexRow>
                  <FlexRow $middle>
                    <Radio name="toggleActive" checked={filter.enabled} label="Aktiva" onClick={this.setEnabled} className="mr3" />
                    <Radio name="toggleActive" checked={filter.disabled} label="Inaktiva" onClick={this.setDisabled} className="mr5" />
                    <CancelButton
                      className="right"
                      onClick={this.clearFilter}
                      disabled={
                        !filter.createdAfter &&
                        !filter.createdBefore &&
                        filter.enabled &&
                        !filter.disabled &&
                        !filter.contact &&
                        !filter.hiddenState.length &&
                        !filter.hiddenCareerPage.length &&
                        !filter.hiddenCategory.length
                      }>
                      Återställ
                    </CancelButton>
                  </FlexRow>
                </React.Fragment>
              )}
              {!allLoaded && (
                <PrimaryButton loading={loading} className="moreButton" onClick={this.onFetchMoreResults}>
                  Hämta fler
                </PrimaryButton>
              )}
            </SelectionBox>
            {this.hasWriteAccess() && (
              <SelectionBox>
                <BoxTitle>Skicka e-post till användare på markerade företag</BoxTitle>
                <ButtonBox $minWidth="200px" data-tooltip-id="root-tooltip" data-tooltip-content={noActions ? "Markera minst en ansökan nedan." : null}>
                  <SecondaryButton disabled={noActions} onClick={this.sendEmail}>
                    E-post
                  </SecondaryButton>
                </ButtonBox>
              </SelectionBox>
            )}
          </Actions>
          <Row className="header">
            <TitleCell onClick={e => e.currentTarget === e.target && this.setSortOrder("name")}>
              <Checkbox checked={selectAll} onChange={this.selectAll} />
              Företagsnamn
              <Arrow className={sortOrder.name} />
            </TitleCell>
            <InfoArea>
              <InfoCell onClick={e => this.setSortOrder("createdAt")}>
                Skapat
                <Arrow className={sortOrder.createdAt} />
              </InfoCell>
              <InfoCell onClick={e => this.setSortOrder("employees")}>
                Användare
                <Arrow className={sortOrder.employees} />
              </InfoCell>
              <InfoCell onClick={e => this.setSortOrder("activeRecruitments")}>
                Pågående rekryteringar
                <Arrow className={sortOrder.activeRecruitments} />
              </InfoCell>
              <InfoCell onClick={e => this.setSortOrder("closedRecruitments")}>
                Avslutade rekryteringar
                <Arrow className={sortOrder.closedRecruitments} />
              </InfoCell>
              <InfoCell onClick={e => this.setSortOrder("category")}>
                Kategori
                <Arrow className={sortOrder.category} />
              </InfoCell>
              <InfoCell onClick={e => this.setSortOrder("state")} $flex={2}>
                Status
                <Arrow className={sortOrder.state} />
              </InfoCell>
              <InfoCell onClick={e => this.setSortOrder("careerPage")}>
                Karriärsida
                <Arrow className={sortOrder.careerPage} />
              </InfoCell>
            </InfoArea>
          </Row>
          <Rows>
            {initialLoading && <Spinner />}
            {!initialLoading &&
              companies.map(company => (
                <Row className="content" key={company.id}>
                  <TitleCell>
                    <Checkbox value={company.id} checked={selectAll || !!find(s => s.id === company.id)(selected)} onChange={this.selectRow} />
                    <CompanyLink
                      $active={company.active !== false}
                      $groups={!!company.groups?.length}
                      data-tooltip-id="root-tooltip"
                      data-tooltip-content={company.active ? company.groups.map(g => g.name).join(", ") : "Inaktiverat"}
                      href={`/${company.urlName}`}
                      target="_blank"
                      rel="opener">
                      {company.name}
                    </CompanyLink>
                    {company.actions && company.actions.length > 0 && (
                      <div className="actions">
                        {map(action => (
                          <i className="actionSymbol" key={action.id} data-tooltip-id="root-tooltip" data-tooltip-content={action.text} onClick={e => this.onCompanyAction(company, action.name)}>
                            <img src={symbols[action.name]} className={action.name} alt="" />
                          </i>
                        ))(company.actions)}
                      </div>
                    )}
                    {!company.hasApplications && <Trash data-company={company.id} onClick={this.deleteCompany} />}
                  </TitleCell>
                  <InfoArea>
                    <InfoCell>{format(parseISO(company.createdAt), "yy-MM-dd")}</InfoCell>
                    <InfoCell>{company.employeeCount}</InfoCell>
                    <InfoCell>{company.activeRecruitmentsCount}</InfoCell>
                    <InfoCell>{company.closedRecruitmentsCount}</InfoCell>
                    <InfoCell>{this.categoryText(company.category)}</InfoCell>
                    <InfoCell $flex={2}>{this.stateText(company.state)}</InfoCell>
                    <InfoCell>{company.careerPage ? "Ja" : "-"}</InfoCell>
                  </InfoArea>
                </Row>
              ))}
          </Rows>
          {!allLoaded && (
            <PrimaryButton loading={loading} className="moreButton" onClick={this.onFetchMoreResults}>
              Hämta fler
            </PrimaryButton>
          )}
        </Wrapper>
        <Modal isOpen={emailModalOpen} onRequestClose={this.onModalClose}>
          <CompanyMailing companies={selected} onClose={this.onModalClose} onUpdate={this.mailingOnUpdate} />
        </Modal>
        <Modal isOpen={messageLogModalOpen} onRequestClose={this.onModalClose}>
          <CompanyMessageLog companyId={messageLogParentId} onClose={this.onModalClose} />
        </Modal>
      </MainLayout>
    )
  }
}
