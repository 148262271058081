import React from "react"
import { graphql, withApollo } from "@apollo/client/react/hoc"
import { gql } from "@apollo/client"
import { compose } from "react-recompose"
import { NavLink, Outlet } from "react-router-dom"
import withRouter from "_hoc/withRouter"
import styled from "styled-components"
import { parseISO, differenceInDays } from "date-fns"
import { assign, isEmpty, some } from "lodash/fp"
import { history } from "_root/store"
import MainLayout from "_layout/main-layout"
import { Spinner } from "_layout/form-elements"
import StateLink from "_components/menu/state-link"
import TrashIcon from "_images/trash.svg"
import confirm from "_components/confirm"
import ArrowIcon from "_images/arrow_left.svg"
import { RecruitmentState, REC_STATS_ID, HAS_SUBSCRIPTION } from "_root/constants"
import * as url from "_routes/url-names"
import to from "_services/await.to"
import inject from "_services/inject"
import { RECRUITMENT_QUERY, UPDATE_RECRUITMENT, UPDATE_RECRUITMENT_AND_AD, UPSERT_RECRUITMENT_INFO_MUTATION, DELETE_RECRUITMENT } from "_containers/recruitment/recruitment-ql"
import { SecondaryButton } from "_root/layout/buttons"

//#region Styles
const Header = styled.div`
  flex: 0 0 auto;
  position: relative;
  min-height: 150px;
  border-bottom: 2px solid var(--color-line);
  padding: 25px 40px 50px 40px;
  background: var(--color-bg-white);
  z-index: 0;
`
const Content = styled.div`
  overflow-y: scroll;
`
const Blocked = styled.div`
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  background: var(--color-bg-white);
`
const TitleContainer = styled.div`
  position: relative;
  padding-bottom: 10px;
  padding-right: 300px;

  .title {
    font-size: 30px;
    line-height: 34px;
    font-weight: 400;
  }

  .small {
    padding-top: 4px;
    padding-bottom: 6px;
    color: var(--color-text);
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
  }
`
const Top = styled.div`
  flex: 0 0 auto;
  position: relative;
  background: var(--color-brand-green);

  .back {
    display: inline-flex;
    align-items: center;
    margin-left: 10px;
    font-weight: 200;
    color: var(--color-text-white);

    i {
      display: inline-block;
      width: 40px;
      height: 40px;
      margin: 5px 0;
      background: url(${ArrowIcon}) no-repeat scroll 50% 50%;
      background-size: 50%;
    }
  }
`
const AutoButton = styled(SecondaryButton)`
  position: absolute;
  top: 16px;
  right: 0;
  height: auto;
  padding: 12px 57px;
  border-radius: 20px;
`
const Menu = styled.ul`
  position: absolute;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  left: 30px;
  right: 30px;
  bottom: 0;
  margin: 0;
  padding: 0;
  list-style-type: none;
  border-top: 1px solid var(--color-line);
  z-index: 1;
`
const MenuItem = styled.li`
  background: var(--color-bg-white);

  &.wide {
    flex: 1;
    text-align: right;
  }
`
const MenuLink = styled(NavLink)`
  cursor: pointer;
  display: inline-block;
  margin: 0 10px;
  padding: 10px 0 5px 0;
  color: var(--color-text-medium);
  text-decoration: none;
  white-space: nowrap;
  border-bottom: 5px solid transparent;

  &.active,
  &.active:hover {
    color: var(--color-text-dark);
    border-bottom: 5px solid var(--color-brand-red-light);
  }

  &:hover {
    text-decoration: none;
    border-bottom: 5px solid var(--color-bg-grey);
  }
`
const Body = styled.section`
  overflow-y: auto;
  background: var(--color-bg-light);
`
const Trash = styled.a`
  display: inline-block;
  margin: 0 0 0 5px;
  padding: 36px 0 0 0;
  width: 24px;
  height: 24px;
  opacity: 0.5;
  background: url(${TrashIcon}) no-repeat scroll 50% 50%;
  background-size: 20px 20px;

  &:hover {
    opacity: 1;
  }
`
//#endregion

@compose(
  withApollo,
  withRouter,
  inject("user"),
  graphql(RECRUITMENT_QUERY, {
    name: "recruitmentQuery",
    options: props => ({
      fetchPolicy: "network-only",
      variables: { id: props.match.params.recruitment },
    }),
  }),
  graphql(UPDATE_RECRUITMENT, { name: "updateRecruitment" }),
  graphql(UPDATE_RECRUITMENT_AND_AD, { name: "updateRecruitmentAndAd" }),
  graphql(UPSERT_RECRUITMENT_INFO_MUTATION, { name: "upsertRecruitmentInfo" })
)
export default class EditRecruitment extends React.PureComponent {
  constructor(props) {
    super(props)
    this.recruitmentId = props.match.params.recruitment
    this.state = {
      title: "",
      company: {},
      interest: false,
      secret: false,
      recruiter: null,
      recruiterGuests: [],
      recruiterExternals: [],
      occupationalAreas: [],
      blocked: [],
      regions: [],
      addons: [],
      jobAd: null,
      saved: true,
      saving: false,
      deleted: false,
    }
    this.mounted = false
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const nextState = {}
    if (nextProps.recruitmentQuery && !nextProps.recruitmentQuery.loading && nextProps.recruitmentQuery.recruitment) {
      const {
        id,
        title,
        company,
        state,
        recruiter,
        applyStart,
        applyStop,
        applyUrl,
        recruiterGuests,
        recruiterExternals,
        occupationalAreas,
        occupationalGroups,
        regions,
        jobAd,
        process,
        profileFile,
        updatedCount,
        interest,
        secret,
        notes,
        profile,
        requirements,
        merits,
        profileHTML,
        requirementsHTML,
        meritsHTML,
        addons,
        blocked,
        information,
      } = nextProps.recruitmentQuery.recruitment

      assign.convert({ immutable: false })(nextState, {
        recruitment: nextProps.recruitmentQuery.recruitment,
        recruitmentId: id,
        title: title,
        notes: notes,
        company: company,
        state: state,
        interest: interest,
        secret: secret,
        applyStart: applyStart,
        applyStop: applyStop,
        applyUrl: applyUrl,
        blocked: blocked,
        recruiter: recruiter,
        recruiterGuests: recruiterGuests,
        recruiterExternals: recruiterExternals,
        occupationalAreas: occupationalAreas,
        occupationalGroups: occupationalGroups,
        regions: regions,
        jobAd: jobAd,
        profile: profile,
        requirements: requirements,
        merits: merits,
        profileHTML: profileHTML,
        requirementsHTML: requirementsHTML,
        meritsHTML: meritsHTML,
        process: process,
        profileFile: profileFile,
        recruitment: nextProps.recruitmentQuery.recruitment,
        updatedCount: updatedCount || 0,
        information: information,
        addons: addons,
      })
    }
    return !isEmpty(nextState) ? nextState : null
  }

  componentDidMount = () => {
    this.mounted = true
  }

  componentDidUpdate = () => {
    this.checkNavigation()
  }

  dirtyRecruitment = ({ dirtyState } = { dirtyState: false }) => {
    this.setState({ saved: !dirtyState })
  }

  cleanRecruitment = () => {
    this.setState({ saved: true })
    this.props.recruitmentQuery.refetch()
  }

  checkNavigation = () => {
    if (!this.state || !this.state.state) return
    const { params } = this.props.match
    if (!params.state) return
    const paramState = `/${params.state}`
    if (paramState === url.REK_NY) return
    const { state } = this.state
    const urlState = state === RecruitmentState.DRAFT ? url.REK_UTKAST : state === RecruitmentState.ACTIVE ? url.REK_AKTIVA : url.REK_AVSLUTADE
    if (paramState !== urlState) {
      history.replace(history.location.pathname.replace(paramState, urlState))
    }
  }

  saveRecruitmentInfo = async info => {
    if (!this.mounted) return
    this.setState({ saving: true })
    const where = { recruitmentId: this.recruitmentId }
    const create = {
      ...info,
      recruitment: { connect: { id: this.state.recruitmentId } },
    }
    const update = {
      recruitmentReason: info.recruitmentReason !== undefined ? { set: info.recruitmentReason } : undefined,
      employersOffer: info.employersOffer !== undefined ? { set: info.employersOffer } : undefined,
      employmentForm: info.employmentForm !== undefined ? { set: info.employmentForm } : undefined,
      workScope: info.workScope !== undefined ? { set: info.workScope } : undefined,
      workingHours: info.workingHours !== undefined ? { set: info.workingHours } : undefined,
      otherInformation: info.otherInformation !== undefined ? { set: info.otherInformation } : undefined,
      remoteWorkFrom: info.remoteWorkFrom !== undefined ? { set: info.remoteWorkFrom } : undefined,
      remoteWorkTo: info.remoteWorkTo !== undefined ? { set: info.remoteWorkTo } : undefined,
      salaryFrom: info.salaryFrom !== undefined ? { set: info.salaryFrom } : undefined,
      salaryTo: info.salaryTo !== undefined ? { set: info.salaryTo } : undefined,
    }
    const [error, result] = await to(this.props.upsertRecruitmentInfo({ variables: { where, create, update } }))
    if (error) {
      console.error("recruitment-edit:saveRecruitmentInfo", error)
    } else {
      this.props.recruitmentQuery.refetch()
    }
    this.setState({ saving: false })
  }

  saveRecruitment = async recruitment => {
    if (!this.mounted) return
    this.setState({ saving: true })
    const {
      applyStart,
      applyStop,
      applyUrl,
      state,
      title,
      notes,
      interest,
      secret,
      company,
      blocked,
      recruiter,
      recruiterGuests,
      recruiterExternals,
      occupationalAreas,
      occupationalGroups,
      regions,
      selectionIds,
      selectedAddons,
      profile,
      profileFileId,
      profileHTML,
      meritsHTML,
      requirementsHTML,
      publishPackageTypes,
      publishChannelsOther,
      jobAdId,
      jobAdIngress,
      jobAdLayout,
      jobAdImage,
      jobAdLogo,
      jobAdMeritsHTML,
      jobAdMeta,
      jobAdRequirementsHTML,
      jobAdTextHTML,
    } = recruitment || {}

    const recruitmentInput = {
      addons: selectedAddons ? { set: selectedAddons.map(a => ({ id: a.id })) } : undefined,
      applyStart: applyStart ? { set: applyStart } : undefined,
      applyStop: applyStop ? { set: applyStop } : undefined,
      applyUrl: (!!applyUrl || applyUrl?.length === 0) ? { set: applyUrl?.trim() } : undefined,
      company: company ? { connect: { id: company.id } } : undefined,
      state: state ? { set: state } : undefined,
      title: title ? { set: title } : undefined,
      notes: notes ? { set: notes } : undefined,
      interest: interest !== undefined ? { set: interest } : undefined,
      secret: secret !== undefined ? { set: secret } : undefined,
      blocked: blocked ? { set: blocked.map(r => ({ id: r.id })) } : undefined,
      recruiter: recruiter ? { connect: { id: recruiter.id } } : undefined,
      recruiterGuests: recruiterGuests ? { set: recruiterGuests.map(r => ({ id: r.id })) } : undefined,
      recruiterExternals: recruiterExternals ? { set: recruiterExternals.map(r => ({ id: r.id })) } : undefined,
      occupationalAreas: occupationalAreas ? { set: occupationalAreas.map(a => ({ id: a.id })) } : undefined,
      occupationalGroups: occupationalGroups ? { set: occupationalGroups.map(g => ({ id: g.id })) } : undefined,
      regions: regions ? { set: regions.map(r => ({ id: r.id })) } : undefined,
      selection: selectionIds && selectionIds.length ? { set: selectionIds.map(id => ({ id })) } : undefined,
      profile: profile ? { set: profile } : undefined,
      profileHTML: profileHTML ? { set: profileHTML } : undefined,
      profileFile: profileFileId ? { connect: { id: profileFileId } } : undefined,
      meritsHTML: meritsHTML !== null && meritsHTML !== undefined ? { set: meritsHTML } : undefined,
      requirementsHTML: requirementsHTML !== null && requirementsHTML !== undefined ? { set: requirementsHTML } : undefined,
      publishChannelsOther: publishChannelsOther !== undefined ? { set: publishChannelsOther } : undefined,
      publishPackageTypes: publishPackageTypes && (publishPackageTypes.create || publishPackageTypes.delete) ? publishPackageTypes : undefined,
      updatedCount: { set: this.state.updatedCount + 1 },
    }

    const jobAdInput = {
      image: jobAdImage ? { connect: { id: jobAdImage } } : undefined,
      logotype: jobAdLogo ? { connect: { id: jobAdLogo } } : undefined,
      layout: jobAdLayout ? { set: jobAdLayout } : undefined,
      ingress: jobAdIngress ? { set: jobAdIngress } : { set: "" },
      textHTML: jobAdTextHTML ? { set: jobAdTextHTML } : { set: "" },
      requirementsHTML: jobAdRequirementsHTML ? { set: jobAdRequirementsHTML } : { set: "" },
      meritsHTML: jobAdMeritsHTML ? { set: jobAdMeritsHTML } : { set: "" },
      meta: jobAdMeta ? jobAdMeta : undefined,
    }

    const updateFunc = jobAdId ? this.props.updateRecruitmentAndAd : this.props.updateRecruitment
    const [error, result] = await to(
      updateFunc({
        variables: {
          id: this.recruitmentId,
          jobAdId: jobAdId,
          recruitment: recruitmentInput,
          jobAd: jobAdInput,
        },
      })
    )
    if (!this.mounted) return
    if (error || !result) {
      console.error("saveRecruitment:error", error, {
        id: this.recruitmentId,
        ...recruitment,
      })
    } else {
      this.setState({
        saved: true,
        updatedCount: recruitmentInput.updatedCount,
        saving: false,
      })
      this.props.recruitmentQuery.refetch()
      if (Object.keys(recruitment).length === 1 && "state" in recruitment && recruitment["state"] === RecruitmentState.ACTIVE) {
        this.props.client.writeQuery({
          query: gql`
            query RecruitmentStats {
              recruitmentStats {
                countRecruitmentsIsStale
                activeRecruitmentsIsStale
                draftRecruitmentsIsStale
                closedRecruitmentsIsStale
              }
            }
          `,
          data: {
            recruitmentStats: {
              __typename: "RecruitmentStats",
              id: REC_STATS_ID,
              countRecruitmentsIsStale: true,
              activeRecruitmentsIsStale: state === RecruitmentState.ACTIVE,
              draftRecruitmentsIsStale: state === RecruitmentState.DRAFT,
              closedRecruitmentsIsStale: state === RecruitmentState.CLOSED,
            },
          },
        })
      }
    }
  }

  fakeSave = () => {
    this.setState({ saving: true }, () => {
      setTimeout(() => {
        if (!this.mounted) return
        this.setState({ saving: false })
      }, 500)
    })
  }

  deleteRecruitment = async e => {
    e.preventDefault()
    let confirmed = await confirm(`Vill du ta bort denna rekrytering permanent?`)
    if (!confirmed) return
    confirmed = await confirm(`Är du verkligen säker på att du vill ta bort denna rekrytering permanent?`)
    if (!confirmed) return
    const state = this.state.recruitment.state
    const [error, result] = await to(
      this.props.client.mutate({
        mutation: DELETE_RECRUITMENT,
        variables: { id: this.state.recruitment.id },
      })
    )
    if (!this.mounted) return
    if (error || !result) {
      console.error("recruitment-edit:deleteRecruitment:error:", error, result, this.state.recruitment.id)
      this.setState({ deleted: false })
    } else {
      this.setState({ deleted: true })
      this.props.client.writeQuery({
        query: gql`
          query RecruitmentStats {
            recruitmentStats {
              countRecruitmentsIsStale
              activeRecruitmentsIsStale
              draftRecruitmentsIsStale
              closedRecruitmentsIsStale
            }
          }
        `,
        data: {
          recruitmentStats: {
            __typename: "RecruitmentStats",
            id: REC_STATS_ID,
            countRecruitmentsIsStale: true,
            activeRecruitmentsIsStale: state === RecruitmentState.ACTIVE,
            draftRecruitmentsIsStale: state === RecruitmentState.DRAFT,
            closedRecruitmentsIsStale: state === RecruitmentState.CLOSED,
          },
        },
      })
      const { params } = this.props.match
      this.props.navigate("/" + params.company + url.REKRYTERING + "/" + params.state)
    }
    return false
  }

  render() {
    if (this.state.deleted || this.props.recruitmentQuery.loading || !this.state.recruitment || !this.state.company) {
      return (
        <MainLayout>
          <Header>
            <Spinner />
          </Header>
          <Content>
            <Spinner />
          </Content>
        </MainLayout>
      )
    }

    const { match, user } = this.props
    const urlBase = `/${match.params.company}` + url.REKRYTERING + (match.params.state ? `/${match.params.state}` : url.REK_NY) + `/${match.params.recruitment}`
    const { recruiter, title, company, state, recruitment, saved, saving } = this.state
    const { recruiterGuests, recruiterExternals } = recruitment
    const groups = company.groups ? company.groups.map(g => g.id) : []
    const titlePrefix = (match.params.state ? "Redigera" : "Skapa") + " rekrytering"
    const publishLinkText = !match.params.state || url.REK_UTKAST.indexOf(match.params.state) > -1 ? "Marknadsföring/aktivera" : "Marknadsföring"
    const templatesUrl = `/${match.params.company}${url.REKRYTERING}/${match.params.state}/${match.params.recruitment}${url.REK_APP_DOK}`
    const appUrl = `/${match.params.company}${url.REKRYTERING}/${match.params.state}/${match.params.recruitment}${url.REK_APPLICATIONS}`
    const userAdmin = user.superAdmin || user.groupAdmin || user.isAdmin
    const hasPermission = recruiter.id === user.id || some({ id: user.id })(recruiterExternals) || some({ id: user.id })(recruiterGuests)
    const blocked = !userAdmin && (!hasPermission || (recruitment.company.subscription !== HAS_SUBSCRIPTION && differenceInDays(new Date(), parseISO(recruitment.applyStart)) > 90))

    return (
      <MainLayout maxHeight>
        <Top>
          <NavLink className="back" to={appUrl} title="Till hantera ansökningar">
            <i />
            Till hantera ansökningar
          </NavLink>
        </Top>
        {!blocked &&
        <Header>
          <TitleContainer>
            <div className="small">{titlePrefix}</div>
            <span className="title">
              {title}, {company.name}
            </span>
            <StateLink state={state} to={urlBase + url.REK_KANALER + "#publicering"} />
            <AutoButton disabled={saving} loading={saving} onClick={this.fakeSave}>
              {saving ? "Sparar" : "Spara"}
            </AutoButton>
          </TitleContainer>
          <Menu>
            <MenuItem>
              <MenuLink end to={urlBase}>
                Uppstart
              </MenuLink>
            </MenuItem>
            <MenuItem>
              <MenuLink to={urlBase + url.REK_ANNONS}>Platsannons</MenuLink>
            </MenuItem>
            <MenuItem>
              <MenuLink to={urlBase + url.REK_KANALER}>{publishLinkText}</MenuLink>
            </MenuItem>
            <MenuItem>
              <MenuLink end to={templatesUrl}>
                Intervju- och referensmallar
              </MenuLink>
            </MenuItem>
            <MenuItem>
              <MenuLink to={urlBase + url.REK_INFO}>Formalia</MenuLink>
            </MenuItem>
            <MenuItem className="wide">
              <MenuLink to={urlBase + url.REK_PLANERA}>Planera rekrytering</MenuLink>
            </MenuItem>
            {user.superAdmin && (
              <MenuItem>
                <MenuLink to={urlBase + url.REK_LOGGBOK}>Loggbok</MenuLink>
              </MenuItem>
            )}
            {(userAdmin || (recruiter && user.id === recruiter.id)) && (
              <MenuItem>
                <Trash onClick={this.deleteRecruitment} data-tooltip-id="root-tooltip" data-tooltip-content="Radera rekrytering" />
              </MenuItem>
            )}
          </Menu>
        </Header>
        }
        {blocked && <Blocked>Du har inte rätt att se denna rekrytering. {hasPermission && "Kontakta support för att aktivera ditt avtal."}</Blocked>}
        {!blocked && recruitment &&
        <Body>
          <Outlet context={[recruitment, state, groups, urlBase, saving, saved, this.saveRecruitment, this.saveRecruitmentInfo, this.dirtyRecruitment, this.cleanRecruitment]} />
        </Body>
        }
      </MainLayout>
    )
  }
}
