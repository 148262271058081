import React from "react"
import { NavLink } from "react-router-dom"
import withRouter from "_hoc/withRouter"
import { withApollo, graphql } from "@apollo/client/react/hoc"
import { gql } from "@apollo/client"
import { compose } from "react-recompose"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import styled from "styled-components"
import { isEmpty, map, isEqual } from "lodash/fp"
import inject from "_services/inject"
import { setUser } from "_actions/user-actions"
import { toggleFilterMenu } from "_actions/menu-actions"
import MainLayout from "_layout/main-layout"
import Filter from "_components/dashboard/filter"
import RecruitmentList from "_components/dashboard/recruitment-list"
import { UserRoles, REC_STATS_ID, HAS_SUBSCRIPTION } from "_root/constants"
import { CLIENT_RECRUITMENTS_STATS, DASHBOARD } from "_containers/recruitment/recruitment-ql"
import { COMPANY_CONTACTS } from "../settings/company-profile-ql"
import withSendmailMutation from "_hoc/withSendmailMutation"
import * as url from "_routes/url-names"
import ArrowDownIcon from "_images/menu-down.svg"
import ProfileImage from "_images/profile.svg"
import MailIconSrc from "_images/email.svg"
import { SmallSpinner } from "_root/layout/form-elements"
import { PrimaryLinkButton } from "_root/layout/buttons"
import dashboardFilter from "_utils/dashboardFilter"

//#region Styles
const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  height: 100%;
`
const Welcome = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 25px;
  padding: 24px 30px 20px 30px;
  border: 1px solid var(--color-bg-light);
  box-shadow: 0px 0px 5px var(--color-bg-light);
  background: var(--color-bg-white);

  @media screen and (max-width: 767px) {
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 10px;
    padding: 10px;
  }
`

const RecruitButtons = styled.div`
  padding-top: 2rem;

  @media screen and (max-width: 767px) {
    margin-top: 10px;
  }
`
const Hello = styled.div`
  .greeting {
    display: block;
    font-size: 30px;
    line-height: 34px;
  }

  @media screen and (max-width: 767px) {
    .greeting {
      font-size: 20px;
      line-height: 24px;
    }
  }
`
const Contacts = styled.div`
  display: flex;
  align-items: flex-start;
  margin-left: auto;
  white-space: nowrap;

  @media screen and (max-width: 767px) {
    display: none;
  }
`
const Bubble = styled.div`
  position: relative;
  display: none;
  padding: 1rem;
  margin-right: 1rem;
  border-radius: 2rem;
  border: 1px solid var(--color-line);
  box-shadow: 1px 1px 1px var(--color-line);
  max-width: 200px;
  font-size: 0.9rem;
  white-space: initial;

  &:after {
    content: '';
    position: absolute;
    top: 40%;
    right: -10px;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-left-color: #fff;
    border-right: 0;
    margin-top: -10px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 40%;
    right: -13px;
    width: 0;
    height: 0;
    border: 11px solid transparent;
    border-left-color: var(--color-line);
    border-right: 0;
    margin-top: -11px;
  }

  @media screen and (min-width: 768px) {
    display: inline-block;
    max-width: 300px;
  }

  @media screen and (min-width: 1500px) {
    max-width: 500px;
  }
`
const Contact = styled.div`
  display: inline-flex;
  margin: 0 10px;
  text-align: center;
  align-items: center;
  flex-flow: column nowrap;
`
const ContactImage = styled.div`
  display: inline-block;
  width: 80px;
  height: 80px;
  margin-bottom: 5px;
  border: 1px solid var(--color-text);
  background: url(${ProfileImage}) no-repeat scroll 50% 50%;
  background-size: 34px 34px;

  img {
    max-width: 100%;
  }
`
const ContactLink = styled.a`
  display: inline-block;
  color: var(--color-text);
  white-space: nowrap;
`
const MailIcon = styled.i`
  display: inline-block;
  margin-right: 4px;
  width: 20px;
  height: 12px;
  background: url(${MailIconSrc}) no-repeat scroll 50% 50%;
`
const Header = styled(NavLink)`
  display: flex;
  color: var(--color-text-white);
  padding: 5px 20px;
  margin: 2px 0;
  background: var(--color-nav-3);
  white-space: nowrap;

  &.active {
    pointer-events: none;
  }

  &:hover {
    text-decoration: none;
  }

  .count {
    font-size: 0.8rem;
    color: var(--color-bg-grey-dark);
  }

  .spinner-container {
    margin-left: 10px;
  }

  @media (max-height: 500px) {
    padding: 7px 20px;

    span {
      font-size: 14px;
    }
  }
`
const ArrowDown = styled.span`
  display: inline-block;
  width: 20px;
  height: 24px;
  margin-left: auto;
  background: url(${ArrowDownIcon}) no-repeat scroll 50% 50%;

  @media (max-height: 500px) {
    width: 20px;
  }
`
const ArrowUp = styled(ArrowDown)`
  transform: rotate(180deg);
`
const FetchButton = styled.button`
  pointer-events: all;
  padding: 0 10px;
  margin-left: 10px;
  height: 24px;
  color: var(--color-bg-grey);
  font-size: 12px;
  border: 1px solid var(--color-bg-grey-dark);
  border-radius: 12px;
  background: transparent;
  outline: none;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  white-space: nowrap;

  &:hover {
    background: var(--color-brand-black-hover);
  }
`
const DueDateList = styled.ul`
  margin: 0;
  padding: 0;
  min-width: 400px;
  list-style-type: none;

  li {
    margin-bottom: 10px;
  }

  @media screen and (max-width: 767px) {
    min-width: unset;
  }
`
const Content = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  justify-content: flex-start;
  padding: 35px 40px 30px 40px;
  min-width: 1080px;

  @media screen and (max-width: 767px) {
    padding: 10px 0;
    min-width: 0;
  }

  @media screen and (max-width: 1280px) {
    padding: 20px;
  }

  @media screen and (max-height: 650px) {
    padding: 10px 20px 10px 10px;
    ${Welcome} {
      margin-bottom: 10px;
      padding: 10px 20px;

      ${Hello} {
        .greeting {
          display: none;
        }
      }
      ${RecruitButtons} {
        padding-top:0;
      }
      ${Bubble} {
        display:none;
      }
      ${Contact} {
        flex-flow: row;
        ${ContactImage}{
          width:55px;
          height:55px;
          margin:0 10px 0 0;

        }
      }
    }
    .content {
      .date {
        padding:3px 0;
      }
    }
  }
`

//#endregion

const BATCH_SIZE = 50

@compose(
  withApollo,
  withRouter,
  withSendmailMutation,
  inject("user", "menu"),
  connect(null, dispatch => bindActionCreators({ toggleFilterMenu, setUser }, dispatch)),
  graphql(COMPANY_CONTACTS, { name: "companyContacts", options: props => ({ variables: { urlName: props.match.params.company } }) }),
  graphql(CLIENT_RECRUITMENTS_STATS, { name: "recuitmentStatsQuery", options: () => ({ fetchPolicy: "cache-only" }) }),
  graphql(DASHBOARD, {
    skip: props => url.REK_AKTIVA.indexOf(props.match.params.state) < 0,
    name: "dashboardActiveQuery",
    options: ({ user }) => ({
      fetchPolicy: "network-only",
      variables: {
        where: dashboardFilter(user.filter, "ACTIVE", user.companyView),
        take: BATCH_SIZE,
        companyId: user.companyView.id,
      },
    }),
  }),
  graphql(DASHBOARD, {
    skip: props => url.REK_UTKAST.indexOf(props.match.params.state) < 0,
    name: "dashboardDraftQuery",
    options: ({ user }) => ({
      fetchPolicy: "network-only",
      variables: {
        where: dashboardFilter(user.filter, "DRAFT", user.companyView),
        take: BATCH_SIZE,
        companyId: user.companyView.id,
      },
    }),
  }),
  graphql(DASHBOARD, {
    skip: props => url.REK_AVSLUTADE.indexOf(props.match.params.state) < 0,
    name: "dashboardClosedQuery",
    options: ({ user }) => ({
      fetchPolicy: "network-only",
      variables: {
        where: dashboardFilter(user.filter, "CLOSED", user.companyView),
        take: BATCH_SIZE,
        companyId: user.companyView.id,
      },
    }),
  })
)
export default class Overview extends React.PureComponent {
  state = {
    mobile: window.innerWidth < 768,
    bookingTitle: "",
    bookingPhone: "",
    bookingTimes: "",
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const nextState = {}
    const { dashboardActiveQuery, dashboardDraftQuery, dashboardClosedQuery } = nextProps
    if (dashboardActiveQuery && !dashboardActiveQuery.loading && dashboardActiveQuery.dashboardRecruitments) {
      if (prevState.fetchMoreActive && dashboardActiveQuery.dashboardRecruitments.length < BATCH_SIZE) {
        nextState.fetchMoreActive = false
      } else if (!prevState.fetchMoreActive && dashboardActiveQuery.dashboardRecruitments.length >= BATCH_SIZE) {
        nextState.fetchMoreActive = true
      }
    }
    if (dashboardDraftQuery && !dashboardDraftQuery.loading && dashboardDraftQuery.dashboardRecruitments) {
      if (prevState.fetchMoreDraft && dashboardDraftQuery.dashboardRecruitments.length < BATCH_SIZE) {
        nextState.fetchMoreDraft = false
      } else if (!prevState.fetchMoreDraft && dashboardDraftQuery.dashboardRecruitments.length >= BATCH_SIZE) {
        nextState.fetchMoreDraft = true
      }
    }
    if (dashboardClosedQuery && !dashboardClosedQuery.loading && dashboardClosedQuery.dashboardRecruitments) {
      if (prevState.fetchMoreClosed && dashboardClosedQuery.dashboardRecruitments.length < BATCH_SIZE) {
        nextState.fetchMoreClosed = false
      } else if (!prevState.fetchMoreClosed && dashboardClosedQuery.dashboardRecruitments.length >= BATCH_SIZE) {
        nextState.fetchMoreClosed = true
      }
    }
    return !isEmpty(nextState) ? nextState : null
  }

  fetchActive = () => {
    const { dashboardRecruitments, fetchMore, loading } = this.props.dashboardActiveQuery
    if (!dashboardRecruitments || loading || this.state.fetchingActive) return
    this.setState({ fetchingActive: true }, () => {
      fetchMore({
        variables: { skip: dashboardRecruitments.length },
        updateQuery: (prev, { fetchMoreResult }) => {
          this.setState({ fetchingActive: false, fetchMoreActive: fetchMoreResult && fetchMoreResult.dashboardRecruitments.length === BATCH_SIZE })
          if (!fetchMoreResult) return prev
          return Object.assign({}, prev, { dashboardRecruitments: [...prev.dashboardRecruitments, ...fetchMoreResult.dashboardRecruitments] })
        },
      })
    })
  }

  fetchDraft = () => {
    const { dashboardRecruitments, fetchMore, loading } = this.props.dashboardDraftQuery
    if (!dashboardRecruitments || loading || this.state.fetchingDraft) return
    this.setState({ fetchingDraft: true }, () => {
      fetchMore({
        variables: { skip: dashboardRecruitments.length },
        updateQuery: (prev, { fetchMoreResult }) => {
          this.setState({ fetchingDraft: false, fetchMoreDraft: fetchMoreResult && fetchMoreResult.dashboardRecruitments.length === BATCH_SIZE })
          if (!fetchMoreResult) return prev
          return Object.assign({}, prev, { dashboardRecruitments: [...prev.dashboardRecruitments, ...fetchMoreResult.dashboardRecruitments] })
        },
      })
    })
  }

  fetchClosed = () => {
    const { dashboardRecruitments, fetchMore, loading } = this.props.dashboardClosedQuery
    if (!dashboardRecruitments || loading || this.state.fetchingClosed) return
    this.setState({ fetchingClosed: true }, () => {
      fetchMore({
        variables: { skip: dashboardRecruitments.length },
        updateQuery: (prev, { fetchMoreResult }) => {
          this.setState({ fetchingClosed: false, fetchMoreClosed: fetchMoreResult && fetchMoreResult.dashboardRecruitments.length === BATCH_SIZE })
          if (!fetchMoreResult) return prev
          return Object.assign({}, prev, { dashboardRecruitments: [...prev.dashboardRecruitments, ...fetchMoreResult.dashboardRecruitments] })
        },
      })
    })
  }

  componentDidMount = () => {
    const { match: routerMatch } = this.props
    const {
      params: { state: currentView },
    } = routerMatch
    this.checkForStaleDataAndRefetch(currentView, true)
  }

  componentDidUpdate = (prevProps, prevState) => {
    const {
      params: { state: currentViewState },
    } = this.props.match
    const {
      params: { state: prevViewState },
    } = prevProps.match
    if (currentViewState !== prevViewState) {
      this.checkForStaleDataAndRefetch(currentViewState, false)
    }
  }

  checkForStaleDataAndRefetch(currentView, checkLoaded) {
    const { recuitmentStatsQuery, dashboardActiveQuery, dashboardDraftQuery, dashboardClosedQuery } = this.props

    if (checkLoaded) {
      if (dashboardActiveQuery && !dashboardActiveQuery.loading) {
        dashboardActiveQuery.refetch()
      }
      if (dashboardDraftQuery && !dashboardDraftQuery.loading) {
        dashboardDraftQuery.refetch()
      }
      if (dashboardClosedQuery && !dashboardClosedQuery.loading) {
        dashboardClosedQuery.refetch()
      }
    }

    if (recuitmentStatsQuery.loading) return
    const { recruitmentStats } = recuitmentStatsQuery

    let updateStaleKey
    if (url.REK_AKTIVA.indexOf(currentView) > 0 && recruitmentStats?.activeRecruitmentsIsStale) {
      dashboardActiveQuery && dashboardActiveQuery.refetch()
      updateStaleKey = "activeRecruitmentsIsStale"
    }
    if (url.REK_UTKAST.indexOf(currentView) > 0 && recruitmentStats?.draftRecruitmentsIsStale) {
      dashboardDraftQuery && dashboardDraftQuery.refetch()
      updateStaleKey = "draftRecruitmentsIsStale"
    }
    if (url.REK_AVSLUTADE.indexOf(currentView) > 0 && recruitmentStats?.closedRecruitmentsIsStale) {
      dashboardClosedQuery && dashboardClosedQuery.refetch()
      updateStaleKey = "closedRecruitmentsIsStale"
    }

    if (updateStaleKey) {
      this.props.client.writeQuery({
        query: gql`
          query RecruitmentStats {
            recruitmentStats {
              countRecruitmentsIsStale
              activeRecruitmentsIsStale
              draftRecruitmentsIsStale
              closedRecruitmentsIsStale
            }
          }
        `,
        data: {
          recruitmentStats: {
            __typename: "RecruitmentStats",
            id: REC_STATS_ID,
            [updateStaleKey]: false,
          },
        },
      })
    }
  }

  toggleFilter = async e => {
    this.props.toggleFilterMenu()
  }

  onFilter = filter => {
    const { setUser, user } = this.props
    if (isEqual(filter)(user.filter)) return
    this.setState({ fetchMoreActive: undefined, fetchMoreDraft: undefined, fetchMoreClosed: undefined })
    setUser({ ...user, filter: { ...filter } })
  }

  hasWriteAccess = () => {
    const { user } = this.props
    return user.superAdmin || (user?.companyView.subscription === HAS_SUBSCRIPTION && [UserRoles.GROUPVIEWER, UserRoles.VIEWER].includes(user.role) === false)
  }

  render() {
    const COMPANY = `/${this.props.match.params.company}`
    const { match, user, menu } = this.props
    const userName = user.firstName + " " + user.lastName
    const view = `/${match.params.state}`

    const isActiveView = view === url.REK_AKTIVA
    const isDraftView = view === url.REK_UTKAST
    const isClosedView = view === url.REK_AVSLUTADE

    const {
      dueRecruitments,
      fetchingActive,
      fetchingDraft,
      fetchingClosed,
      fetchMoreActive,
      fetchMoreDraft,
      fetchMoreClosed,
    } = this.state

    const { companyContacts, dashboardActiveQuery, dashboardDraftQuery, dashboardClosedQuery } = this.props

    const contacts = companyContacts && companyContacts.company && companyContacts.company.jobbetContacts
    const hasSubscription = companyContacts && companyContacts.company && companyContacts.company.subscription

    const recruitmentsActive = (dashboardActiveQuery && dashboardActiveQuery.dashboardRecruitments) || []
    const recruitmentsDraft = (dashboardDraftQuery && dashboardDraftQuery.dashboardRecruitments) || []
    const recruitmentsClosed = (dashboardClosedQuery && dashboardClosedQuery.dashboardRecruitments) || []

    const activeCount = Number.isInteger(menu.activeCount) && (isActiveView ? `${recruitmentsActive.length} av ${menu.activeCount}` : `${menu.activeCount}`)
    const draftCount = Number.isInteger(menu.draftCount) && (isDraftView ? `${recruitmentsDraft.length} av ${menu.draftCount}` : `${menu.draftCount}`)
    const closedCount = Number.isInteger(menu.closedCount) && (isClosedView ? `${recruitmentsClosed.length} av ${menu.closedCount}` : `${menu.closedCount}`)

    const activeSpinner = dashboardActiveQuery && dashboardActiveQuery.loading && <SmallSpinner />
    const draftSpinner = dashboardDraftQuery && dashboardDraftQuery.loading && <SmallSpinner />
    const closedSpinner = dashboardClosedQuery && dashboardClosedQuery.loading && <SmallSpinner />

    const fetchMoreActiveButton = isActiveView && !activeSpinner && !!activeCount && recruitmentsActive.length < menu.activeCount && fetchMoreActive && (
      <FetchButton onClick={this.fetchActive}>Hämta fler{fetchingActive && <SmallSpinner />}</FetchButton>
    )
    const fetchMoreDraftButton = isDraftView && !draftSpinner && !!activeCount && recruitmentsDraft.length < menu.draftCount && fetchMoreDraft && (
      <FetchButton onClick={this.fetchDraft}>Hämta fler{fetchingDraft && <SmallSpinner />}</FetchButton>
    )
    const fetchMoreClosedButton = isClosedView && !closedSpinner && !!activeCount && recruitmentsClosed.length < menu.closedCount && fetchMoreClosed && (
      <FetchButton onClick={this.fetchClosed}>Hämta fler{fetchingClosed && <SmallSpinner />}</FetchButton>
    )

    return (
      <MainLayout maxHeight row>
        <Wrapper>
          <Content>
            <Welcome>
              <Hello>
                <strong className="greeting">Hej {userName}!</strong>
                <RecruitButtons>
                  {this.hasWriteAccess() && <PrimaryLinkButton className="hello-action-button" to={COMPANY + url.REKRYTERING + url.REK_NY}>Skapa ny rekrytering</PrimaryLinkButton>}
                </RecruitButtons>
              </Hello>
              {contacts && contacts.length > 0 && (
                <Contacts>
                  {hasSubscription != HAS_SUBSCRIPTION && menu.activeCount > 0 && 
                    <Bubble>Vi bjuder alltid på fri tillgång till vårt Rekryteringscenter under 90 dagar i samband med att du bokar en tjänst via Jobbet.se. Fri support ingår vardagar 08-17.</Bubble>
                  }
                  {hasSubscription != HAS_SUBSCRIPTION && menu.activeCount == 0 && 
                    <Bubble>Ert företag saknar avtal med Jobbet.se och du kan därför inte se dina tidigare rekryteringar eller aktivera en ny rekrytering.
                    Kontakta kundtjanst@jobbet.se för information om hur ni aktiverar ert konto/tecknar avtal.</Bubble>
                  }
                  {hasSubscription === HAS_SUBSCRIPTION && 
                    <Bubble>Vi finns här för att hjälpa dig med din rekrytering!<br />I din tjänst ingår fri support vardagar 08-17. Ring eller maila oss gärna!</Bubble>
                  }
                  {map(contact => (
                    <Contact key={contact.id}>
                      <ContactImage data-tooltip-id="root-tooltip" data-tooltip-content={contact.firstName + " " + contact.lastName}>
                        {contact && contact.picture && contact.picture.url && <img src={contact.picture.url} alt={contact.firstName + " " + contact.lastName} />}
                      </ContactImage>
                      <span>
                        {contact && contact.email && (
                          <ContactLink href={"mailto:" + contact.email} data-tooltip-id="root-tooltip" data-tooltip-content={contact.email}>
                            <MailIcon />
                            {contact.firstName + " " + contact.lastName}
                          </ContactLink>
                        )}
                        <br />
                        {contact && (contact.phone || contact.mobile) && <ContactLink href={"tel:" + contact.mobile}>{contact.mobile}</ContactLink>}
                      </span>
                    </Contact>
                  ))(contacts)}
                </Contacts>
              )}
            </Welcome>
            <Header to={COMPANY + url.REKRYTERING + url.REK_AKTIVA}>
              <span>Aktiva rekryteringar {activeCount && <span className="count">({activeCount})</span>}</span>
              {fetchMoreActiveButton}
              {activeSpinner}
              {view === url.REK_AKTIVA ? <ArrowDown /> : <ArrowUp />}
            </Header>
            {isActiveView && <RecruitmentList recruitments={recruitmentsActive} loading={dashboardActiveQuery && dashboardActiveQuery.loading} due={dueRecruitments} />}
            <Header to={COMPANY + url.REKRYTERING + url.REK_UTKAST}>
              <span>Utkast till rekryteringar {draftCount && <span className="count">({draftCount})</span>}</span>
              {fetchMoreDraftButton}
              {draftSpinner}
              {view === url.REK_UTKAST ? <ArrowDown /> : <ArrowUp />}
            </Header>
            {isDraftView && <RecruitmentList recruitments={recruitmentsDraft} loading={dashboardDraftQuery && dashboardDraftQuery.loading} />}
            <Header to={COMPANY + url.REKRYTERING + url.REK_AVSLUTADE}>
              <span>Avslutade rekryteringar {closedCount && <span className="count">({closedCount})</span>}</span>
              {fetchMoreClosedButton}
              {closedSpinner}
              {view === url.REK_AVSLUTADE ? <ArrowDown /> : <ArrowUp />}
            </Header>
            {isClosedView && <RecruitmentList recruitments={recruitmentsClosed} loading={dashboardClosedQuery && dashboardClosedQuery.loading} />}
          </Content>
          <Filter onClick={this.toggleFilter} onChange={this.onFilter} />
        </Wrapper>
      </MainLayout>
    )
  }
}
