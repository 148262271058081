import { gql } from "@apollo/client"

import { STORED_PATH, STORED_TOKEN, EXPIRED_TOKEN } from "../constants"
import { USER_LOGOUT, USER_SET_LOGGEDIN, USER_SET_RELOAD } from "_actions/user-actions"

import to from "./await.to"
import { default as store } from "../store"

const LOGOUT_MUTATION = gql`
  mutation LogoutUser {
    clearToken
  }
`

export const isExpiredUser = () => {
  return !!window.sessionStorage.getItem(EXPIRED_TOKEN)
}

export const loginUser = (token) => {
  window.sessionStorage.removeItem(EXPIRED_TOKEN)
  window.sessionStorage.setItem(STORED_TOKEN, token)
  store.dispatch({ type: USER_SET_LOGGEDIN, payload: true })
}

export const logoutUser = (apolloClient, nextUrl) => {
  window.sessionStorage.removeItem(STORED_TOKEN)
  apolloClient && apolloClient.resetStore()
  if (!isExpiredUser()) {
    window.sessionStorage.removeItem(STORED_PATH + window.name, window.location.pathname)
  }
  store.dispatch({ type: USER_LOGOUT })
  if (nextUrl) {
    setTimeout(() => { window.location = nextUrl }, 50)
  }
}

export const expiredUser = () => {
  window.name = window.name || window.location.pathname
  window.sessionStorage.setItem(STORED_PATH + window.name, window.location.pathname)
  window.sessionStorage.setItem(EXPIRED_TOKEN, true)
  logoutUser()
}

export const userToken = () => {
  return window.sessionStorage.getItem(STORED_TOKEN)
}

export const reloadUser = async (client) => {
  const [err, result] = await to(client.mutate({ mutation: LOGOUT_MUTATION }))
  if (!err && result) {
    store.dispatch({ type: USER_SET_RELOAD, payload: true })
  }
}

export const clearToken = async ({ client, reload, nextUrl }) => {
  const [error, result] = await to(client.mutate({ mutation: LOGOUT_MUTATION }))
  if (error || !result) {
    console.error("login-service:error:", error)
  }
  else if (nextUrl) {
    logoutUser(client, nextUrl)
  }
  else {
    window.sessionStorage.removeItem(STORED_TOKEN)
    if (reload === true) {
      store.dispatch({ type: USER_SET_LOGGEDIN, payload: false })
    }
  }
}

export default {
  loginUser,
  logoutUser,
  userToken,
  reloadUser,
  clearToken,
}
