import React from 'react'
import { compose } from "react-recompose"
import inject from "_services/inject"
import styled from 'styled-components'
import Header from '_components/header'
import SideMenuBase from '_components/menu/side-menu'

//#region Styles
const Main = styled.div`
  position: relative;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  /* min-width: 1366px; MARTIN MILISIC VILL INTE HA EN VERTIKAL SCROLLBAR */
  `
const Head = styled(Header)`
    flex: 0 1 auto;
    `
const Body = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  `
const Content = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  padding: ${props => props.$padding ? '40px 30px' : '0' };
  background: var(--color-bg-lighter);
  overflow-y: ${props => props.$scroll ? 'auto' : (props.$scrollAlways ? 'scroll' : 'initial')};
  
  &.blocked {
    opacity: 0.2;
    pointer-events: none;
  }

  > div {
    width: 100%;
  }

  @media screen and (max-width: 767px) {
    padding: ${props => props.$padding ? '20px 0' : '0' };
  }
`
const SideMenu = styled(SideMenuBase)`
    flex: 0;
`
//#endregion

const MainLayout = ({ row, scroll, scrollAlways, padding, maxHeight, nomenu, children }) => {
  const styles = maxHeight ? { maxHeight: '100vh', height: '100vh' } : {}
  const contentStyles = row ? { flexDirection: 'row' } : {}

  return (
    <Main style={styles}>
      <Head />
      <Body>
        {nomenu ||
          <SideMenu />
        }
        <Content id='main-content' style={contentStyles} $scroll={scroll} $scrollAlways={scrollAlways} $padding={padding}>
          {children}
        </Content>
      </Body>
    </Main>
  )
}
export default MainLayout