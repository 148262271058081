import React from 'react'
import styled from 'styled-components'
import { PrimaryLinkButton } from "_layout/buttons"
import { NavLink } from "react-router-dom"
import * as url from "_routes/url-names"

const Services = styled.div`
  margin-bottom: 10px;

  > a {
    display: inline-block;
    margin-bottom: 10px;
  }
  > div {
    text-align: left;
  }
`
const Title = styled.header`
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
  padding-bottom: 7px;
  border-bottom: 2px solid var(--color-brand-green);

  > span {
    display: inline-block;
    color: var(--color-text-dark);
    font-size: 21px;
    line-height: 34px;
    font-weight: 400;
  }

  .ad-meter-wrapper {
    display: inline-block;
    margin-left: auto;
  }
`
const SubTitle = styled.header`
  display: flex;
  align-items: baseline;
  padding-bottom: 7px;

  > span {
    display: inline-block;
    color: var(--color-text-dark);
    font-size: 18px;
    font-weight: 700;
  }

  .ad-meter-wrapper {
    display: inline-block;
    margin-left: auto;
  }
`

const AddonStepsList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-weight: 900;

  li {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 0;
    white-space: nowrap;
    .addonStepStatus, .orderAddon {
        margin-left:auto;
    }
    .addonStepActive {
        color:var(--color-brand-green);
        padding: 0 32px;
    }
    .addonStepName {
        padding-right: 10px;
    }
  }
`




export class ServicesSummary extends React.Component {

    constructor(props) {
        super(props)
        this.addonStepsIncluded = {
            select: {displayname:'Urval', active:false},
            firstInt: {displayname:'1:a intervju', active:false},
            test: {displayname:'Test', active:false},
            secInt: {displayname:'2:a intervju', active:false},
            ref: {displayname:'Referenser', active:false}
        }
        this.props.addons.forEach(addon => {
         switch (addon.type) {
            case "COMPLETE":
                this.addonStepsIncluded['select'].active = true
                this.addonStepsIncluded['firstInt'].active = true
                this.addonStepsIncluded['test'].active = true
                this.addonStepsIncluded['secInt'].active = true
                this.addonStepsIncluded['ref'].active = true
                break
            case "ADMIN":
                this.addonStepsIncluded['select'].active = true
                this.addonStepsIncluded['firstInt'].active = true
                break
            case "INTERVIEWS":
                this.addonStepsIncluded['firstInt'].active = true
                this.addonStepsIncluded['secInt'].active = true
                break
            case "SECONDOPINION":
                this.addonStepsIncluded['test'].active = true
                this.addonStepsIncluded['secInt'].active = true
                this.addonStepsIncluded['ref'].active = true
                break
            case "TESTS":
                this.addonStepsIncluded['test'].active = true
                break
            case "REFERENCES":
                this.addonStepsIncluded['ref'].active = true
                break
            case "CAP":
                this.addonStepsIncluded['select'].active = true
                this.addonStepsIncluded['firstInt'].active = true
                break
          }
        })
        this.state = {
          mobile: window.innerWidth < 768,
        }
        const mql = window.matchMedia("(max-width: 767px)")
        mql.addEventListener("change", e => {
          this.setState({ mobile: e.currentTarget.matches })
        })
    }

    render() {
        const {
            addons,
            realUrl
        } = this.props

        const { mobile } = this.state

        return (
            <div className='test'>
                <Services>
                    <Title>
                        <span>Bedömning</span>
                    </Title>
                    <SubTitle><span>Bokningsbara tjänster</span></SubTitle>
                    {!!addons.length && <div className='sub-heading'>Se vilka av våra bedömningstjänster ni har bokat</div>}
                    {!addons.length && <div className='sub-heading-missing'>Ni har inga bedömningstjänster bokade än</div>}
                    <AddonStepsList>
                        {
                            Object.keys(this.addonStepsIncluded).map((keyName, i) => (
                            <li key={i}>
                                <span className="addonStepName">{this.addonStepsIncluded[keyName].displayname}</span>
                                {
                                    this.addonStepsIncluded[keyName].active
                                    ? <span className="addonStepStatus addonStepActive">Bokad</span>
                                    : <PrimaryLinkButton className="orderAddon" onClick={() => this.props.openOrder(this.addonStepsIncluded[keyName].displayname)}>Beställ</PrimaryLinkButton>
                                }                           
                            </li>
                            ))
                        }                        
                    </AddonStepsList>


                </Services>
            </div>
        )
    }
}