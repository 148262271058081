import { gql } from "@apollo/client"

export const CREATE_ADDRESS = gql`
  mutation CreateAddress($userId: String!, $postalCity: String!, $postalCode: String!) {
    createAddress(data: { user: { connect: { id: $userId } }, postalCity: $postalCity, postalCode: $postalCode }) {
      id
    }
  }
`
export const SEND_APPLICATION = gql`
  mutation SendApplication(
    $candidateId: String!
    $email: String!
    $recruitmentId: String!
    $recruitmentTitle: String!
    $cvId: String
    $docsIds: [String!]!
    $answers: Json
    $links: String
    $information: String
  ) {
    sendApplication(
      candidateId: $candidateId
      email: $email
      recruitmentId: $recruitmentId
      recruitmentTitle: $recruitmentTitle
      cvId: $cvId
      docsIds: $docsIds
      answers: $answers
      links: $links
      information: $information
    ) {
      id
    }
  }
`
export const REPAIR_APPLICATION = gql`
  mutation RepairApplication($applicationId: String!, $recruitmentId: String!) {
    repairApplication(applicationId: $applicationId, recruitmentId: $recruitmentId) {
      id
    }
  }
`
export const REGISTER_AND_APPLY = gql`
  mutation RegisterAndApply(
    $email: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $mobile: String
    $pictureId: String
    $postalCity: String
    $postalCode: String
    $interestCompanyId: String
    $occupationalGroupsIds: [String!]!
    $recruitmentId: String!
    $recruitmentTitle: String!
    $cvId: String
    $docsIds: [String!]!
    $answers: Json
    $links: String
    $information: String
    $spam: Boolean
  ) {
    registerAndApply(
      email: $email
      password: $password
      firstName: $firstName
      lastName: $lastName
      mobile: $mobile
      pictureId: $pictureId
      postalCity: $postalCity
      postalCode: $postalCode
      interestCompanyId: $interestCompanyId
      occupationalGroupsIds: $occupationalGroupsIds
      recruitmentId: $recruitmentId
      recruitmentTitle: $recruitmentTitle
      cvId: $cvId
      docsIds: $docsIds
      links: $links
      information: $information
      answers: $answers
      spam: $spam
    ) {
      id
    }
  }
`
export const UPDATE_APPLICANT = gql`
  mutation UpdateApplicant(
    $id: String!
    $firstName: String
    $lastName: String
    $mobile: String
    $addressId: AddressWhereUniqueInput!
    $postalCity: String
    $postalCode: String
    $pictureId: FileUpdateOneWithoutPictureUserNestedInput
    $candidateId: CandidateWhereUniqueInput!
    $interestId: InterestWhereUniqueInput!
    $occupationalGroupsIds: [OccupationalGroupWhereUniqueInput!]!
    $spam: Boolean
  ) {
    updateUser(where: { id: $id }, data: { firstName: { set: $firstName }, lastName: { set: $lastName }, mobile: { set: $mobile }, picture: $pictureId }) {
      id
    }
    updateAddress(where: $addressId, data: { postalCity: { set: $postalCity }, postalCode: { set: $postalCode } }) {
      id
    }
    updateCandidate(where: $candidateId, data: { spam: { set: $spam } }) {
      id
    }
    updateInterest(where: $interestId, data: { occupationalGroups: { set: $occupationalGroupsIds } }) {
      id
    }
  }
`
export const GET_CANDIDATE = gql`
  query Candidate($email: String!) {
    user(where: { email: $email }) {
      id
      firstName
      lastName
      email
      mobile
      address {
        id
        postalCity
        postalCode
      }
      picture {
        id
        name
        url
      }
      candidate {
        id
        applications {
          id
          cv {
            id
            name
            url
          }
          docs {
            id
            name
            url
          }
          answers
          links
          information
          process(orderBy: { order: { sort: asc } }) {
            id
          }
          recruitment {
            id
            title
          }
        }
        interests {
          id
          cv {
            id
            name
            url
          }
        }
        spam
      }
    }
  }
`
export const APPLICATIONS_PROCESS_QUERY = gql`
  query ApplicationsQuery($recruitmentId: String!) {
    applications(where: { recruitment: { id: { equals: $recruitmentId } } }) {
      id
      process(orderBy: { order: { sort: asc } }) {
        id
        type
        state
      }
    }
  }
`
export const APPLICATIONS_QUERY = gql`
  query ApplicationsQuery($recruitmentId: String!) {
    applications(orderBy: { createdAt: desc }, where: { recruitment: { id: { equals: $recruitmentId } } }) {
      id
      createdAt
      candidate {
        id
        user {
          id
          email
          mobile
          fullName
          firstName
          lastName
          address {
            postalCity
            postalCode
          }
        }
      }
      expired
      process(orderBy: { order: { sort: asc } }) {
        id
        order
        type
        subType
        state
        start
        stop
        text
        backing
        backingHTML
      }
      cv {
        id
        name
        url
      }
      docs {
        id
        name
        url
      }
      docsAddition {
        id
        name
        url
      }
      notes
      answers
      links
      information
      grade
      symbols {
        id
        order
        name
        text
        symbolTexts(where: { recruitment: { id: { equals: $recruitmentId } } }) {
          id
          symbolId
          text
        }
        origin
        editable
      }
      recruitment {
        id
        title
        state
        process(orderBy: { order: { sort: asc } }) {
          id
          type
          state
        }
        symbols {
          id
          order
          name
          text
          origin
          editable
        }
        selection(orderBy: { order: asc }) {
          id
          answers(orderBy: { position: asc }) {
            id
            value
          }
          text
          type
          order
          company {
            id
          }
        }
        company {
          id
          name
          urlName
          templates(orderBy: { name: asc }) {
            id
            name
            type
            content
            contentHTML
          }
        }
      }
      interviews {
        id
        status
        interview {
          id
          startTime
        }
      }
      messageLogCount
    }
  }
`
export const APPLICATION_QUERY = gql`
  query ApplicationQuery($id: String!) {
    application(where: { id: $id }) {
      id
      expired
      createdAt
      candidate {
        id
        user {
          id
          firstName
          lastName
          fullName
          mobile
          email
          picture {
            contentType
            url
          }
          address {
            postalCity
            postalCode
          }
        }
      }
      process(orderBy: { order: { sort: asc } }) {
        id
        order
        type
        subType
        state
        start
        stop
        text
        backing
        backingHTML
      }
      cv {
        id
        name
        url
      }
      docs {
        id
        name
        url
      }
      docsAddition {
        id
        name
        url
      }
      workingDocs(orderBy: { name: asc }) {
        id
        name
        type
        application {
          id
        }
        recruitment {
          id
          recruiter {
            id
            firstName
            lastName
            fullName
          }
          recruiterExternals {
            id
            firstName
            lastName
            fullName
          }
          recruiterGuests {
            id
            firstName
            lastName
            fullName
          }
        }
        file {
          id
          name
          url
          fileCompany {
            id
          }
        }
        content
        contentHTML
        readonly
        visible {
          id
        }
      }
      notes
      answers
      links
      information
      grade
      symbols {
        id
        order
        name
        text
        origin
        editable
      }
      recruitment {
        id
        title
        state
        process(orderBy: { order: { sort: asc } }) {
          id
          type
          state
        }
        symbols {
          id
          order
          name
          text
          origin
          editable
        }
        selection(orderBy: { order: asc }) {
          id
          answers(orderBy: { position: asc }) {
            id
            value
          }
          text
          type
          order
          company {
            id
          }
        }
        company {
          id
          name
          urlName
          templates(orderBy: { name: asc }) {
            id
            name
            type
            content
            contentHTML
          }
        }
        recruiter {
          id
          firstName
          lastName
          fullName
        }
        recruiterExternals {
          id
          firstName
          lastName
          fullName
        }
        recruiterGuests {
          id
          firstName
          lastName
          fullName
        }
        settings
      }
      interviews {
        id
        status
        interview {
          id
          startTime
          endTime
          spots
          vacantSpots
          location
          note
          guest
          interviewers {
            id
            fullName
            firstName
            lastName
          }
        }
      }
    }
  }
`
export const APPLICATION_WORKING_DOCS_QUERY = gql`
  query ApplicationQuery($id: String!) {
    application(where: { id: $id }) {
      id
      workingDocs(orderBy: { name: asc }) {
        id
        name
        type
        application {
          id
        }
        recruitment {
          id
        }
        file {
          id
          name
          url
        }
        content
        contentHTML
        readonly
        visible {
          id
        }
      }
    }
  }
`
export const APPLICATION_MESSAGE_LOGS_QUERY = gql`
  query ApplicationMessageLogs($applicationId: String!) {
    application(where: { id: $applicationId }) {
      messageLogs {
        id
        createdAt
        from
        to {
          id
          value
        }
        subject
        body
      }
    }
  }
`
export const DOCUMENT_QUERY = gql`
  query DocumentQuery($id: String!) {
    document(where: { id: $id }) {
      id
      name
      type
      application {
        id
      }
      recruitment {
        id
      }
      file {
        id
        name
        url
      }
      content
      contentHTML
      readonly
      visible {
        id
      }
    }
  }
`
