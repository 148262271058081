import { memoize } from 'lodash'
import {
    flow,
    join,
    map,
    sortBy
} from 'lodash/fp'

const calculateScore = ({ publicationChannels, hasOther }) => {
  let score = 0

  flow(
    sortBy('sort'),
    map('title'),
    map((title) => {
      switch (title) { 
        case "Arbetsförmedlingen - genom Jobbet.se":
          score += 15
          break
        case "Facebook":
          score += 20
          break
        case "Instagram":
          score += 20
          break
        case "Linkedin":
          score += 40
          break
        case "Jobbet.se":
          score += 20
          break
        case "Snapchat":
          score += 15
          break
        case "Tiktok":
          score += 15
          break
        default:
          break
      }
    })
  )(publicationChannels)

  if ( hasOther ) score += 15

  return score // Max should be 160 degrees
}

export const memoizedCalculateScore = memoize(calculateScore, ({ publicationChannels, hasOther }) => {
    const keyParts = []

    const pubChannelKeyPart = flow(
        sortBy('sort'),
        map('id'),
        join('_')
    )(publicationChannels)

    if ( pubChannelKeyPart ) keyParts.push(pubChannelKeyPart)

    keyParts.push(`other:${hasOther ? 't' : 'f'}`)

    return keyParts.join("_")
})