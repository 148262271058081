import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import Falafel from "_images/menu-dots.svg"
import handleClickOutside from "_utils/handleClickOutside"
import { object } from "prop-types"

//#region Styles
const Content = styled.div`
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
  width: ${props => `${props.width || 300}px`};
  box-shadow: 0px 2px 0px var(--color-line);

  &:hover {
    box-shadow: 0px 2px 0px var(--color-line-dark);
  }
`
const Input = styled.input`
  cursor: pointer;
  width: 100%;
  color: var(--color-text-dark);
  background: url(${Falafel}) scroll 100% 50% no-repeat var(--color-bg-white);
  background-size: 24px 16px;

  &::-webkit-input-placeholder {
    color: var(--color-text-dark);
  }

  &[type="text"] {
    padding-right: 10%;
  }

  &:focus {
    cursor: text;
  }

  &[disabled] {
    background: var(--color-bg-lightest);
  }
`
const Dropdown = styled.div`
  position: absolute;
  left: 0;
  display: flex;
  min-width: 100%;
  flex-flow: column nowrap;
  background: var(--color-bg-white);
  border: 1px solid var(--color-line-dark);
  box-shadow: 2px 2px 10px var(--color-line-dark);
  z-index: 100;

  &:focus {
    outline: none;
  }
`
const ValueList = styled.ul`
  margin: 0;
  padding: 0;
  overflow-y: auto;
  list-style-type: none;
`
const ListItem = styled.li`
  color: var(--color-text-dark);
  background-color: transparent;

  label {
    cursor: pointer;
    display: block;

    span {
      display: block;
      padding: 10px;
    }
  }

  input[type="checkbox"] {
    display: none;

    &:checked + span {
      color: var(--color-text-white);
      background: var(--color-nav-1);
    }
  }

  &.no-values {
    padding: 10px;
  }

  &:nth-child(even) {
    background: var(--color-bg-lightest);
  }

  &.hovered {
    color: var(--color-text-white) !important;
    background: var(--color-nav-1) !important;
  }

  &.selected:not(.hovered) {
    color: var(--color-text-white) !important;
    background: var(--color-nav-1) !important;
  }
`
const Title = styled.h4`
  flex: 0 0 auto;
  margin: 0;
  padding: 5px 10px;
  font-weight: 400;
  color: var(--color-text-white);
  background: var(--color-nav-2);
`
//#endregion

const ValuePicker = ({ reset, noMatch = 'Inga träffar', values = [], current = null, placeholder = 'Välj...', disabled = (false), disabledPlaceholder = 'Ingen vald', onSelected, width, required }) => {
  
  const getPlaceholder = () => (disabled ? disabledPlaceholder || 'Ingen vlad' : placeholder || 'Välj')
  
  const [showList, setShowList] = useState(false)
  const [filtered, setFiltered] = useState(values)
  const [selected, setSelected] = useState(current || null)
  const [textInput, setTextInput] = useState('')
  const [filterText, setFilterText] = useState('')
  const [dropStyle, setDropStyle] = useState({})
  const [placeholderText, setPlaceholderText] = useState(getPlaceholder)
  const [hovered, setHovered] = useState(null)
  const [isUsingKeyboard, setIsUsingKeyboard] = useState(false)
  
  const dropdownListRef = useRef(null)
  const textInputRef = useRef(null)
  const pristineRef = useRef(selected)
  
  const filteredValues = filterText ? values.filter(item => item.toLowerCase().includes(filterText)) : values
  
  const closeDropDown = (shouldBlur = true, resetToPristine = false) => {
    if (resetToPristine) {
      setSelected(pristineRef.current)
    }

    setShowList(false) 
    setFilterText('')
    setTextInput('')
    setHovered(null)
    setIsUsingKeyboard(false)
    
    if (shouldBlur && textInputRef.current){
      textInputRef.current.blur()
    }
  }

  const keyDown = (e) => {
    setIsUsingKeyboard(true)

    if (e.key === 'Escape') {
      closeDropDown(true, true)
    } 
    else if (e.key === 'Enter') {
      if (hovered || selected) {

        const valueToSelect = hovered || selected

        if (onSelected) {
          onSelected(valueToSelect)
        }

        selectValue({ value: hovered || selected, checked: true })
        closeDropDown(true, false)
      }
    }
    else if (e.key === 'Tab') {
      closeDropDown(false, true)
    }
    else if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault()
      
      const index = filteredValues.indexOf(hovered || selected)
      const newIndex = e.key === 'ArrowUp' ? index - 1 : index + 1
  
      if (newIndex >= 0 && newIndex < filteredValues.length) {
        const markedValue = filteredValues[newIndex]
        setSelected(markedValue)
        setHovered(null)

        if (!filterText) {
          setTextInput(pristineRef.current)
        }
      }
    }
  }

  const userInput = (e) => {
    const value = e.target.value
    setFilterText(value.trim().toLowerCase())
    setTextInput(value)
  }

  const inputFocus = () => {
    if (!showList) {
      pristineRef.current = selected

      const docHeight = document.body.offsetHeight
      const inputRect = textInputRef.current.getBoundingClientRect()
      const showListAbove = inputRect.top > docHeight / 2
      const listMaxHeight = 250

      setDropStyle({
        maxHeight: listMaxHeight,
        [showListAbove ? 'bottom' : 'top']: "100%"
      })

      setShowList(true)
      setFilterText('')
      setTextInput('')
      setHovered(selected)
    }
  }

  const selectValue = (input = object) => {
    let value, checked

    if (input.target) {
      value = input.target.value
      checked = input.target.checked
    } 
    else {
      value = input.value
      checked = input.checked
    }

    if (checked && value !== selected) {
      setPlaceholderText(value)
      setSelected(value)
      setHovered(null)
      setShowList(false)
      setTextInput(value)

      if (onSelected) onSelected(value)

    } 
    else if (!checked && selected !== null) {
      const newSelected = required ? selected : null
      setSelected(newSelected)
      setHovered(null)
      setPlaceholderText(getPlaceholder())
      setShowList(false)
      setTextInput(newSelected ? newSelected.name : "")

      if (onSelected) onSelected(newSelected)
    }
  }

  const handleMouseOver = (value) => {
    if (!isUsingKeyboard) setHovered(value)
  }

  const handleMouseOut = () => setHovered(null)

  const handleMouseMove = () => setIsUsingKeyboard(false)


  // Reset to the latest saved object 
  useEffect(() => {
    if (reset) {
      setSelected(current)
      setTextInput(current ? current.name : '')
    }
  }, [reset, current])
  
  // Updates filtered values, selected items and placholder text
  useEffect(() => {
    setFiltered(values)
    setSelected(current || null)
    setPlaceholderText(getPlaceholder())
  }, [values, current, disabled, disabledPlaceholder, placeholder])

  // Closes dropdown on outside click
  useEffect(() => {
    if (!showList) return

    return handleClickOutside([textInputRef, dropdownListRef], () => closeDropDown(true, true))
  }, [showList])

  // Scrolls dropdown to ensure the selected item is visible
  useEffect(() => {
    if (!showList || !selected || !dropdownListRef.current) return

    const dropdownListBounds = dropdownListRef.current.getBoundingClientRect()
    const index = filteredValues.indexOf(selected)
    const selectedElement = document.getElementById(index)
    if (!selectedElement) return
    const selectedBounds = selectedElement.getBoundingClientRect()

    if (selectedBounds.top < dropdownListBounds.top) {
      dropdownListRef.current.scrollTop -= dropdownListBounds.top - selectedBounds.top
    }
    else if (selectedBounds.bottom > dropdownListBounds.bottom) {
      dropdownListRef.current.scrollTop += selectedBounds.bottom - dropdownListBounds.bottom
    }
  }, [showList, selected, filtered])

  return (
    <Content width={width}>
      <Input
        type="text"
        placeholder={selected || placeholderText || ""}
        ref={textInputRef}
        value={textInput || ""}
        disabled={disabled}
        onClick={inputFocus}
        onChange={userInput}
        onKeyDown={keyDown}
        onFocus={inputFocus}
      />
      {showList && (
        <Dropdown tabIndex="1" className="value-picker-dropdown" style={dropStyle} onMouseMove={handleMouseMove}>
          <Title>{placeholder || "Välj..."}</Title>
          <ValueList ref={dropdownListRef}>
            {filteredValues.length ? (
              filteredValues.map((item, index) => (
                <ListItem
                  key={index}
                  id={index}
                  className={`
                    ${!isUsingKeyboard && item === hovered ? 'hovered' : ''} 
                    ${item === selected && (!hovered || isUsingKeyboard) ? 'selected' : ''}
                  `}
                  onMouseOver={() => handleMouseOver(item)}
                  onMouseOut={handleMouseOut}
                >
                  <label>
                    <input
                      tabIndex="0"
                      type="checkbox"
                      className="value-picker-dropdown-input"
                      value={item}
                      onChange={selectValue}
                      defaultChecked={item === selected}
                    />
                    <span>{item}</span>
                  </label>
                </ListItem>
              ))
            ) : (
              <ListItem key="0" className="no-values">
                {noMatch}
              </ListItem>
            )}
          </ValueList>
        </Dropdown>
      )}
    </Content>
  )
}

export default ValuePicker