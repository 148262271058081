import React from "react"
import styled from "styled-components"
import { isLive } from "_services/util"
import { JOBB } from "_routes/url-names"
import { Input } from "_layout/form-elements"
import { SecondaryButton } from "_layout/buttons"
import shareableLinkLogo from "_images/sharablelink-channel.png"
import message from "_components/message"

//#region Styles
const Channel = styled.div`
  display: inline-block;
  width: 304px;
  background: var(--color-bg-white);
  border-bottom: 2px solid var(--color-line-dark);
`
const ChannelHeader = styled.div`
  position: relative;
  height: 40px;
  background-color: var(--color-bg-lightest);

  .publishchannel__logo {
    position: absolute;
    bottom: 0;
    transform: translateY(50%);
    left: 20px;
  }
`
const ChannelContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 24px;
  text-align: center;

  .channel__title {
    font-weight: bold;
    margin-bottom: 8px;
  }
  .channel__description {
    color: var(--color-text);
    margin: 0 0 16px;
  }
  button {
    align-self: center;
  }
`
const ShareableInput = styled(Input)`
  width: 100%;
  padding: 8px;
`
//#endregion

export default class ShareableLinkChannel extends React.PureComponent {
  constructor(props) {
    super(props)
    this.textInput = React.createRef()
  }

  handelCopyText = () => {
    navigator.clipboard.writeText(this.textInput.current.value).then(async () => {
      await message("Länken är nu kopierad och kan klistras in på önskad plats.")
    })
  }

  slug = input => {
    return (
      input &&
      input
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/[^a-zA-Z0-9\s]/g, "")
        .replace(/\s+/g, "-")
        .toLowerCase()
    )
  }

  render() {
    const { jobAdId, jobAdTitle, companyName } = this.props
    if (!jobAdId || !jobAdTitle || !companyName) return ""
    const titleSlug = this.slug(jobAdTitle)
    const companySlug = this.slug(companyName)
    const url = isLive() ? `https://www.jobbet.se${JOBB}/${companySlug}/${titleSlug}/${jobAdId}` : `http://jobbet.devserver.phosdev.se${JOBB}/${companySlug}/${titleSlug}/${jobAdId}`

    return (
      <Channel className={this.props.className}>
        <ChannelHeader>
          <img className="publishchannel__logo" src={shareableLinkLogo} alt="Loggo för publiceringskanal - Spårbar länk" />
        </ChannelHeader>
        <ChannelContent>
          <header className="channel__title">Spårbar länk</header>
          <p className="channel__description">Länk för spridning i övriga kanaler.</p>
          <ShareableInput readOnly={true} defaultValue={url} ref={this.textInput} />
          <SecondaryButton onClick={this.handelCopyText}>Kopiera länk</SecondaryButton>
        </ChannelContent>
      </Channel>
    )
  }
}
