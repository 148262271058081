import React from 'react'
import styled from 'styled-components'
import MemberLayout from './member-layout'
import { MEDLEM } from '_routes/url-names'
import { PrimaryLinkButton } from '_root/layout/buttons';

//#region Styles
const Section = styled.section`
  margin: 0 0 20px 0;
  padding: 20px 40px;
  border-radius: 10px;
  background: var(--color-bg-white);
`
const H1 = styled.h1`
  margin: 0;
  color: var(--color-text);
`
const Ingress = styled.p`
  margin-bottom: 30px;
`
//#endregion

const MemberConfirmation = () => {
  return (
    <MemberLayout nomenu>
      <Section>
        <H1>Tack för din registrering!</H1>
      </Section>
      <Section>
        <Ingress>En bekräftelse har skickats till din e-post.</Ingress>
        <p>
          <PrimaryLinkButton to={MEDLEM}>Logga in</PrimaryLinkButton>
        </p>
      </Section>
    </MemberLayout>
  )
}
export default MemberConfirmation