import { setContext } from "@apollo/client/link/context"
import { userToken, expiredUser } from '_services/login-service'

function parseJwt (token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = userToken()
    // return the headers to the context so httpLink can read them
    if (token) {
      const jwToken = parseJwt(token)
      const currentSecond = new Date().getTime() / 1000
      const exp = jwToken.exp || 0
      if (currentSecond > exp) {
        expiredUser()
        return null
      }
      return {
            headers: {
              ...headers,
              authorization: `Bearer ${token}`
            }
          }
    } else {
        return {
            headers: {
                ...headers
            }
        }
    }
})

export default authLink