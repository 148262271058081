import React from 'react'
import styled from 'styled-components'
import { SecondaryButton } from '_layout/buttons'

const StyledButton = styled(SecondaryButton)`
    pointer-events: inherit;
    width: 130px;

    & + .react-datepicker__close-icon {
        &::after {
            background-color: var(--color-brand-green-dark);
        }
    }
`

const DateButton = ({onClick, style, label, value}) =>
  <StyledButton onClick={onClick} style={style} data-tooltip-id = "root-tooltip" data-tooltip-content={label}>
    {value||label}
  </StyledButton>

export default DateButton

