import React from "react"
import { graphql, withApollo } from "@apollo/client/react/hoc"
import { compose } from "react-recompose"
import { NavLink, Outlet } from "react-router-dom"
import withRouter from "_hoc/withRouter"
import { Tooltip } from "react-tooltip"
import styled from "styled-components"
import { parseISO, differenceInDays } from "date-fns"
import { isEmpty, some } from "lodash/fp"
import { history } from "_root/store"
import MainLayout from "_layout/main-layout"
import { Spinner, ButtonNavLink } from "_layout/form-elements"
import NotesInput from "_components/applications/notes-input"
import NotesIcon from "_images/notes.svg"
import NotesDarkIcon from "_images/notes-dark.svg"
import StateLink from "_components/menu/state-link"
import { RecruitmentState, UserRoles, HAS_SUBSCRIPTION } from "_root/constants"
import * as url from "_routes/url-names"
import { RECRUITMENT_QUERY, UPDATE_RECRUITMENT } from "_containers/recruitment/recruitment-ql"
import inject from "_services/inject"
import to from "_services/await.to"

//#region Styles
const Header = styled.div`
  position: relative;
  min-height: 150px;
  border-bottom: 2px solid var(--color-line);
  padding: 25px 40px 0 40px;
  background: var(--color-bg-white);

  &.blocked {
    opacity: 0.2;
    pointer-events: none;
  }

  @media screen and (max-width: 767px) {
    padding: 15px 20px 0 20px;
    min-height: unset;
  }
`
const EditLink = styled(ButtonNavLink)`
  position: absolute;
  top: 16px;
  right: 0;
  display: inline-block;
  width: auto;
  margin: 0;
  padding: 9px 57px;
  font-size: 0.9em;
  border-radius: 20px;
  color: var(--color-text-white);
  background: var(--color-brand-green);

  @media screen and (max-width: 767px) {
    display: none;
  }
`
const Content = styled.section`
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  overflow-y: scroll;

  @media screen and (max-width: 767px) {
    overflow-y: unset;
  }
`
const Blocked = styled.div`
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  background: var(--color-bg-white);
`
const TitleContainer = styled.div`
  position: relative;
  padding-bottom: 50px;
  padding-right: 300px;

  .title {
    font-size: 30px;
    line-height: 34px;
    font-weight: 400;
  }

  .small {
    padding-top: 4px;
    padding-bottom: 6px;
    color: var(--color-text);
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
  }

  @media screen and (max-width: 767px) {
    padding-right: 0;
    padding-bottom: 10px;

    .title {
      font-size: 20px;
    }
  }
`
const Menu = styled.ul`
  position: absolute;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  left: 30px;
  right: 30px;
  bottom: 0;
  margin: 0;
  padding: 0;
  list-style-type: none;
  border-top: 1px solid var(--color-line);
  background: var(--color-bg-white);

  @media screen and (max-width: 767px) {
    display: none;
  }
`
const MenuItem = styled.li`
  background: var(--color-bg-white);

  &.wide {
    margin-right: auto;
  }
`
const MenuLink = styled(NavLink)`
  cursor: pointer;
  display: inline-block;
  margin: 0 10px;
  padding: 10px 0 5px 0;
  color: var(--color-text-medium);
  text-decoration: none;
  white-space: nowrap;
  border-bottom: 5px solid transparent;

  &.active,
  &.active:hover {
    color: var(--color-text-dark);
    border-bottom: 5px solid var(--color-brand-red-light);
  }

  &:hover {
    text-decoration: none;
    border-bottom: 5px solid var(--color-bg-grey);
  }
`
const Notes = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 100%;
  background: var(--color-bg-white);

  i {
    width: 17px;
    height: 20px;
    background: url(${props => (props.$active ? NotesDarkIcon : NotesIcon)}) scroll no-repeat transparent;

    &:hover {
      opacity: 0.5;
    }
  }
`
//#endregion

@compose(
  withRouter,
  withApollo,
  inject("user"),
  graphql(RECRUITMENT_QUERY, { name: "recruitmentQuery", options: props => ({ fetchPolicy: "network-only", variables: { id: props.match.params.recruitment } }) }),
  graphql(UPDATE_RECRUITMENT, { name: "updateRecruitment" })
)
export default class Applications extends React.Component {
  constructor(props) {
    super(props)
    this.recruitmentId = props.match.params.recruitment
    this.state = {}
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const nextState = {}
    if (nextProps.recruitmentQuery?.recruitment && !nextProps.recruitmentQuery.loading) {
      nextState.recruitment = { ...nextProps.recruitmentQuery.recruitment }
      nextState.notes = nextState.recruitment.notes
    }
    return !isEmpty(nextState) ? nextState : null
  }

  componentDidUpdate = () => {
    this.checkNavigation()
  }

  checkNavigation = () => {
    if (!this.state || !this.state.recruitment || !this.state.recruitment.state) return
    const { params } = this.props.match
    const { state } = this.state.recruitment
    const urlState = state === RecruitmentState.DRAFT ? url.REK_UTKAST : state === RecruitmentState.ACTIVE ? url.REK_AKTIVA : url.REK_AVSLUTADE
    const paramState = `/${params.state}`
    if (paramState !== urlState) {
      history.replace(history.location.pathname.replace(paramState, urlState))
    }
  }

  onSingleNotes = async (id, notes) => {
    const [error, result] = await to(this.props.updateRecruitment({ variables: { id: id, recruitment: { notes: { set: notes } } } }))
    if (error || !result) {
      console.error("recruitment-applications:onSingleNotes", error)
      return
    }
    this.setState({ notes: notes })
  }

  getNotesTooltip = () => {
    const { notes, recruitment } = this.state
    return <NotesInput key={notes} id={recruitment.id} big notes={notes ? notes.replaceAll("</br>", "\n") : ""} onChange={this.onSingleNotes} />
  }

  hasWriteAccess = () => {
    const { user } = this.props
    if (user.superAdmin || user.groupAdmin || user.isAdmin) return true
    const { recruiter, recruiterGuests, recruiterExternals } = this.state.recruitment
    const hasPermission = user && (recruiter.id === user.id || some({ id: user.id })(recruiterExternals) || some({ id: user.id })(recruiterGuests))
    return hasPermission && [UserRoles.GROUPVIEWER, UserRoles.VIEWER].includes(user.role) === false
  }

  refetch = () => {
    this.props.recruitmentQuery.refetch()
  }

  render() {
    if (this.props.recruitmentQuery.loading || !this.state.recruitment) {
      return (
        <MainLayout>
          <Header>
            <Spinner />
          </Header>
          <Content>
            <Spinner />
          </Content>
        </MainLayout>
      )
    }
    const { match, user } = this.props
    const { recruitment, notes } = this.state
    const { recruiter, recruiterGuests, recruiterExternals } = recruitment
    const realUrl = `/${match.params.company}${url.REKRYTERING}/${match.params.state}/${match.params.recruitment}${url.REK_APPLICATIONS}`
    const editUrl = `/${match.params.company}${url.REKRYTERING}/${match.params.state}/${match.params.recruitment}`
    const userAdmin = user.superAdmin || user.groupAdmin || user.isAdmin
    const hasPermission = recruiter.id === user.id || some({ id: user.id })(recruiterExternals) || some({ id: user.id })(recruiterGuests)
    const blocked = !userAdmin && (!hasPermission || (recruitment.company.subscription !== HAS_SUBSCRIPTION && differenceInDays(new Date(), parseISO(recruitment.applyStart)) > 90))

    return (
      <MainLayout maxHeight>
        <Header>
          <TitleContainer>
            <div className="small">Hantera ansökningar</div>
            <span className="title">
              {recruitment.title}
              {recruitment.company && ", " + recruitment.company.name}
            </span>
            {this.hasWriteAccess() && <StateLink state={recruitment.state} to={editUrl + url.REK_KANALER + "#publicering"} />}
            {this.hasWriteAccess() && <EditLink to={editUrl}>Redigera</EditLink>}
          </TitleContainer>
          <Menu>
            <MenuItem className="wide">
              <MenuLink end to={realUrl}>
                Översikt och ansökningar
              </MenuLink>
            </MenuItem>
            {user.superAdmin && (
            <MenuItem>
              <Notes $active={!!notes}>
                <i data-tooltip-id={"noteTip"} />
              </Notes>
              <Tooltip id="noteTip" variant="light" border="1px solid var(--color-line)" place="left" effect="solid" content={this.getNotesTooltip()} clickable delayHide={700} className="stay" />
            </MenuItem>
            )}
          </Menu>
        </Header>
        <Content>
          {blocked && <Blocked>Du har inte rätt att se denna rekrytering. {hasPermission && "Kontakta support för att aktivera ditt avtal."}</Blocked>}
          {!blocked &&
          <Outlet context={[recruitment, this.refetch]} />
          }
        </Content>
      </MainLayout>
    )
  }
}
