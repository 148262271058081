import { gql } from "@apollo/client"

export const ALL_USERS_QUERY = gql`
  query UserQuery {
    users(where: { role: { in: [SUPERADMIN, SITEADMIN] }, disabled: { equals: false } }) {
      id
      firstName
      lastName
    }
  }
`
export const ACTIVE_COMPANIES = gql`
  query ActiveCompanies {
    companies(where: { AND: [{ group: { equals: false } }, { active: { equals: true } }] }, orderBy: { name: asc }) {
      id
      name
      urlName
    }
  }
`
export const CREATE_ADDRESS = gql`
  mutation CreateAddress($address: String, $postalCode: String, $postalCity: String) {
    createAddress(data: { address: $address, postalCode: $postalCode, postalCity: $postalCity }) {
      id
    }
  }
`
export const COMPANY_QUERY = gql`
  query CompanyQuery($urlName: String!) {
    company(where: { urlName: $urlName }) {
      id
      active
      group
      groups {
        id
        name
        urlName
      }
      groupies {
        id
        name
        urlName
      }
      name
      urlName
      category
      subscription
      careerPage
      description
      billingInfo
      address {
        id
        address
        postalCode
        postalCity
      }
      logotype {
        id
        url
      }
      employees {
        id
        role
        active
        firstName
        lastName
        email
      }
      contactPersons {
        id
        active
        firstName
        lastName
      }
      jobbetContacts {
        id
        firstName
        lastName
      }
      policyUser {
        id
        active
        firstName
        lastName
        email
      }
      policyAccepted
    }
  }
`
export const COMPANY_CONTACTS = gql`
  query CompanyContacts($urlName: String!) {
    company(where: { urlName: $urlName }) {
      id
      subscription
      jobbetContacts(take: 2) {
        id
        firstName
        lastName
        phone
        mobile
        email
        picture {
          url
        }
      }
    }
  }
`
export const COMPANY_UPDATE_MUTATION = gql`
  mutation UpdateCompany(
    $id: String!
    $active: Boolean
    $name: String
    $category: String
    $subscription: String
    $careerPage: Boolean
    $description: String
    $billingInfo: String
    $logotype: FileUpdateOneWithoutLogoCompanyNestedInput
    $jobbetContactsIds: [UserWhereUniqueInput!]
    $contactPersonsIds: [UserWhereUniqueInput!]
    $policyUserId: UserWhereUniqueInput
    $addressId: String!
    $address: NullableStringFieldUpdateOperationsInput
    $postalCode: NullableStringFieldUpdateOperationsInput
    $postalCity: NullableStringFieldUpdateOperationsInput
  ) {
    updateAddress(where: { id: $addressId }, data: { address: $address, postalCode: $postalCode, postalCity: $postalCity }) {
      id
    }
    updateCompany(
      where: { id: $id }
      data: {
        active: { set: $active }
        name: { set: $name }
        category: { set: $category }
        subscription: { set: $subscription }
        careerPage: { set: $careerPage }
        description: { set: $description }
        billingInfo: { set: $billingInfo }
        logotype: $logotype
        jobbetContacts: { set: $jobbetContactsIds }
        contactPersons: { set: $contactPersonsIds }
        policyUser: { connect: $policyUserId }
        address: { connect: { id: $addressId } }
      }
    ) {
      id
      active
      name
      urlName
      category
      subscription
      careerPage
      description
      billingInfo
      address {
        id
        address
        postalCode
        postalCity
      }
      logotype {
        id
        url
      }
      contactPersons {
        id
        firstName
        lastName
      }
      jobbetContacts {
        id
        firstName
        lastName
      }
      policyUser {
        id
        active
        firstName
        lastName
        email
      }
    }
  }
`
export const COMPANY_DELETE_LOGOTYPE_MUTATION = gql`
  mutation UpdateCompany($id: String!) {
    updateCompany(where: { id: $id }, data: { logotype: { delete: true } }) {
      id
    }
  }
`
export const INACTIVATE_USER = gql`
  mutation UpdateUserMutation($id: String!) {
    updateUser(where: { id: $id }, data: { active: { set: false } }) {
      id
    }
  }
`
export const SET_GROUPIES = gql`
  mutation SetGroupies($id: String!, $groupies: [CompanyWhereUniqueInput!]!) {
    updateOneCompany(where: { id: $id }, data: { groupies: { set: $groupies } }) {
      id
    }
  }
`
