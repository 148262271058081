import React from 'react'
import styled from 'styled-components'

import Modal from '_components/modal'
import { SecondaryButton } from '_layout/buttons'

const ModalHeader = styled.header`
    font-size: 1.875rem;
    color: var(--color-brand-green);
    margin-bottom: 1rem;
    font-weight: bold;
`

const OrderConfirmedDialog = ({ show, proceed, dismiss, messageHeader, messageText, confirmText }) => {
  return (
    <Modal isOpen={show} onRequestClose={dismiss} center>
      <ModalHeader>{messageHeader}</ModalHeader>
      <p>{messageText}</p>
      <SecondaryButton onClick={() => proceed()}>{confirmText || 'OK'}</SecondaryButton>
    </Modal>
  )
}
export default OrderConfirmedDialog