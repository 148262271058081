import { title } from 'process'
import React from 'react'

const style = {
    pointerEvents: 'inherit'
}

const DateLink = ({ onClick, style, label, value }) =>
  <a onClick={onClick} style={style} title={label}>
    {value || label}
  </a>

export default DateLink 
