import React, { useEffect, useState, useRef } from 'react'
import onClickOutside from 'react-onclickoutside'
import styled from 'styled-components'
import MenuIcon from '_images/menu-dots.svg'
import handleClickOutside from '_utils/handleClickOutside'
import handleKeyPress from '_utils/handleKeyPress'

//#region Styles
const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  width: ${props => props.$small ? 'auto' : '100%'};
  margin-right: ${props => props.$noMargin ? '0px' : '15px'};
  transform-style: flat;
`
const Button = styled.button`
  display: inline-block;
  width: inherit;
  padding: 10px 30px 10px 20px;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid var(--color-bg-light);
  background: url(${MenuIcon}) scroll ${props => props.$hasLabel ? '100%' : '50%'} 50% no-repeat var(--color-bg-white);
  background-size: 24px 16px;
  text-align: left;
  box-shadow: 0px 3px 5px var(--color-bg-grey);
  outline: none;

  &[disabled], &[disabled]:hover {
    opacity: 0.6;
    color: var(--color-text-dark);
    background: var(--color-bg-white);
  }
`
const Content = styled.div`
  position: absolute;
  overflow-y: auto;
  padding: 10px;
  min-width: 100%;
  border: 1px solid var(--color-bg-light);
  background: var(--color-bg-white);
  box-shadow: 1px 1px 10px var(--color-bg-light);
  z-index: 10000;

  ${props => props.$right ? 'right: 0;' : 'left: 0;'}

  @media screen and (max-width: 767px) {
    max-width: 100%;
  }
`
//#endregion

const DropDown = ({ open, toggle, label, children, disabled, right, noMargin, small, className }) => {

  const [dropStyle, setDropStyle] = useState ({})
  const buttonRef = useRef(null)
  const wrapperRef = useRef(null)

  useEffect (() => {
    if (!open) return

    return handleClickOutside([wrapperRef], toggle)
  }, [open, toggle])
  
  useEffect(() => {
    if (!open) return

    return handleKeyPress(['Enter', 'Escape'], toggle)
  }, [open, toggle])

  const handleToggle = () =>{
    if(buttonRef.current){
      const docHeight = document.body.offsetHeight
      const buttonRect = buttonRef.current.getBoundingClientRect()
      const maxHeight = docHeight - buttonRect.bottom
      setDropStyle({ maxHeight })
      toggle()
    }
  }

  const classes = ['drop-down']
  if (open) classes.push('open')
  if (className) classes.push(className)

  return (
    <Wrapper ref={wrapperRef} className={classes.join(' ')} $noMargin={noMargin} $small={small}>
      <Button ref={buttonRef} $hasLabel={!!label} onClick={handleToggle} $open={open} disabled={disabled}>{label}</Button>
      {open &&
      <Content $right={right} style={dropStyle}>
        {children}
      </Content>}
    </Wrapper>
  )
}

export default DropDown