import React from 'react'
import MainLayout from '_layout/main-layout'
import { H2, FlexRow, FlexCol } from '_layout/form-elements'

const Permission = () => {
  return (
    <MainLayout padding>
      <H2>Roller &amp; behörighet</H2>
      <FlexRow className='margin20'>
        <FlexCol>
          <p>Det finns tre roller i systemet: administratör, rekryterare och granskare.</p>
          <h3 className='mt3'>Administratör</h3>
          <strong>Rättighet att ändra och hantera:</strong>
          <p>
            - Företagsinformation<br />
            - Inställningar<br />
            - Användare<br />
            - Roller rättigheter<br />
            - Mallar<br />
            - Dokument<br />
            - Rekryteringsuppdrag
          </p>
          <h3 className='mt3'>Rekryterare</h3>
          <strong>Rättighet att ändra och hantera</strong>
          <p>
            - Rekryteringsuppdrag
          </p>
          <strong>Rättighet att hantera</strong>
          <p>
            - Mallar<br />
            - Dokument
          </p>
          <h3 className='mt3'>Granskare</h3>
          <strong>Rättighet att se</strong>
          <p>
            - Rekryteringsuppdrag
          </p>
        </FlexCol>
      </FlexRow>
    </MainLayout>
  )
}
export default Permission