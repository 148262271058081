import React from 'react';
import styled from 'styled-components'
import withRouter from '_hoc/withRouter'
import { NavLink } from 'react-router-dom'
import { RecruitmentState } from '_root/constants'

//#region Styles
const StateButton = styled(NavLink)`
  cursor: pointer;
  display: flex;
  align-items: center;
  position: absolute;
  top: 18px;
  right: 190px;
  height: 40px;
  margin: 0;
  z-index: 2;
  padding: 10px 20px;
  border: 1px solid var(--color-bg-light);
  border-bottom: none;
  color: var(--color-text-dark);
  background: var(--color-bg-white);
  box-shadow: 0 2px 2px var(--color-bg-light);
  text-decoration: none;

  &:hover {
    background-color: var(--color-bg-lightest);
  }

  &[disabled], &[disabled]:hover {
    cursor: default;
    opacity: 0.5;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`
const Indicator = styled.div`
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 8px;
  border-radius: 50%;
  background-color: ${props => props.$green ? 'var(--color-success)' : (props.$red ? 'var(--color-error)' : 'var(--color-yellow)')};
`
//#endregion

const StateLink = ({ state, to }) => {
  const status = state === RecruitmentState.ACTIVE ? 'Aktiv' : (state === RecruitmentState.DRAFT ? 'Utkast' : 'Avslutad')
  return <StateButton to={to}>
    <Indicator $green={state === RecruitmentState.ACTIVE} $yellow={state === RecruitmentState.DRAFT} $red={state === RecruitmentState.CLOSED} />{status}
  </StateButton>
}

export default withRouter(StateLink)
