import React from "react"
import { Link } from "react-router-dom"
import withRouter from "_hoc/withRouter"
import { withApollo, graphql } from "@apollo/client/react/hoc"
import { gql } from "@apollo/client"
import { compose } from "react-recompose"
import draftToHtml from "draftjs-to-html"
import { format, parseISO } from "date-fns"
import styled from "styled-components"
import { Tooltip } from "react-tooltip"
import map from "lodash/fp/map"
import find from "lodash/fp/find"
import some from "lodash/fp/some"
import filter from "lodash/fp/filter"
import orderBy from "lodash/fp/orderBy"
import uniqBy from "lodash/fp/uniqBy"
import each from "lodash/fp/each"
import { Spinner } from "_layout/form-elements"
import AdMeter from "_components/dashboard/ad-meter"
import { Process, RecruitmentState, UserRoles, ProcessType, REC_STATS_ID, HAS_SUBSCRIPTION } from "_root/constants"
import { REKRYTERING, REK_APPLICATIONS, JOBB } from "_routes/url-names"
import { NEW_PROCESS_MUTATION, COPY_RECRUITMENT_QUESTION, COPY_FILE, COPY_JOB_AD, COPY_RECRUITMENT_MUTATION } from "_containers/recruitment/recruitment-ql"
import { USER_INFO } from "_root/common-ql"
import CopyIconSrc from "_images/menu-copy-icon.svg"
import ViewIconSrc from "_images/menu-preview-icon.svg"
import EditIconSrc from "_images/menu-edit-icon.svg"
import MenuIconSrc from "_images/menu-dots.svg"
import { isLive } from "_services/util"
import inject from "_services/inject"
import to from "_services/await.to"
import all from "_services/await.all"

import { memoizedCalculateScore } from "_modules/recruitments"
import message from "_root/components/message"

//#region Styles
const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  margin: -2px 0 5px 0;
`
const Content = styled.div`
  flex: 1;
  margin: 0;
  overflow-y: scroll;
`
const Row = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;

  &.header {
    height: 38px;
    background: var(--color-bg-light);
    color: var(--color-text);
    z-index: 1;
    font-size: 0.8em;
    overflow-y: scroll;
  }

  &.content {
    min-height: 70px;
    border-top: 1px solid var(--color-bg-lightest);

    &:last-child {
      border-bottom: 1px solid var(--color-bg-lightest);
    }

    &.blocked {
      opacity: 0.5;

      a {
        pointer-events: none;
      }
    }
  }

  @media screen and (max-width: 767px) {
    &.header {
      display: none;
    }

    &.content {
      flex-direction: column;
      border-bottom: 1px solid var(--color-line);
    }
  }
`
const TitleCell = styled.div`
  position: relative;
  flex: 2;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;
  padding: 10px 75px 10px 20px;
  color: var(--color-text-dark);
  background: var(--color-bg-white);

  span:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  .menu-tip {
    opacity: 1;
    padding: 5px 0;
    box-shadow: 1px 1px 3px var(--color-line-dark);
  }

  .header & {
    padding: 0 75px 0 20px;
    border-top: 8px solid var(--color-line-dark);
    border-bottom: 3px solid var(--color-line);

    &.active {
      border-bottom: 3px solid var(--color-brand-black);
    }

    &:hover:not(.no-cursor) {
      cursor: pointer;
      background: var(--color-bg-light);
    }
  }
`
const Menu = styled.span`
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s linear 0s, opacity 0s linear 0s;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 20px;
  right: -15px;
  bottom: 50%;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: url(${MenuIconSrc}) scroll 50% 50% no-repeat var(--color-bg-white);
  background-size: 15px 15px;
  box-shadow: 0px 0px 10px var(--color-text-medium);
  z-index: 500;

  ${TitleCell}:hover & {
    opacity: 1;
    visibility: visible;
    transition: visibility 0s linear 0s, opacity 350ms linear;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`
const MenuLink = styled(Link)`
  display: flex;
  align-items: center;
  padding: 10px 20px 10px 10px;
  color: var(--color-text-dark);
  font-size: 14px;
  line-height: 18px;

  .icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin: 0 10px 0 5px;
    border-radius: 50%;
    background: url(${props => props.$icon}) no-repeat scroll 50% 50% var(--color-bg-white);
  }

  span {
    display: inline-block;
  }

  &:hover {
    background-color: var(--color-bg-lightest);
    cursor: pointer;
  }
`
const TitleLink = styled(Link)`
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  font-size: 1em;
  font-weight: 700;
  color: ${props => (props["data-due"] ? "var(--color-error)" : "var(--color-brand-green)")};
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
  }
  @media screen and (max-width: 767px) {
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
`
const InfoArea = styled.div`
  flex: 5;
  display: flex;
  flex-flow: row nowrap;
  justify-content: stretch;
  min-width: 300px;
`
const InfoCell = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  min-width: 82px;
  background: var(--color-bg-white);
  color: var(--color-text);

  &:nth-child(4) {
    border-left: 1px solid var(--color-line);
  }

  &:last-child {
    border-right: 1px solid var(--color-line);
  }

  &.date {
    padding: 10px;
    font-size: 0.8em;

    span {
      line-height: 1;
    }
  }

  .header & {
    border-top: 8px solid var(--color-line-dark);
    border-bottom: 3px solid var(--color-line);

    &:nth-child(4),
    &:nth-child(5) {
      border-top: 8px solid var(--color-brand-red);
    }

    &.active {
      border-bottom: 3px solid var(--color-brand-black);
    }

    &:hover {
      cursor: pointer;
      background: var(--color-bg-light);
    }
  }

  .content & {
    padding: 15px 0;
  }

  &.views,
  &.applications {
    font-size: 24px;
    line-height: 28px;
    font-weight: 200;

    a {
      color: var(--color-text-dark);

      &:hover {
        text-decoration: none;
      }

      &.new {
        color: var(--color-brand-red);
        font-weight: 700;
      }
    }
  }
`
const ProcessArea = styled.div`
  flex: 4;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: stretch;
  background: var(--color-bg-white);

  .header & {
    cursor: pointer;
    border-top: 8px solid var(--color-brand-green);
    border-bottom: 3px solid var(--color-line);

    &.active {
      border-bottom: 3px solid var(--color-brand-black);
    }

    &:hover {
      background: var(--color-bg-light);

      div {
        background: var(--color-bg-light);
      }
    }
  }
`
const Organisation = styled.div`
  pointer-events: none;
  font-size: 0.8em;

  span {
    white-space: nowrap;
  }
`
const ProcessCell = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  .content & {
    width: 100%;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      margin-top: -1px;
      border-top: 1px solid var(--color-bg-light);
      z-index: 0;
    }

    &.start:before {
      left: 50%;
      right: 0;
    }

    &.both:before {
      left: 0;
      right: 0;
    }

    &.end:before {
      left: 0;
      right: 50%;
    }

    &:after {
      content: "";
      display: block;
      width: 16px;
      height: 16px;
      border-radius: 8px;
      border: 2px solid var(--color-brand-green);
      background: var(--color-bg-white);
      z-index: 0;
    }

    &.started:after {
      border: none;
      background: var(--color-brand-green);
    }

    &.na:after {
      border: 2px dotted var(--color-bg-grey-dark);
      background: var(--color-bg-white);
    }
  }

  .header & {
    cursor: pointer;

    &:hover {
      background: var(--color-bg-light);

      div {
        background: var(--color-bg-light);
      }
    }
  }
`
const Initials = styled.div`
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 17.5px;
  right: 35px;
  bottom: 0;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  color: var(--color-text-dark);
  background: var(--color-bg-grey);

  &.me {
    color: var(--color-text-dark);
    background: var(--color-yellow);
  }

  &.external:after {
    position: absolute;
    content: "+";
    font-size: 20px;
    top: -10px;
    right: -10px;
    color: var(--color-brand-red);
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`
const Narrow = styled.div`
  padding: 5px 0 0 0;
  font-size: 0.8rem;
  background-color: var(--color-bg-white);

  label {
    margin-right: 0.5rem;
    font-weight: 700;
  }

  div.new * {
    color: var(--color-brand-red);
    font-weight: 700;
  }
`
//#endregion

@compose(
  withRouter,
  withApollo,
  inject("user"),
  graphql(USER_INFO, { name: "getUserInfo", options: props => ({ fetchPolicy: "network-only", variables: { id: props.user.id } }) }),
  graphql(NEW_PROCESS_MUTATION, { name: "newProcess" }),
  graphql(COPY_RECRUITMENT_QUESTION, { name: "copyQuestion" }),
  graphql(COPY_FILE, { name: "copyFile" }),
  graphql(COPY_JOB_AD, { name: "copyJobAd" }),
  graphql(COPY_RECRUITMENT_MUTATION, { name: "copyRecruitment" })
)
export default class RecruitmentList extends React.Component {
  constructor(props) {
    super(props)
    this.companyUrl = props.match.params.company
    this.state = {
      mobile: window.innerWidth < 768,
      isJobbet: this.companyUrl === "jobbet.se",
      isGroup: props.user.companyView.group,
      hasSubscription: props.user.companyView.subscription === HAS_SUBSCRIPTION,
      due: [],
      sortProp: "dates",
      sortOrder: { title: "", organisation: "", dates: "desc", views: "", appCount: "", adMeter: "", process: "", active: "dates" },
      recruitments: props.recruitments && props.recruitments.length ? RecruitmentList.applyAdMeterScore(props.recruitments) : [],
    }
    this.baseUrl = `${REKRYTERING}/${props.match.params.state}/`
    const mql = window.matchMedia("(max-width: 767px)")
    mql.addEventListener("change", e => {
      this.setState({ mobile: e.currentTarget.matches })
    })
  }

  static filterRecruitmentProcess(recruitment) {
    const process = recruitment.process
      .filter(step => step.type !== ProcessType.OTHER)
      .map(step => {
        const newStep = { ...step }
        if (step.state === Process.INVALID || step.type === ProcessType.SELECTION) return newStep
        switch (step.type) {
          case ProcessType.INTERVIEW1:
            newStep.state = recruitment.activeInterview1 ? Process.ACTIVE : Process.INACTIVE
            break
          case ProcessType.TEST:
            newStep.state = recruitment.activeTest ? Process.ACTIVE : Process.INACTIVE
            break
          case ProcessType.INTERVIEW2:
            newStep.state = recruitment.activeInterview2 ? Process.ACTIVE : Process.INACTIVE
            break
          case ProcessType.REFERENCES:
            newStep.state = recruitment.activeReferences ? Process.ACTIVE : Process.INACTIVE
            break
          case ProcessType.CLOSING:
            newStep.state = recruitment.activeClosing ? Process.ACTIVE : Process.INACTIVE
            break
          default:
        }
        return newStep
      })
    return { ...recruitment, process }
  }

  static applyAdMeterScore(recruitments) {
    const unique = uniqBy("id", recruitments)
    return unique.map(function (rec) {
      return {
        ...RecruitmentList.filterRecruitmentProcess(rec),
        adMeterScore: memoizedCalculateScore({
          publicationChannels: map("publishChannel")(filter(pc => pc.activated)(rec.recruitmentSelectedPublishChannels) || []),
          hasOther: !!rec.publishChannelsOther,
        }),
        adMeterBookedScore: memoizedCalculateScore({
          publicationChannels: map("publishChannel")(filter(pc => !pc.activated)(rec.recruitmentSelectedPublishChannels) || []),
          hasOther: false,
        }),
      }
    })
  }

  static applySearch(recruitments) {
    return map(recruitment => {
      const search = !!recruitment.addons.find(a => a.name === "Search")
      return {
        ...recruitment,
        search,
      }
    })(recruitments)
  }

  static applyPermission(recruitments, user) {
    const userAdmin = user.superAdmin || user.groupAdmin || user.isAdmin
    return map(recruitment => {
      const { recruiter, recruiterExternals, recruiterGuests } = recruitment
      return {
        ...recruitment,
        permission: userAdmin || recruiter?.id === user.id || some({ id: user.id })(recruiterExternals) || some({ id: user.id })(recruiterGuests),
      }
    })(recruitments)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const nextState = {}
    if (nextProps.recruitments && nextProps.recruitments.length) {
      nextState.recruitments = RecruitmentList.applyAdMeterScore(nextProps.recruitments)
      nextState.recruitments = RecruitmentList.applySearch(nextState.recruitments)
      nextState.recruitments = RecruitmentList.applyPermission(nextState.recruitments, nextProps.user)
      const empty = filter(r => !r.process.length)(nextProps.recruitments)
      if (empty && empty.length) {
        each(e => {
          RecruitmentList.createNewProcess({ id: e.id }, nextProps)
        })(empty)
      }
    } else {
      nextState.recruitments = []
    }
    if (nextProps.due && nextProps.due.length !== prevState.due.length) {
      nextState.due = nextProps.due
    }
    if (!prevState.userInfo && !nextProps.getUserInfo.loading && nextProps.getUserInfo.user) {
      nextState.userInfo = nextProps.getUserInfo.user.info || {}
    }
    return nextState.recruitments || nextState.due ? nextState : null
  }

  setSortOrder = sortProp => {
    const sortOrder = { title: "", organisation: "", dates: "desc", views: "asc", appCount: "asc", adMeter: "", adSearch: "", process: "", active: sortProp }
    sortOrder[sortProp] = this.state.sortOrder[sortProp] !== "asc" ? "asc" : "desc"
    this.setState({ sortOrder, sortProp })
  }

  sortRecruitments = () => {
    const recruitments = this.sortRecruitmentsInitial()
    if (this.props.user.superAdmin) return recruitments
    const blocked = recruitments.filter(r => !r.permission)
    const allowed = recruitments.filter(r => r.permission)
    return [...allowed, ...blocked]
  }

  sortRecruitmentsInitial = () => {
    const { recruitments } = this.state
    const { sortProp, sortOrder } = this.state
    switch (sortProp) {
      case "organisation":
        return orderBy(r => `${r.company.name.toLowerCase()}`)([sortOrder.organisation])(recruitments)
      case "dates":
        return orderBy(r => `${r.applyStart}`)([sortOrder.dates])(recruitments)
      case "views":
        return orderBy(r => (r.jobAd?.views ? parseInt(r.jobAd.views, 10) : 0))([sortOrder.views])(recruitments)
      case "appCount":
        return orderBy(r => r.applications.length)([sortOrder.appCount])(recruitments)
      case "adMeter":
        return orderBy(["adMeterScore"])([sortOrder.adMeter])(recruitments)
      case "adSearch":
        return orderBy(r => !r.search)([sortOrder.adSearch])(recruitments)
      case "process":
        return orderBy([a => filter(p => p.state === Process.ACTIVE)(a.process).length])([sortOrder.process])(recruitments)
      case "title":
        return orderBy(r => `${r.title.trim().toLowerCase()}`)([sortOrder.title])(recruitments)
      default:
        return recruitments
    }
  }

  sortTitle = e => {
    this.setSortOrder("title")
  }

  sortOrganisation = e => {
    this.setSortOrder("organisation")
  }

  sortDates = e => {
    this.setSortOrder("dates")
  }

  sortViews = () => {
    this.setSortOrder("views")
  }

  sortAppCount = () => {
    this.setSortOrder("appCount")
  }

  sortAdMeter = () => {
    this.setSortOrder("adMeter")
  }

  sortSearch = () => {
    this.setSortOrder("adSearch")
  }

  sortProcess = () => {
    this.setSortOrder("process")
  }

  date = date => {
    if (!date || typeof date !== "string" || date.length < 10) {
      return " "
    }
    return format(parseISO(date), "yy-MM-dd")
  }

  initials = user => {
    if (!user || !user.firstName || !user.lastName) return "--"
    return `${user.firstName[0].toUpperCase()}${user.lastName[0].toUpperCase()}`
  }

  initialsTooltip = (recruiter, recruiterExternals, addons) => {
    const lines = []
    if (recruiter) {
      lines.push(`Rekryterande chef: ${recruiter.firstName} ${recruiter.lastName}`)
    }
    if (recruiterExternals && recruiterExternals.length > 0) {
      const re = map(e => `${e.firstName} ${e.lastName}`)(recruiterExternals)
      lines.push(`Extern rekryterare: ${re.join(", ")}`)
    }
    if (addons && addons.length > 0) {
      const ra = map(a => a.name)(addons)
      lines.push(`Bokade tjänster: ${ra.join(", ")}`)
    }
    return lines.join("<br/>")
  }

  initialsClassName = (recruiter, recruiterExternals) => {
    const classNames = []
    if (recruiter && recruiter.id === this.props.user.id) {
      classNames.push("me")
    }
    if (recruiterExternals && recruiterExternals.length > 0) {
      classNames.push("external")
    }
    return classNames.join(" ")
  }

  processNames = ["Urval", "1:a intervju", "Test", "2:a intervju", "Referens", "Avslut"]

  processTitle = step => {
    const name = step.text || this.processNames[step.order]
    if (!step.start && !step.stop) return name
    const start = step.start ? format(parseISO(step.start), "yy-MM-dd") : "…"
    const stop = step.stop ? format(parseISO(step.stop), "yy-MM-dd") : "…"
    return `${name} ${start} - ${stop}`
  }

  process = steps => {
    const firstClass = step => (step.order === 0 ? "start" : step.order === 6 ? "end" : "both")
    const secondClass = step => (step.state === Process.INVALID ? " na" : step.state === Process.ACTIVE ? " started" : "")
    const processClass = step => firstClass(step) + secondClass(step)
    return map(step => <ProcessCell key={step.id} className={processClass(step)} data-tooltip-content={this.processTitle(step)} data-tooltip-id="root-tooltip" />)(orderBy(["order"])("asc")(steps))
  }

  status = steps => {
    const step = steps.findLast(s => s.state === Process.ACTIVE)
    return step ? this.processNames[step.order] : "-"
  }

  static createNewProcess = async (recruitmentId, props) => {
    const { newProcess } = props
    const steps = [
      () => newProcess({ variables: { recruitmentId, order: 0, type: ProcessType.SELECTION, state: Process.INACTIVE } }),
      () => newProcess({ variables: { recruitmentId, order: 1, type: ProcessType.INTERVIEW1, state: Process.INACTIVE } }),
      () => newProcess({ variables: { recruitmentId, order: 2, type: ProcessType.TEST, state: Process.INACTIVE } }),
      () => newProcess({ variables: { recruitmentId, order: 3, type: ProcessType.INTERVIEW2, state: Process.INACTIVE } }),
      () => newProcess({ variables: { recruitmentId, order: 4, type: ProcessType.REFERENCES, state: Process.INACTIVE } }),
      () => newProcess({ variables: { recruitmentId, order: 5, type: ProcessType.CLOSING, state: Process.INACTIVE } }),
    ]
    const [errorSteps, resultSteps] = await all(map(step => step())(steps))
    if (errorSteps) {
      console.error("newProcess:error", errorSteps)
      return []
    }
    return map(step => step.data.createProcessStep)(resultSteps)
  }

  copyProcess = async original => {
    const copy = map(step => {
      const { id, ...stepCopy } = step
      return stepCopy
    })(original)
    const { newProcess } = this.props
    const steps = map(step => () => newProcess({ variables: { ...step, state: Process.INACTIVE, start: null, stop: null } }))(copy)
    const [errorSteps, resultSteps] = await all(map(step => step())(steps))
    if (errorSteps) {
      console.error("newProcess:error", errorSteps)
      return []
    }
    return map(step => ({ id: step.data.createProcessStep.id }))(resultSteps)
  }

  copySelection = async original => {
    const { copyQuestion } = this.props
    const questions = original.map(
      question => () =>
        copyQuestion({
          variables: {
            questionInput: {
              answers: { create: question.answers.map(a => ({ value: a.value, position: a.position })) },
              company: question.company ? { connect: { id: question.company } } : undefined,
              order: question.order,
              text: question.text,
              type: question.type,
            },
          },
        })
    )
    const [errorQuestions, resultQuestions] = await all(map(question => question())(questions))
    if (errorQuestions) {
      console.error("copySelection:copyQuestion:error", errorQuestions)
      return []
    }
    return resultQuestions.map(question => ({ id: question.data.createQuestion.id }))
  }

  copyJobAd = async jobAd => {
    if (!jobAd) return null
    const { id, publishStart, publishStop, ...copy } = jobAd
    copy.image = jobAd.image && jobAd.image.id ? { connect: { id: jobAd.image.id } } : undefined
    copy.logotype = jobAd.logotype && jobAd.logotype.id ? { connect: { id: jobAd.logotype.id } } : undefined
    if (!jobAd.textHTML && jobAd.text) {
      copy.textHTML = draftToHtml(JSON.parse(jobAd.text))
    }
    if (!jobAd.requirementsHTML && jobAd.requirements) {
      copy.requirementsHTML = draftToHtml(JSON.parse(jobAd.requirements))
    }
    if (!jobAd.meritsHTML && jobAd.merits) {
      copy.meritsHTML = draftToHtml(JSON.parse(jobAd.merits))
    }
    const [error, result] = await to(this.props.copyJobAd({ variables: copy }))
    if (error) {
      console.error("copyJobAd:error:", error)
      return
    }
    return result.data.createJobAd.id
  }

  copyRecruitment = async e => {
    e.preventDefault()
    e.stopPropagation()
    const recruitment = find(r => r.id === e.currentTarget.dataset.id)(this.state.recruitments)
    const copy = {}
    copy.applications = []
    copy.title = recruitment.title + " (kopia)"
    copy.companyId = recruitment.company.id
    copy.interest = !!recruitment.interest
    copy.jobAdId = null
    if (recruitment.jobAd) {
      copy.jobAdId = { id: await this.copyJobAd(recruitment.jobAd) }
    }
    if (recruitment.profileFile && recruitment.profileFile.id) {
      const { id, name, contentType, size } = recruitment.profileFile
      const [errorCopy, resultCopy] = await to(this.props.copyFile({ variables: { id, name, contentType, size } }))
      if (!errorCopy && resultCopy) {
        copy.profileFileId = { id: resultCopy.data.copyFile.id }
      }
    }
    copy.documentsIds = map(o => o.id)(recruitment.documents)
    copy.recruiterId = recruitment.recruiter ? recruitment.recruiter.id : null
    copy.recruiterExternalIds = map(o => ({ id: o.id }))(recruitment.recruiterExternals || [])
    copy.recruiterGuestIds = map(o => ({ id: o.id }))(recruitment.recruiterGuests || [])
    copy.occupationalAreasIds = map(o => ({ id: o.id }))(recruitment.occupationalAreas || [])
    copy.occupationalGroupsIds = map(o => ({ id: o.id }))(recruitment.occupationalGroups || [])
    copy.regionsIds = map(o => ({ id: o.id }))(recruitment.regions || [])
    copy.processIds = await this.copyProcess(recruitment.process)
    if (!copy.processIds || !copy.processIds.length) {
      message("Något gick fel vid kopiering av rekryteringen: " + recruitment.title)
      return
    }
    copy.selectionIds = await this.copySelection(recruitment.selection)
    if (!copy.selectionIds || copy.selectionIds.length !== recruitment.selection.length) {
      message("Något gick fel vid kopiering av rekryteringen: " + recruitment.title)
      return
    }
    copy.symbolsIds = map(o => ({ id: o.id }))(recruitment.symbols || [])
    copy.addonsIds = map(ra => ({ id: ra.id }))(recruitment.addons || [])
    const [error, result] = await to(this.props.copyRecruitment({ variables: copy }))
    if (error) {
      console.error("copyRecruitment:error:", error)
      return false
    } else {
      this.props.client.writeQuery({
        query: gql`
          query RecruitmentStats {
            recruitmentStats {
              countRecruitmentsIsStale
              activeRecruitmentsIsStale
              draftRecruitmentsIsStale
              closedRecruitmentsIsStale
            }
          }
        `,
        data: {
          recruitmentStats: {
            __typename: "RecruitmentStats",
            id: REC_STATS_ID,
            draftRecruitmentsIsStale: true,
            draftRecruitmentsIsStale: false,
            closedRecruitmentsIsStale: false,
          },
        },
      })
    }
    const url = "/" + (recruitment.company.urlName || this.props.user.companyView.urlName) + REKRYTERING + "/" + RecruitmentState.DRAFT + "/" + result.data.createRecruitment.id
    window.open(url, "_blank")
    return false
  }

  slug = input => {
    return (
      input &&
      input
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/[^a-zA-Z0-9\s]/g, "")
        .replace(/\s+/g, "-")
        .toLowerCase()
    )
  }

  openJobAd = e => {
    e.preventDefault()
    e.stopPropagation()
    const titleSlug = this.slug(e.currentTarget.dataset.title)
    const companySlug = this.slug(e.currentTarget.dataset.company)
    const url = isLive()
      ? `https://www.jobbet.se${JOBB}/${companySlug}/${titleSlug}/${e.currentTarget.dataset.ad}`
      : `http://jobbet.devserver.phosdev.se${JOBB}/${companySlug}/${titleSlug}/${e.currentTarget.dataset.ad}`
    window.open(url, e.currentTarget.dataset.ad, "width=1200,height=800,location=0")
    return false
  }

  checkNew = (id, currentCount) => {
    const { userInfo } = this.state
    if (!userInfo || !userInfo.appCount) return "new"
    const oldCount = userInfo.appCount[id]
    return currentCount > 0 && currentCount !== oldCount ? "new" : ""
  }

  hasWriteAccess = () => {
    return this.props.user && [UserRoles.GROUPVIEWER, UserRoles.VIEWER].includes(this.props.user.role) === false
  }

  isGroupOrJobbet = () => {
    const { companyView } = this.props.user
    return companyView?.group || companyView?.isJobbet
  }

  render() {
    const { due, isJobbet, isGroup, sortOrder, mobile } = this.state
    const openNew = isJobbet || isGroup
    return (
      <Wrapper className="recruitment-list">
        <Row className="header">
          {openNew ? (
            <TitleCell className={(sortOrder.active === "title" || sortOrder.active === "organisation" ? "active" : "") + " no-cursor"}>
              <div>
                <span onClick={this.sortTitle}>Tjänst</span> / <span onClick={this.sortOrganisation}>organisation</span>
              </div>
            </TitleCell>
          ) : (
            <TitleCell className={sortOrder.active === "title" ? "active" : ""} onClick={this.sortTitle}>
              Tjänst
            </TitleCell>
          )}
          <InfoArea>
            <InfoCell className={sortOrder.active === "dates" ? "active" : ""} onClick={this.sortDates}>
              Ansökningstid
            </InfoCell>
            <InfoCell className={sortOrder.active === "views" ? "active" : ""} onClick={this.sortViews}>
              Visningar
            </InfoCell>
            <InfoCell className={sortOrder.active === "appCount" ? "active" : ""} onClick={this.sortAppCount}>
              Ansökningar
            </InfoCell>
            <InfoCell className={sortOrder.active === "adMeter" ? "active" : ""} onClick={this.sortAdMeter}>
              Annonsering
            </InfoCell>
            <InfoCell className={sortOrder.active === "adSearch" ? "active" : ""} onClick={this.sortSearch}>
              Search
            </InfoCell>
          </InfoArea>
          <ProcessArea className={sortOrder.active === "process" ? "active" : ""} onClick={this.sortProcess}>
            <ProcessCell>{this.processNames[0]}</ProcessCell>
            <ProcessCell>{this.processNames[1]}</ProcessCell>
            <ProcessCell>{this.processNames[2]}</ProcessCell>
            <ProcessCell>{this.processNames[3]}</ProcessCell>
            <ProcessCell>{this.processNames[4]}</ProcessCell>
            <ProcessCell>{this.processNames[5]}</ProcessCell>
          </ProcessArea>
        </Row>
        <Content>
          {map(({ id, permission, applyStart, applyStop, title, company, recruiter, jobAd, applications, process, adMeterScore, adMeterBookedScore, search, recruiterExternals, addons }) => (
            <Row key={id} className={"content" + (permission ? "" : " blocked")} data-tooltip-id="root-tooltip" data-tooltip-content={permission ? "" : "Behörighet saknas"}>
              <TitleCell>
                {openNew ? (
                  <TitleLink
                    to={`/${company.urlName}${this.baseUrl}${id}${REK_APPLICATIONS}`}
                    rel="opener"
                    target="_blank"
                    data-tooltip-content="Hantera ansökningar"
                    data-tooltip-id="root-tooltip"
                    data-due={due && some(d => d.id === id)(due)}>
                    {title}
                  </TitleLink>
                ) : (
                  <TitleLink
                    to={`/${company.urlName}${this.baseUrl}${id}${REK_APPLICATIONS}`}
                    data-tooltip-content="Hantera ansökningar"
                    data-tooltip-id="root-tooltip"
                    data-due={due && some(d => d.id === id)(due)}>
                    {title}
                  </TitleLink>
                )}
                <Organisation>
                  {this.isGroupOrJobbet() ? company && company.name : recruiter && (<><span>Rekryterande chef:</span> <span>{recruiter.firstName} {recruiter.lastName}</span></>) }
                </Organisation>
                <Initials
                  className={this.initialsClassName(recruiter, recruiterExternals)}
                  data-tooltip-id="root-tooltip"
                  data-tooltip-html={this.initialsTooltip(recruiter, recruiterExternals, addons)}>
                  {this.initials(recruiter)}
                </Initials>
                {permission && <Menu data-tooltip-id={"menuTip_" + id} />}
                <Tooltip id={"menuTip_" + id} variant="light" border="1px solid var(--color-line)" place="bottom" effect="solid" delayHide={500} className="menu-tip" clickable={true}>
                  {jobAd && (
                    <MenuLink to="" $icon={ViewIconSrc} data-ad={jobAd.id} data-company={company && company.name} data-title={title} onClick={this.openJobAd}>
                      <div className="icon"></div>
                      <span>Se annons</span>
                    </MenuLink>
                  )}
                  {this.hasWriteAccess() && (
                    <MenuLink to={`/${company.urlName}${this.baseUrl}${id}`} rel="opener" target={openNew ? "_blank" : undefined} $icon={EditIconSrc}>
                      <div className="icon"></div>
                      <span>Redigera</span>
                    </MenuLink>
                  )}
                  {this.hasWriteAccess() && (
                    <MenuLink to="" $icon={CopyIconSrc} data-id={id} onClick={this.copyRecruitment}>
                      <div className="icon"></div>
                      <span>Kopiera</span>
                    </MenuLink>
                  )}
                </Tooltip>
                {mobile && (
                  <Narrow>
                    <div>
                      <label>Ansökningstid:</label>
                      <span>
                        {this.date(applyStart)} - {this.date(applyStop)}
                      </span>
                    </div>
                    <div className={this.checkNew(id, applications.length)}>
                      <label>Ansökningar:</label>
                      <span>{applications.length}</span>
                    </div>
                    <div>
                      <label>Status:</label>
                      <span>{this.status(process)}</span>
                    </div>
                  </Narrow>
                )}
              </TitleCell>
              {!mobile && (
                <InfoArea>
                  <InfoCell className="date">
                    {this.date(applyStart)}
                    <span>-</span>
                    {this.date(applyStop)}
                  </InfoCell>
                  <InfoCell className="views">{jobAd && jobAd.views ? jobAd.views : 0}</InfoCell>
                  <InfoCell className={"applications"}>
                    {openNew ? (
                      <Link to={`/${company.urlName}${this.baseUrl}${id}${REK_APPLICATIONS}`} rel="opener" target="_blank" className={this.checkNew(id, applications.length)}>
                        {applications.length}
                      </Link>
                    ) : (
                      <Link to={`/${company.urlName}${this.baseUrl}${id}${REK_APPLICATIONS}`} className={this.checkNew(id, applications.length)}>
                        {applications.length}
                      </Link>
                    )}
                  </InfoCell>
                  <InfoCell>
                    <AdMeter active={adMeterScore} booked={adMeterBookedScore} />
                  </InfoCell>
                  <InfoCell>{search ? "Ja" : "Nej"}</InfoCell>
                </InfoArea>
              )}
              {!mobile && <ProcessArea>{this.process(process, id)}</ProcessArea>}
            </Row>
          ))(this.sortRecruitments())}
          {this.props.loading && <Spinner className="center" />}
        </Content>
      </Wrapper>
    )
  }
}
