import React from 'react'
import styled from 'styled-components'
import { ButtonNavLink } from "_layout/form-elements"
import { NavLink } from "react-router-dom"
import * as url from "_routes/url-names"
import { UserRoles } from "_root/constants"

const Search = styled.div`
  margin-bottom: 10px;

  > a {
    display: inline-block;
    margin-bottom: 10px;
  }
  > div {
    text-align: left;
  }
  .ingress {
    margin-bottom: 15px;
  }
`
const SubTitle = styled.header`
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
  padding-bottom: 7px;
  border-bottom: 2px solid var(--color-line-dark);

  > span {
    display: inline-block;
    color: var(--color-text-dark);
    font-size: 30px;
    line-height: 34px;
    font-weight: 400;
  }

  .ad-meter-wrapper {
    display: inline-block;
    margin-left: auto;
  }
`

const AddonStepsList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-weight: 900;

  li {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 0;
    white-space: nowrap;
    .addonStepStatus {
        margin-left:auto;
    }
    .addonStepActive {
        color:var(--color-brand-green)
    }
  }
`
const PrimaryLink = styled(ButtonNavLink)`
  width: auto;
  margin-top: 20px;
  margin-bottom: 0;
  padding: 9px 22px;
  font-size: 0.9em;
  border-radius: 20px;
  color: var(--color-text-white);
  background: var(--color-brand-green);
`




export class SearchSummary extends React.Component {

    constructor(props) {
        super(props)
        this.searchOrdered = false
        this.props.addons.forEach(addon => {
         switch (addon.type) {
            case "SEARCH":
                this.searchOrdered = true
                break
          }
        })
        this.state = {
          mobile: window.innerWidth < 768,
        }
        const mql = window.matchMedia("(max-width: 767px)")
        mql.addEventListener("change", e => {
          this.setState({ mobile: e.currentTarget.matches })
        })
    }

    hasWriteAccess = () => {
        return this.props.user && [UserRoles.GROUPVIEWER, UserRoles.VIEWER].includes(this.props.user.role) === false
      }

    render() {
        const {
            addons,
            realUrl
        } = this.props

        const { mobile } = this.state

        return (
            <div className='test'>
                <Search>
                    <SubTitle>
                        <span>Search</span>
                    </SubTitle>
                    {!!this.searchOrdered && 
                      <div className='ingress'>Ni har bokat Search. Jobbet.se kartlägger och ta personlig kontakt med era toppkandidater</div>
                    }
                    {!this.searchOrdered && 
                      <div className='ingress negative'>Ni har inte beställt Search för denna rekrytering</div>
                    }
                    {this.hasWriteAccess() && !this.searchOrdered && <PrimaryLink to="">Beställ Search</PrimaryLink>}
                </Search>
            </div>
        )
    }
}