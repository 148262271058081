import React from "react"
import withRouter from "_hoc/withRouter"
import { graphql } from "@apollo/client/react/hoc"
import { compose } from "react-recompose"
import styled from "styled-components"
import { assign, isEmpty, isEqual, filter, pick, map } from "lodash/fp"
import { format } from "date-fns"
import { ALL_USERS_QUERY, CREATE_ADDRESS, COMPANY_QUERY, COMPANY_UPDATE_MUTATION, COMPANY_DELETE_LOGOTYPE_MUTATION } from "./company-profile-ql"
import { UPDATE_POLICY } from "_root/common-ql"
import confirm from "_components/confirm"
import Policy from "_components/policy"
import ObjectPicker from "_components/object-picker"
import ValuePicker from "_components/value-picker"
import MainLayout from "_layout/main-layout"
import { H2, FlexRow, FlexCol, Label, Input, TextArea, Logo, UserPicker, FilePicker, Checkbox } from "_layout/form-elements"
import { PrimaryButton, DeleteButton, SecondaryButton, SaveButton, ResetButton } from "_layout/buttons"
import UploadService from "_services/upload-service"
import inject from "_services/inject"
import to from "_services/await.to"
import { ADMIN_COMPANY_ID, UserRoles, FileType, NO_SUBSCRIPTION, HAS_SUBSCRIPTION } from "_root/constants"

//#region Styles
const Error = styled.div`
  margin-top: -0.5em;
  margin-bottom: 20px;
  color: var(--color-error);
`
const PolicyAccepted = styled.div`
  max-width: min(300px, 100%);
`
const Groupies = styled.div`
  margin-bottom: 1rem;

  ul {
    list-style-type: none;
    margin: 0 0 1rem 0;
    padding: 0;
  }
`
//#endregion Styles

@compose(
  withRouter,
  inject("user"),
  graphql(ALL_USERS_QUERY, { name: "userQuery" }),
  graphql(COMPANY_QUERY, { name: "companyQuery", options: props => ({ fetchPolicy: "network-only", variables: { urlName: props.match.params.company } }) }),
  graphql(CREATE_ADDRESS, { name: "createAddress" }),
  graphql(COMPANY_UPDATE_MUTATION, { name: "updateCompany" }),
  graphql(COMPANY_DELETE_LOGOTYPE_MUTATION, { name: "deleteLogotype" }),
  graphql(UPDATE_POLICY, { name: "updatePolicy" })
)
export default class CompanyProfile extends React.Component {
  constructor(props) {
    super(props)
    this.state = this.initialState
  }

  initialState = {
    isJobbet: this.props.user.companyView && this.props.user.companyView.id === ADMIN_COMPANY_ID,
    companyName: "",
    companyInfo: "",
    billingInfo: "",
    address: { address: "", postalCode: "", postalCity: "" },
    category: "Okategoriserad",
    careerPage: false,
    logotype: null,
    employees: [],
    contacts: [],
    selectedAdmins: [],
    subscription: NO_SUBSCRIPTION,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const nextState = {}

    if (!prevState.companyId && !nextProps.companyQuery.loading && !nextProps.companyQuery.error) {
      const company = nextProps.companyQuery.company
      const address = company.address
      ? {
        id: company.address.id,
        address: company.address.address || prevState.address.address || "",
        postalCode: company.address.postalCode || prevState.address.postalCode || "",
        postalCity: company.address.postalCity || prevState.address.postalCity || "",
      }
      : { address: "", postalCity: "", postalCode: "" }

      assign.convert({ immutable: false })(nextState, {
        isJobbet: company.id === ADMIN_COMPANY_ID,
        companyActive: company.active === true,
        company: company,
        companyId: company.id,
        companyName: company.name || "",
        companyInfo: company.description || "",
        billingInfo: company.billingInfo || "",
        category: company.category || "Okategoriserad",
        careerPage: company.careerPage === true,
        isPolicyUser: company.policyUser && nextProps.user && company.policyUser.id === nextProps.user.id,
        policyUser: company.policyUser && { ...company.policyUser, name: `${company.policyUser.firstName} ${company.policyUser.lastName}` },
        policyAccepted: company.policyAccepted,
        address: address,
        logotype: (company.logotype && company.logotype.url) || "",
        employees:
          map(e => {
            return { ...e, name: `${e.firstName} ${e.lastName}` }
          })(company.employees) || [],
        contacts: company.contactPersons || [],
        selectedAdmins: company.jobbetContacts || [],
        subscription: company.subscription || NO_SUBSCRIPTION,
      })

      nextState.pristineState = {
        address,
        category: company.category,
        careerPage: company.careerPage,
        ...pick(["companyActive", "companyName", "companyInfo", "billingInfo", "logotype", "employees", "contacts", "policyUser", "selectedAdmins", "subscription"])(nextState),
      }
    }

    return !isEmpty(nextState) ? nextState : null
  }

  onSelectedAdmins = selected => {
    this.setState({ selectedAdmins: [...selected] })
  }

  onSelectedContacts = selected => {
    this.setState({ contacts: [...selected] })
  }

  onLogoSelected = (base64, file) => {
    if (base64 && file) {
      this.setState({ logotype: base64, logotypeFile: file })
    }
  }

  onLogoDelete = async () => {
    const { company } = this.state
    const [errorCompany, resultCompany] = await to(this.props.deleteLogotype({ variables: { id: company.id } }))
    if (errorCompany || !resultCompany) {
      console.error("create company error:", errorCompany)
    } else {
      this.setState({ logotype: null })
    }
  }

  selectedPolicyUser = async policyUser => {
    if (policyUser && this.state.policyUser && policyUser.id === this.state.policyUser.id) return
    this.setState({ policyUser })
  }

  selectedCategory = async category => {
    this.setState({ category })
  }

  selectedSubscription = async subscription => {
    this.setState({ subscription })
  }

  toggleActive = e => {
    this.setState({ companyActive: e.target.checked })
  }

  toggleCareerPage = e => {
    this.setState({ careerPage: e.target.checked })
  }

  acceptPolicy = async e => {
    const confirmed = await confirm(`Är du säker på att du vill godkänna villkoren?`, { text: "Du behöver bara godkänna villkoren en gång." })
    if (!confirmed) return
    const { user } = this.props
    const policyAccepted = `${user.firstName} ${user.lastName} accepterade villkoren ${format(new Date(), "yy-MM-dd")}`
    const [errorPolicy] = await to(this.props.updatePolicy({ variables: { id: user.employer.id, policyAccepted: policyAccepted } }))
    if (errorPolicy) {
      console.error("company-profile:acceptPolicy:errorPolicy: ", errorPolicy)
    } else {
      this.setState({ policyAccepted })
    }
  }

  companyChanged = () => {
    const { pristineState } = this.state
    return (
      (this.state.policyUser && this.state.policyUser !== pristineState.policyUser) ||
      (this.state.companyName && this.state.companyName !== pristineState.companyName) ||
      this.state.companyActive !== pristineState.companyActive ||
      this.state.companyInfo !== pristineState.companyInfo ||
      this.state.billingInfo !== pristineState.billingInfo ||
      this.state.category !== pristineState.category ||
      this.state.subscription !== pristineState.subscription ||
      this.state.careerPage !== pristineState.careerPage ||
      this.state.logotype !== pristineState.logotype ||
      this.state.address.address !== pristineState.address.address ||
      this.state.address.postalCode !== pristineState.address.postalCode ||
      this.state.address.postalCity !== pristineState.address.postalCity ||
      !isEqual(this.state.selectedAdmins)(pristineState.selectedAdmins) ||
      !isEqual(this.state.contacts)(pristineState.contacts)
    )
  }

  resetState = () => {
    this.setState({ ...this.state.pristineState })
  }

  saveCompany = async e => {
    const { pristineState } = this.state

    let { company, companyActive, companyId, companyName, companyInfo, billingInfo, category, careerPage, selectedAdmins, contacts, logotypeFile, address, policyUser, subscription } = this.state

    if (!companyName) {
      // TODO: Update
      this.setState({ missingInput: true })
      return
    }

    if (company && policyUser && company.policyUser && company.policyUser.id !== policyUser.id) {
      const confirmed = await confirm(`Vill du byta ansvarig till ${policyUser.firstName} ${policyUser.lastName}?`)
      if (!confirmed) return
    }

    let logotypeId = null
    if (logotypeFile) {
      const [error, { data }] = await to(UploadService.uploadFile(logotypeFile))
      if (error) {
        console.error("new-company:saveCompany:uploadLogotype:", error)
      } else if (data) {
        logotypeId = data.id
      }
    }

    if (!address.id) {
      const [errorAddress, resultAddress] = await to(
        this.props.createAddress({
          variables: { address: address.address, postalCity: address.postalCity, postalCode: address.postalCode },
        })
      )
      if (errorAddress || !resultAddress) {
        console.error("create company address error:", errorAddress)
        return
      }
      const {
        data: {
          createAddress: { id: addressId },
        },
      } = resultAddress
      address.id = addressId
    }

    const companyVariables = {
      id: companyId,
      addressId: address.id,
      address: undefined,
      postalCity: undefined,
      postalCode: undefined,
      active: companyActive,
      careerPage: careerPage,
    }

    if (logotypeId) companyVariables.logotype = { connect: { id: logotypeId } }
    if (companyName) companyVariables.name = companyName
    companyVariables.description = companyInfo || ""
    companyVariables.billingInfo = billingInfo || ""
    companyVariables.category = category || "Okategoriserad"
    companyVariables.subscription = subscription || NO_SUBSCRIPTION
    if (!isEqual(selectedAdmins)(pristineState.selectedAdmins)) {
      companyVariables.jobbetContactsIds = map(admin => ({ id: admin.id }))(selectedAdmins)
    }
    if (!isEqual(contacts)(pristineState.contacts)) {
      companyVariables.contactPersonsIds = map(contact => ({ id: contact.id }))(contacts)
    }
    if (!isEqual(address)(pristineState.address)) {
      companyVariables.address = { set: address.address }
      companyVariables.postalCode = { set: address.postalCode }
      companyVariables.postalCity = { set: address.postalCity }
    }
    if (!isEqual(policyUser)(pristineState.policyUser)) {
      companyVariables.policyUserId = { id: policyUser.id }
    }
    const [errorCompany, resultCompany] = await to(this.props.updateCompany({ variables: companyVariables }))
    if (errorCompany || !resultCompany) {
      console.error("create company error:", errorCompany)
      return
    }
    this.setState({ companyUpdated: true, companyLoaded: false, companyId: null }, this.props.companyQuery.refetch)
  }

  render() {
    if (this.state.companyUpdated) {
      return (
        <MainLayout padding>
          <H2>Företagsuppgifter uppdaterade</H2>
          <FlexRow className="margin20">
            <FlexCol className="mr6">
              <PrimaryButton onClick={e => this.setState({ companyUpdated: false })}>Tillbaka</PrimaryButton>
            </FlexCol>
          </FlexRow>
        </MainLayout>
      )
    }

    const { loading, error, users } = this.props.userQuery

    const {
      user,
      companyQuery: { company },
    } = this.props

    if (loading || error || !company) 
      return (
        <MainLayout padding>
          <H2>Laddar...</H2>
        </MainLayout>
      )

    const {
      isJobbet,
      companyActive,
      companyName,
      companyInfo,
      category,
      careerPage,
      billingInfo,
      missingInput,
      address,
      employees,
      contacts,
      selectedAdmins,
      subscription,
      policyUser,
      policyAccepted,
      isPolicyUser,
    } = this.state

    const canSave = this.companyChanged()

    return (
      <MainLayout padding>
        <H2>Företagsuppgifter</H2>
        <FlexRow className="margin20">
          <FlexCol $left="4rem">
            <Label>Namn på företag *</Label>
            <Input type="text" placeholder="Företagsnamn *" value={companyName} onChange={e => this.setState({ companyName: e.target.value })} />
            <Label>Beskrivning av företag</Label>
            <TextArea placeholder="Företagsbeskrivning" rows="6" value={companyInfo} onChange={e => this.setState({ companyInfo: e.target.value })} />
            <Label>Gatuadress eller postbox</Label>
            <Input type="text" placeholder="Adress" value={address.address} onChange={e => this.setState({ address: { ...this.state.address, address: e.target.value } })} />
            <Label>Postnummer</Label>
            <Input type="text" placeholder="Postnummer" value={address.postalCode} onChange={e => this.setState({ address: { ...this.state.address, postalCode: e.target.value } })} />
            <Label>Postort</Label>
            <Input type="text" placeholder="Postort" value={address.postalCity} onChange={e => this.setState({ address: { ...this.state.address, postalCity: e.target.value } })} />
            <FilePicker label={"Logotyp för företag"} fileId={"company-logo"} text={"Välj fil..."} accept={[FileType.JPEG, FileType.PNG, FileType.SVG]} onBase64={this.onLogoSelected} />
            {this.state.logotype && (
              <React.Fragment>
                <Logo className="mt2" src={this.state.logotype} alt="Företagets logotyp" />
                <DeleteButton className="mt2" onClick={this.onLogoDelete}>
                  Ta bort logotyp
                </DeleteButton>
              </React.Fragment>
            )}
          </FlexCol>
          <FlexCol $right="4rem">
            {user.superAdmin && !isJobbet && (
              <div>
                <Label>Faktureringsinformation</Label>
                <TextArea placeholder="Fakturering" rows="3" value={billingInfo} onChange={e => this.setState({ billingInfo: e.target.value })} />
              </div>
            )}
            <Label>Kontaktpersoner på företaget</Label>
            {!employees?.length && <Error>Det saknas användare för företaget.</Error>}
            {!!employees?.length &&
              <UserPicker
                onSelected={this.onSelectedContacts}
                placeholder="Välj personer..."
                noSelected="Inga personer valda"
                noUsers="Inga valbara personer"
                users={employees}
                selected={contacts}
                selectedText="person(er) vald(a)"
              />
            }
            {user.superAdmin && !isJobbet && (
              <div>
                <Label>Kontaktpersoner på Jobbet.se</Label>
                <UserPicker
                  onSelected={this.onSelectedAdmins}
                  placeholder="Välj personer..."
                  noSelected="Inga personer valda"
                  noUsers="Inga valbara personer"
                  users={users}
                  selected={selectedAdmins}
                  selectedText="person(er) vald(a)"
                />
              </div>
            )}
            {user.superAdmin && !isJobbet && (
              <div className="mt1 mb1">
                <Label>Huvudadministratör</Label>
                <ObjectPicker
                  form={"true"}
                  required
                  className="mb3"
                  values={filter(e => e.role === UserRoles.ADMINISTRATOR)(employees)}
                  placeholder="Välj person..."
                  current={policyUser}
                  onSelected={this.selectedPolicyUser}
                  noMatch="Inga träffar"
                />
                <Label>Kundkategori</Label>
                <ValuePicker
                  form={"true"}
                  className="mb3"
                  values={["Okategoriserad", "Idealkund", "JA-kund", "K-kund"]}
                  placeholder="Välj kategori..."
                  current={category}
                  onSelected={this.selectedCategory}
                  noMatch="Inga träffar"
                  reset={this.resetState}
                />
                <Label>Avtal</Label>
                <ValuePicker
                  form={"true"}
                  className="mb3"
                  values={[NO_SUBSCRIPTION, HAS_SUBSCRIPTION]}
                  placeholder="Välj avtalstyp..."
                  current={subscription}
                  onSelected={this.selectedSubscription}
                  noMatch="Inga träffar"
                  reset={this.resetState}
                />
                <Checkbox className="mr3 mb3" checked={companyActive} onChange={this.toggleActive} label="Aktivt företag" />
                <br />
                {!!company.groups?.length && (
                  <Groupies>
                    <Label>Grupptillhörighet</Label>
                    <ul>
                      {company.groups.map(g => (
                        <li key={g.id}>
                          <a href={"/" + g.urlName} target="_blank" rel="opener">
                            {g.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </Groupies>
                )}
              </div>
            )}
            {user.superAdmin && (
              <div className="mt1 mb1">
                <Checkbox checked={careerPage} onChange={this.toggleCareerPage} label="Aktivera pub. kanal för karriärsida" />
              </div>
            )}
            <ResetButton disabled={!canSave} onClick={this.resetState}>
              Återställ
            </ResetButton>
            <SaveButton disabled={!canSave} onClick={this.saveCompany}>
              Spara
            </SaveButton>
            {missingInput && ( // This should never happen
              <Error>Vänligen fyll i alla obligatoriska fält.</Error>
            )}
            <Policy className="mv3" inline>
              Villkor
            </Policy>
            {!policyAccepted && <Error>Villkor ännu inte accepterade.</Error>}
            {policyAccepted && <PolicyAccepted>{policyAccepted}</PolicyAccepted>}
            {isPolicyUser && (
              <SecondaryButton className="mt3" data-tooltip-id="root-tooltip" data-tooltip-content="Villkoren kan godkännas obegränsat antal gånger." onClick={this.acceptPolicy}>
                Godkänn villkor
              </SecondaryButton>
            )}
          </FlexCol>
        </FlexRow>
      </MainLayout>
    )
  }
}
