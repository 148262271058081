import React from 'react'
import MainLayout from '_layout/main-layout'
import { TemplateType } from '_root/constants'
import { H2, FlexRow, FlexCol } from '_layout/form-elements'
import TemplateList from '_root/components/templates/template-list';

const RecruitmentMailings = () => {
  return (
    <MainLayout padding maxHeight scroll>
      <div>
        <H2>Skapa och hantera mallar för e-postutskick</H2>
        <FlexRow className='margin20'>
          <FlexCol $full $stretch>
            <h3>Exempel på mallar som är bra att skapa:</h3>
            <p className='mt0 mb4'>
              - Välkommen på intervju<br />
              - Information om rekryteringsprocessen<br />
              - Rekryteringen har dragit ut på tiden<br />
              - Mer information om ert företag<br />
              - Kompletterande information till ansökan<br />
              - Arbetsprover<br />
              - Information om referenser<br />
              - Nej tack - info om att kandidaten inte fått tjänsten
            </p>
            <h3>Utskick till ansökande</h3>
            <TemplateList type={TemplateType.APPLICANT_EMAIL} title='e-postutskick' />
          </FlexCol>
        </FlexRow>
      </div>
    </MainLayout>
  )
}
export default RecruitmentMailings