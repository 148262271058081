import React from 'react'
import MainLayout from '_layout/main-layout'
import { H2, FlexRow, FlexCol } from '_layout/form-elements'

const CVDatabase = () => {
  return (
    <MainLayout padding>
      <H2>Hantera sida för CV-databas</H2>
      <FlexRow className='margin20'>
        <FlexCol>
          Här ska du som kund kunna skräddarsy utseende m.m. för spontanansökan.
        </FlexCol>
      </FlexRow>
    </MainLayout>
  )
}
export default CVDatabase
