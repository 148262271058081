import React from "react"
import { useOutletContext } from "react-router-dom"
import { gql } from "@apollo/client"
import styled from "styled-components"
import map from "lodash/fp/map"
import { AllFileTypes } from "_root/constants"
import UploadService from "_services/upload-service"
import { FlexRow, FlexCol, Checkbox, FilePicker } from "_layout/form-elements"
import { AnswerType, UserRoles} from "_root/constants"
import to from "_services/await.to"
import all from "_services/await.all"

//#region Styles
const Content = styled.div`
  background: var(--color-bg-white);
`
const Name = styled.h3`
  font-weight: 700;
  margin-bottom: 0.5em;
`
const Section = styled.div`
  padding: 40px 0;
  margin: 0 40px;
  background: var(--color-bg-white);
  border-bottom: 1px solid var(--color-line);

  &:last-child {
    border-bottom: none;
  }

  @media screen and (max-width: 767px) {
    padding: 20px 0;
    margin: 0 20px;

    &:first-child {
      padding-top: 0;
    }
  }
`
const Image = styled.img`
  margin-top: -19px;
  width: 200px;
`
const Info = styled.div`
  margin-bottom: 0.5em;
`

const DataList = styled.dl`
  & dt {
    margin: 0 0 10px;
    font-size: 1.1rem;
    font-weight: 600;
  }

  & dd {
    margin: 0 0 16px;
    font-size: 0.9rem;
    font-weight: 200;

    ul {
      margin: 0;
    }
  }
`
//#endregion

export default function ApplicationCurrent(props) {
  const { user, client } = props
  const [recruitment, application, company] = useOutletContext()
  const mobile = window.innerWidth < 768
  const applicant = application?.candidate?.user

  if (!applicant) {
    return (
      <Content>
        <Section>
          <h2>Utgången ansökan</h2>
        </Section>
      </Content>
    )
  }

  const { selection } = application.recruitment
  const { answers } = application

  const canWrite = [UserRoles.SITEADMIN, UserRoles.SUPERADMIN, UserRoles.GROUPADMIN, UserRoles.GROUPRECRUITER, UserRoles.ADMINISTRATOR, UserRoles.RECRUITER].includes(user.role)

  let links = application.links.trim().split(/\s+/)
  links =
    links.length && links[0]
      ? links.map(link => (
          <div key={link}>
            <a href={link.match(/^[a-zA-Z]+:\/\//) ? link : `http://${link}`} target="_blank">
              {link}
            </a>
          </div>
        ))
      : []

  const onFilesSelected = async files => {
    if (files && files.length) {
      const [errorUpload, resultUpload] = await all(map(file => UploadService.uploadFile(file))(files))
      if (errorUpload || !resultUpload) {
        console.error("onFilesSelected:uploadFile:error ", errorUpload)
        const reload = await confirm("Ett oväntat fel inträffade.", { text: "Klicka på OK för att ladda om sidan." })
        if (reload) window.location.reload()
      } else {
        const fileIds = map(file => `{ id: "${file.data.id}" }`)(resultUpload).join(',')
        const mutation = gql`mutation UpdateApplication {
          updateApplication(data: {
              docsAddition: { connect: [${fileIds}] },
            }, where: { id: "${application.id}" }) {
                id
            }
        }`
        const [error, result] = await to(client.mutate({ mutation }))
        if (error || !result) {
          console.error("application-current:onFilesSelected:UpdateApplication:error", error)
          const reload = await confirm("Ett oväntat fel inträffade.", { text: "Klicka på OK för att ladda om sidan." })
          if (reload) window.location.reload()
        } else {
          // Reload the page to show the new documents (should be done with a cache update instead)
          window.location.reload()
        }
      }
    }
  }

  return (
    <Content>
      <Section>
        <h2>Personuppgifter</h2>
        <FlexRow $gap="40px" $column={mobile}>
          <FlexCol>
            <Name>{`${applicant.firstName} ${applicant.lastName}`}</Name>
            <Info>
              <a href={"mailto:" + applicant.email}>{applicant.email}</a>
            </Info>
            <Info>{applicant.mobile}</Info>
            {applicant.address && applicant.address.postalCity && <Info>{applicant.address.postalCity}</Info>}
          </FlexCol>
          <FlexCol>{applicant.picture && <Image src={applicant.picture.url} alt={`Profilbild - ${applicant.firstName} ${applicant.lastName}`} />}</FlexCol>
        </FlexRow>
      </Section>
      <Section>
        <h2>Kort motivering</h2>
        <p>{application.information}</p>
      </Section>
      <Section>
        <h2>Urvalsfrågor</h2>
        <DataList>
          {map(question => [
            <dt key={`${question.id}_dt`}>{question.text}</dt>,
            <dd key={`${question.id}_dd`}>
              {answers &&
                answers[question.id] &&
                question.type !== AnswerType.TEXT &&
                question.answers.length > 0 &&
                map(alt => (
                  <div key={alt.value}>
                    <Checkbox disabled={true} checked={answers[question.id].split("|#").includes(alt.value)}></Checkbox>
                    {alt.value}
                  </div>
                ))(question.answers)}
              {answers && answers[question.id] && question.answers.length === 0 && answers[question.id].split("|#").map(answer => <div key={answer}>{answer}</div>)}
              {(!answers || !answers[question.id]) && "-"}
            </dd>,
          ])(selection)}
        </DataList>
      </Section>
      <Section>
        <h2>Länkar</h2>
        {links}
        {!links.length && <div>Inga länkar har angivits.</div>}
      </Section>
      <Section>
        <h2>Dokument</h2>
        <ul>
          {application.cv && (
            <li>
              <a href={application.cv.url} target="_blank">
                {application.cv.name}
              </a>
            </li>
          )}
          {application.docs.length > 0 &&
            map(doc => (
              <li key={doc.id}>
                <a href={doc.url} target="_blank">
                  {doc.name}
                </a>
              </li>
            ))(application.docs)}
        </ul>
      </Section>
      {!!application.docsAddition?.length &&
      <Section>
        <h2>Kompletterande dokument</h2>
        <ul>
          {map(doc => (
            <li key={doc.id}>
              <a href={doc.url} target="_blank">
                {doc.name}
              </a>
            </li>
          ))(application.docsAddition)}
        </ul>
      </Section>
      }
      {canWrite && (
        <Section>
          <FilePicker className="mr0 mb0" fileId={"addition-document"} text={"Komplettera dokument…"} accept={AllFileTypes} onMultiple={onFilesSelected} />
        </Section>
      )}
    </Content>
  )
}
