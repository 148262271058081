import React, { PureComponent } from "react"
import styled from "styled-components"
import { graphql } from "@apollo/client/react/hoc"
import { compose } from "react-recompose"
import { map } from "lodash/fp"

import ProfileImage from "_images/profile.svg"
import MailIconSrc from "_images/email.svg"
import { PrimaryButton, SecondaryInvertButton, CancelButton } from "_layout/buttons"
import { TextArea } from "_layout/form-elements"
import Modal from "_components/modal"
import message from "_components/message"

import { COMPANY_CONTACTS } from "_settings/company-profile-ql"
import withSendmailMutation from "_hoc/withSendmailMutation"
import { getBookedServiceMailContent } from "_modules/recruitments/services/mailtemplate-service"

import to from "_services/await.to"
import inject from "_services/inject"

//#region Styles
const TemplateInformationWrapper = styled.div`
  padding: 15px 20px;
  margin-bottom: 20px;
  background: var(--color-brand-green-light);
  box-shadow: 0 12px 20px var(--color-bg-grey-dark);
  z-index: 100;

  h2 {
    margin: 0 0 10px 80px;
  }

  p {
    display: inline-block;
    width: 45%;
    margin: 0 0 0 80px;
    padding-right: 20px;
    font-size: 16px;
    vertical-align: top;

    strong {
      display: block;
      font-size: 26px;
      line-height: 32px;
      margin: 10px 0 20px 0;
      color: var(--color-brand-green-dark);
    }
  }
`
const Contacts = styled.div`
  display: inline-flex;
  width: 35%;
  height: 100%;
  align-items: flex-start;
  justify-content: center;
  white-space: nowrap;
`
const Contact = styled.div`
  display: inline-flex;
  margin: 0 30px;
  text-align: center;
  align-items: center;
  flex-flow: column nowrap;
`
const ContactImage = styled.div`
  display: inline-block;
  width: 95px;
  height: 95px;
  margin-bottom: 5px;
  border: 1px solid var(--color-text);
  background: url(${ProfileImage}) no-repeat scroll 50% 50%;
  background-size: 34px 34px;

  img {
    max-width: 100%;
  }
`
const ContactLink = styled.a`
  display: inline-block;
  font-size: 14px;
  color: var(--color-text);
  white-space: nowrap;
`
const MailIcon = styled.i`
  display: inline-block;
  margin-right: 4px;
  width: 18px;
  height: 10px;
  background: url(${MailIconSrc}) no-repeat scroll 50% 50%;
`
const ModalHeader = styled.header`
  font-size: 1.5rem;
  color: var(--color-brand-green);
  margin-bottom: 1rem;
  font-weight: bold;
`
const Nowrap = styled.div`
  text-align: center;
  white-space: nowrap;
`
const CurrentTemplates = styled.ul`
  padding: 20px 0;
`
//#endregion Styles

@compose(
  inject("user"), withSendmailMutation, 
  graphql(COMPANY_CONTACTS, { name: "companyContacts", options: props => ({ variables: { urlName: props.recruitment.company.urlName } }) }))
export default class TemplateInformation extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      orderMessage: "",
      showConfirmOrderModal: false,
      showConfirmOrderTemplatesModal: false,
    }
  }

  onOrderMessageChange = e => {
    this.setState({ orderMessage: e.currentTarget.value })
  }

  dismissOrderModal = () => {
    this.setState({ showConfirmOrderModal: false, showConfirmOrderTemplatesModal: false, orderType: "", orderMessage: "" })
  }

  orderTemplates = () => {
    const orderType = "anpassade mallar"
    this.setState({ showConfirmOrderTemplatesModal: true, orderType })
  }

  handleOrder = async () => {
    const { orderType, orderMessage } = this.state
    const { sendMail, user, recruitment } = this.props
    const [error] = await to(
      sendMail({
        from: "noreply@jobbet.se",
        to: process.env.REACT_APP_AD_SUPPORT,
        subject: `Beställning - ${orderType}`,
        html: getBookedServiceMailContent({ sender: user.fullName, company: recruitment.company.name, job: recruitment.title, order: orderType, orderMessage }),
        showFooter: false,
      })
    )
    if (!error) {
      await message("Din beställning har skickats!")
      this.setState({
        showConfirmOrderModal: false,
        showConfirmOrderTemplatesModal: false,
        orderType: "",
        orderMessage: "",
      })
    } else {
      // Kontakta kundtjänst.
    }
  }

  render() {
    let {
      companyContacts,
      recruitment: { recruiterExternals: contacts },
    } = this.props
    if (!contacts || !contacts.length) {
      contacts = companyContacts.loading ? [] : companyContacts && companyContacts.company && companyContacts.company.jobbetContacts
    }
    return (
      <TemplateInformationWrapper>
        <h2>Vill du rekrytera strukturerat och professionellt?</h2>
        <p>
          <strong>Beställ anpassad intervju- och referensmall av oss!</strong>
          <SecondaryInvertButton onClick={this.orderTemplates}>Beställ anpassade mallar</SecondaryInvertButton>
        </p>
        {!!contacts.length && (
          <Contacts>
            {map(contact => (
              <Contact key={contact.id}>
                <ContactImage data-tooltip-id="root-tooltip" data-tooltip-content={contact.firstName + " " + contact.lastName}>
                  {contact && contact.picture && contact.picture.url && <img src={contact.picture.url} alt={contact.firstName + " " + contact.lastName} />}
                </ContactImage>
                <span>
                  {contact && contact.email && (
                    <ContactLink href={"mailto:" + contact.email} data-tooltip-id="root-tooltip" data-tooltip-content={contact.email}>
                      <MailIcon />
                      {contact.firstName + " " + contact.lastName}
                    </ContactLink>
                  )}
                  <br />
                  {contact && (contact.phone || contact.mobile) && <ContactLink href={"tel:" + contact.mobile}>{contact.mobile}</ContactLink>}
                </span>
              </Contact>
            ))(contacts.slice(0, 2))}
          </Contacts>
        )}
        <Modal center isOpen={this.state.showConfirmOrderTemplatesModal} onRequestClose={this.dismissOrderModal}>
          <ModalHeader>
            Vill du rekrytera strukturerat och professionellt?
            <br />
            Beställ anpassad intervju- och referensmall av oss!
          </ModalHeader>
          <p className="mb4">
            Intervjumallen skräddarsys efter kravprofilen i er aktuella rekrytering.
            <br />
            Skicka din förfrågan här och glöm inte att uppge dina kontaktuppgifter.
            <br />
            En rekryterare återkommer till dig inom kort. Pris från 2500 kr per rekrytering.
          </p>
          <TextArea width="500px" rows={8} placeholder="Skriv din förfrågan till oss här..." onChange={this.onOrderMessageChange} value={this.state.orderMessage} />
          <Nowrap>
            <CancelButton className="mr3" onClick={this.dismissOrderModal}>
              Avbryt
            </CancelButton>
            <PrimaryButton onClick={this.handleOrder}>Skicka</PrimaryButton>
          </Nowrap>
        </Modal>
      </TemplateInformationWrapper>
    )
  }
}
