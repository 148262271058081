import { gql } from '@apollo/client'

export const COMPANY_QUERY = gql`
query CompanyQuery($urlName: String!, $filter: TemplateWhereInput) {
  company(where: { urlName: $urlName }) {
    id
    name
    urlName
    files(orderBy: { name: asc }) {
      id
      name
      url
    }
    templates(orderBy: { name: asc }, where: $filter) {
      id
      name
      type
      shared
      content
      contentHTML
    }
    jobbetContacts(take: 2) {
      id
      firstName
      lastName
      phone
      mobile
      email
      picture {
        url
      }
    }
    careerPage
  }
}
`
export const GROUPS_QUERY = gql`
query CompanyQuery($urlNames: [String]!, $filter: TemplateWhereInput) {
    companies(where: { urlName: { in: $urlNames } }) {
        id
        name
        urlName
        files(orderBy: { name: asc }) {
            id
            name
            url
        }
        templates(orderBy: { name: asc }, where: $filter) {
            id
            name
            type
            shared
            content
            contentHTML
        }
    }
}
`
export const RECRUITMENT_DOCUMENTS_QUERY = gql`
query RecruitmentQuery($id: String!) {
  recruitment(where: { id: $id }) {
    id
    company {
      id
      files(orderBy: { name: asc }) {
        id
        name
        url
      }
    }
    documents(orderBy: { name: asc }) {
      id
      name
      type
      application {
        id
      }
      recruitment {
        id
      }
      company {
        id
      }
      file {
        id
        name
        url
        fileCompany {
          id
        }
      }
      content
      contentHTML
      readonly
      visible {
        id
      }
    }
  }
}
`
export const CREATE_TEMPLATE = gql`
mutation CreateTemplate(
  $companyId: String!,
  $name: String!,
  $contentHTML: String!,
  $type: TemplateType!
) {
  createTemplate(data: {
    company: { connect: { id: $companyId } },
    name: $name,
    type: $type,
    contentHTML: $contentHTML
  }) {
    id
    name
    type
    content
    contentHTML
  }
}
`
export const UPDATE_TEMPLATE = gql`
mutation UpdateTemplate(
    $id: String!,
    $name: String,
    $shared: Boolean,
    $content: Json,
    $contentHTML: String,
    $type: TemplateType
) {
    updateTemplate(
        where: { id: $id },
        data: {
            name: { set: $name },
            shared: { set: $shared },
            type: { set: $type },
            content: $content,
            contentHTML: { set: $contentHTML }
        }
    ) {
        id
    }
}
`
export const DELETE_TEMPLATE = gql`
mutation DeleteTemplate(
    $id: String!,
) {
    deleteOneTemplate(
        where: { id: $id }
    ) {
        id
    }
}
`
export const UPDATE_COMPANY = gql`
mutation UpdateCompany(
    $id: String!,
    $filesIds: [FileWhereUniqueInput!]!
) {
    updateCompany(
        where: { id: $id },
        data: { files: { set: $filesIds } }
    ) {
        id
    }
}
`
export const DELETE_FILE = gql`
mutation DeleteFile(
    $id: String!
) {
    deleteFile(where: { id: $id }) {
        id
    }
}
`
export const DELETE_FILE_AND_DOC = gql`
mutation DeleteFileAndDoc(
    $fileId: String!,
    $docId: String!
) {
    deleteFile(where: { id: $fileId }) {
        id
    }
    deleteDocument(where: { id: $docId }) {
        id
    }
}
`
export const DELETE_DOCUMENT = gql`
mutation DeleteDocument(
    $id: String!
) {
    deleteDocument(where: { id: $id }) {
        id
    }
}
`
