export const ADMIN_COMPANY = "jobbet.se"
export const ADMIN_COMPANY_ID = "cj8613fj60bz70196d7w9ms1q"
export const STORED_PATH = "stored-path-"
export const STORED_TOKEN = "stored-token"
export const EXPIRED_TOKEN = "expired-token"
export const NO_SUBSCRIPTION = "Saknas"
export const HAS_SUBSCRIPTION = "Avtalskund"
export const DragType = {
  QUESTION: "QUESTION",
}
export const AddonType = {
  ADMIN: "ADMIN",
  CAP: "CAP",
  COMPLETE: "COMPLETE",
  INTERVIEWS: "INTERVIEWS",
  REFERENCES: "REFERENCES",
  TESTS: "TESTS",
  SEARCH: "SEARCH",
  SECONDOPINION: "SECONDOPINION",
  OTHER: "OTHER",
}
export const AnswerType = {
  TEXT: "TEXT",
  BOOL: "BOOL",
  SINGLE: "SINGLE",
  MULTIPLE: "MULTIPLE",
  GRADE: "GRADE",
}
export const RecruitmentState = {
  DRAFT: "DRAFT",
  ACTIVE: "ACTIVE",
  CLOSED: "CLOSED",
}
export const Process = {
  INVALID: "INVALID",
  INACTIVE: "INACTIVE",
  ACTIVE: "ACTIVE",
}
export const ProcessType = {
  SELECTION: "SELECTION",
  INTERVIEW1: "INTERVIEW1",
  TEST: "TEST",
  INTERVIEW2: "INTERVIEW2",
  REFERENCES: "REFERENCES",
  OTHER: "OTHER",
  CLOSING: "CLOSING",
}
export const ProcessSubType = {
  PERSONAL: "PERSONAL",
  PHONE: "PHONE",
}

export const ProcessSubTypeTitles = {
  PERSONAL: "Personlig",
  PHONE: "Telefon",
}
export const ProcessTitlesList = ["Urval", "1:a intervju", "Test", "2:a intervju", "Referens", "Övrigt", "Avslut"]
export const ProcessTitles = {
  SELECTION: ProcessTitlesList[0],
  INTERVIEW1: ProcessTitlesList[1],
  TEST: ProcessTitlesList[2],
  INTERVIEW2: ProcessTitlesList[3],
  REFERENCES: ProcessTitlesList[4],
  OTHER: ProcessTitlesList[5],
  CLOSING: ProcessTitlesList[6],
}
export const TemplateType = {
  APPLICANT_EMAIL: "APPLICANT_EMAIL",
  INTERVIEW: "INTERVIEW",
  OTHER: "OTHER",
  REFERENCES: "REFERENCES",
}
export const AdLayout = {
  COLUMN: "column",
  INLINE: "inline",
  NOIMAGE: "noimage",
}
export const Actions = {
  ADD: "ADD",
  COPY_SINGLE: "COPY_SINGLE",
  ADD_SUPER: "ADD_ADVANCED",
  COPY: "COPY",
  GROUPCOPY: "GROUPCOPY",
  GROUPCOPYS: "GROUPSCOPY",
  BROWSE: "BROWSE",
}
export const UserRoles = {
  SITEADMIN: "SITEADMIN",
  SUPERADMIN: "SUPERADMIN",
  GROUPADMIN: "GROUPADMIN",
  GROUPRECRUITER: "GROUPRECRUITER",
  GROUPVIEWER: "GROUPVIEWER",
  ADMINISTRATOR: "ADMINISTRATOR",
  RECRUITER: "RECRUITER",
  VIEWER: "VIEWER",
  CANDIDATE: "CANDIDATE",
}
export const CaptchaKey = "6LeHXR4eAAAAABSFa_i_h8hh_Bv5EFcJXB7EORca"
// export const CaptchaKey = '6LdI40EUAAAAABXVS_ett3tFPuw9D1LAeOWU-644'
export const STORE_FILTER = "STORED_FILTER" // Updated to ignore old stored filter.
export const STORE_CHANNELS = "STORE_CHANNELS"
export const SMTP_SERVER = "themessenger.rackfish.net" // thepostman.phosworks.net

export const symbols = {
  employed: require("_images/symbols/employed.png").default,
  withdrawn: require("_images/symbols/withdrawn.png").default,
  further: require("_images/symbols/further.png").default,
  redFlag: require("_images/symbols/redFlag.png").default,
  blueFlag: require("_images/symbols/blueFlag.png").default,
  greenFlag: require("_images/symbols/greenFlag.png").default,
  blackFlag: require("_images/symbols/blackFlag.png").default,
  yellowFlag: require("_images/symbols/yellowFlag.png").default,
  happy: require("_images/symbols/happy.png").default,
  exclamation: require("_images/symbols/exclamation.png").default,
  question: require("_images/symbols/question.png").default,
  magnify: require("_images/symbols/magnify.png").default,
  message: require("_images/symbols/message.png").default,
}

export const FileType = {
  TXT: { name: "txt", mime: "text/plain", ext: [".txt"] },
  RTF: { name: "rtf", mime: "application/rtf", ext: [".rtf"] },
  PNG: { name: "png", mime: "image/png", ext: [".png"] },
  JPEG: { name: "jpeg/jpg", mime: "image/jpeg", ext: [".jpeg", ".jpg"] },
  DOC: { name: "doc", mime: "application/msword", ext: [".doc"] },
  DOCX: { name: "docx", mime: "application/vnd.openxmlformats-officedocument.wordprocessingml.document", ext: [".docx"] },
  XLS: { name: "xls", mime: "application/vnd.ms-excel", ext: [".xls"] },
  XLSX: { name: "xlsx", mime: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", ext: [".xlsx"] },
  PPT: { name: "ppt", mime: "application/vnd.ms-powerpoint", ext: [".ppt"] },
  PPTX: { name: "pptx", mime: "application/vnd.openxmlformats-officedocument.presentationml.presentation", ext: [".pptx"] },
  ODT: { name: "odt", mime: "application/vnd.oasis.opendocument.text", ext: [".odt"] },
  ODS: { name: "ods", mime: "application/vnd.oasis.opendocument.spreadsheet", ext: [".ods"] },
  PDF: { name: "pdf", mime: "application/pdf", ext: [".pdf"] },
  SVG: { name: "svg", mime: "image/svg+xml", ext: [".svg"] },
}

export const AllFileTypes = [
  FileType.TXT,
  FileType.RTF,
  FileType.PNG,
  FileType.JPEG,
  FileType.DOC,
  FileType.DOCX,
  FileType.XLS,
  FileType.XLSX,
  FileType.PPT,
  FileType.PPTX,
  FileType.ODT,
  FileType.ODS,
  FileType.PDF,
  FileType.SVG,
]

export const getRoleName = role => {
  switch (role) {
    case UserRoles.SUPERADMIN:
      return "Superadmin"
    case UserRoles.SITEADMIN:
      return "Sajtadmin"
    case UserRoles.GROUPADMIN:
      return "Gruppadministratör"
    case UserRoles.GROUPRECRUITER:
      return "Grupprekryterare"
    case UserRoles.GROUPVIEWER:
      return "Gruppgranskare"
    case UserRoles.ADMINISTRATOR:
      return "Administratör"
    case UserRoles.RECRUITER:
      return "Rekryterare"
    case UserRoles.VIEWER:
      return "Granskare"
    default:
      return null
  }
}

export const REC_STATS_ID = "IdToMergeLocalCacheItems"
