import { gql } from "@apollo/client"

export const JOBBET_USERS_QUERY = gql`
  query UserQuery {
    users(where: { role: { in: [SUPERADMIN, SITEADMIN] }, disabled: { equals: false } }) {
      id
      fullName
      firstName
      lastName
    }
  }
`
export const EMPLOYEE_QUERY = gql`
  query EmployeeQuery($urlName: String!) {
    users(where: { disabled: { equals: false }, employer: { urlName: { equals: $urlName } } }) {
      id
      role
      fullName
      firstName
      lastName
    }
  }
`
export const GROUP_EMPLOYEE_QUERY = gql`
  query GroupEmployeeQuery($groups: [String]!) {
    users(where: { disabled: { equals: false }, employer: { id: { in: $groups } } }) {
      id
      role
      fullName
      firstName
      lastName
    }
  }
`
export const NEW_PROCESS_MUTATION = gql`
  mutation CreateProcess($order: Int, $type: ProcessStepType!, $state: ProcessStepState!, $start: DateTime, $stop: DateTime, $text: String, $recruitmentId: RecruitmentWhereUniqueInput) {
    createProcessStep(data: { order: $order, type: $type, state: $state, start: $start, stop: $stop, text: $text, recruitment: { connect: $recruitmentId } }) {
      id
    }
  }
`
export const NEW_RECRUITMENT_MUTATION = gql`
  mutation CreateRecruiment(
    $title: String!
    $companyId: String!
    $interest: Boolean!
    $recruiterId: String!
    $recruiterGuestIds: [UserWhereUniqueInput!]!
    $recruiterExternalIds: [UserWhereUniqueInput!]!
    $occupationalAreasIds: [OccupationalAreaWhereUniqueInput!]!
    $occupationalGroupsIds: [OccupationalGroupWhereUniqueInput!]!
    $regionsIds: [RegionWhereUniqueInput!]!
    $processIds: [ProcessStepWhereUniqueInput!]!
    $symbolsIds: [SymbolWhereUniqueInput!]!
    $profileFileId: FileWhereUniqueInput
    $addonsIds: [AddonWhereUniqueInput!]
  ) {
    createRecruitment(
      data: {
        state: DRAFT
        title: $title
        interest: $interest
        company: { connect: { id: $companyId } }
        recruiter: { connect: { id: $recruiterId } }
        recruiterGuests: { connect: $recruiterGuestIds }
        recruiterExternals: { connect: $recruiterExternalIds }
        occupationalAreas: { connect: $occupationalAreasIds }
        occupationalGroups: { connect: $occupationalGroupsIds }
        regions: { connect: $regionsIds }
        process: { connect: $processIds }
        symbols: { connect: $symbolsIds }
        profileFile: { connect: $profileFileId }
        addons: { connect: $addonsIds }
      }
    ) {
      id
    }
  }
`
export const COPY_RECRUITMENT_MUTATION = gql`
  mutation CreateRecruiment(
    $title: String!
    $companyId: String!
    $interest: Boolean!
    $jobAdId: JobAdWhereUniqueInput
    $merits: String
    $meritsHTML: String
    $requirements: String
    $requirementsHTML: String
    $profileHTML: String
    $profileFileId: FileWhereUniqueInput
    $documentsIds: [DocumentWhereUniqueInput!]!
    $recruiterId: String!
    $recruiterGuestIds: [UserWhereUniqueInput!]!
    $recruiterExternalIds: [UserWhereUniqueInput!]!
    $occupationalAreasIds: [OccupationalAreaWhereUniqueInput!]!
    $occupationalGroupsIds: [OccupationalGroupWhereUniqueInput!]!
    $regionsIds: [RegionWhereUniqueInput!]!
    $processIds: [ProcessStepWhereUniqueInput!]!
    $symbolsIds: [SymbolWhereUniqueInput!]!
    $selectionIds: [QuestionWhereUniqueInput!]!
    $addonsIds: [AddonWhereUniqueInput!]
  ) {
    createRecruitment(
      data: {
        state: DRAFT
        title: $title
        interest: $interest
        company: { connect: { id: $companyId } }
        recruiter: { connect: { id: $recruiterId } }
        recruiterGuests: { connect: $recruiterGuestIds }
        recruiterExternals: { connect: $recruiterExternalIds }
        occupationalAreas: { connect: $occupationalAreasIds }
        occupationalGroups: { connect: $occupationalGroupsIds }
        regions: { connect: $regionsIds }
        process: { connect: $processIds }
        symbols: { connect: $symbolsIds }
        addons: { connect: $addonsIds }
        jobAd: { connect: $jobAdId }
        merits: $merits
        requirements: $requirements
        meritsHTML: $meritsHTML
        requirementsHTML: $requirementsHTML
        profileHTML: $profileHTML
        profileFile: { connect: $profileFileId }
        documents: { connect: $documentsIds }
        selection: { connect: $selectionIds }
      }
    ) {
      id
    }
  }
`
export const RECRUITMENT_QUERY = gql`
  query RecruitmentQuery($id: String!) {
    recruitment(where: { id: $id }) {
      id
      applyStart
      applyStop
      applyUrl
      state
      title
      notes
      interest
      secret
      company {
        id
        name
        urlName
        subscription
        groups {
          id
        }
        description
        logotype {
          id
          url
        }
        gallery {
          id
          name
          description
          url
        }
      }
      blocked {
        id
        firstName
        lastName
      }
      recruiter {
        id
        firstName
        lastName
        email
        mobile
      }
      recruiterExternals {
        id
        firstName
        lastName
        email
        mobile
        picture {
          url
        }
      }
      recruiterGuests {
        id
        firstName
        lastName
        email
        mobile
      }
      regions {
        id
        name
      }
      occupationalAreas {
        id
        name
      }
      occupationalGroups {
        id
        name
      }
      addons {
        id
        name
        type
      }
      profile
      profileHTML
      requirements
      requirementsHTML
      merits
      meritsHTML
      jobAd {
        id
        ingress
        text
        textHTML
        layout
        image {
          id
          url
          description
        }
        logotype {
          id
          url
        }
        requirementsHTML
        requirements
        meritsHTML
        merits
        recruitment {
          id
          title
        }
        views
        meta
      }
      selection(orderBy: { order: asc }) {
        id
        text
        type
        order
        answers(orderBy: { position: asc }) {
          id
          position
          questionId
          value
        }
        recruitments {
          id
        }
      }
      process(orderBy: { order: { sort: asc } }) {
        id
        order
        type
        subType
        state
        start
        stop
        text
        backing
        backingHTML
      }
      profileFile {
        id
        name
        url
        contentType
      }
      recruitmentSelectedPublishChannels {
        id
        activated
        publishChannel {
          id
          title
        }
      }
      publishChannelsOther
      publishPackageTypes {
        id
        value
        position
      }
      applications {
        id
      }
      symbols {
        id
        name
        text
        order
        editable
        origin
      }
      information {
        id
        employersOffer
        employmentForm
        otherInformation
        recruitmentReason
        remoteWorkFrom
        remoteWorkTo
        salaryFrom
        salaryTo
        workScope
        workingHours
      }
      settings
      updatedAt
      updatedCount
    }
  }
`
export const JOBAD_SELECTION_QUERY = gql`
  query JobAdSelectionQuery($id: String!) {
    jobAd(where: { id: $id }) {
      recruitment {
        id
        title
        selection(orderBy: { order: asc }) {
          id
          text
          type
          order
          answers(orderBy: { position: asc }) {
            id
            position
            questionId
            value
          }
          recruitments {
            id
          }
        }
      }
    }
  }
`
export const RECRUITMENT_APPLY_QUERY = gql`
  query RecruitmentApplyQuery($id: String!) {
    recruitment(where: { id: $id }) {
      id
      applyStart
      applyStop
      state
      title
      interest
      company {
        id
        name
        logotype {
          id
          url
        }
      }
      occupationalGroups {
        id
        name
      }
      selection(orderBy: { order: asc }) {
        id
        text
        type
        order
        answers(orderBy: { position: asc }) {
          id
          position
          questionId
          value
        }
        company {
          id
        }
      }
    }
  }
`
export const UPDATE_RECRUITMENT = gql`
  mutation UpdateRecruitment($id: String!, $recruitment: RecruitmentUpdateInput!) {
    updateRecruitment(where: { id: $id }, data: $recruitment) {
      id
      state
      interest
      title
      notes
      applyStart
      applyStop
      applyUrl
      blocked {
        id
        firstName
        lastName
      }
      company {
        id
      }
      recruiter {
        id
        firstName
        lastName
      }
      recruiterGuests {
        id
        firstName
        lastName
      }
      recruiterExternals {
        id
        firstName
        lastName
      }
      regions {
        id
        name
      }
      occupationalAreas {
        id
        name
      }
      occupationalGroups {
        id
        name
      }
      addons {
        id
        name
        type
      }
      publishPackageTypes {
        id
        value
        position
      }
      publishChannelsOther
      profile
      requirements
      merits
      profileHTML
      requirementsHTML
      meritsHTML
      jobAd {
        id
        ingress
        text
        textHTML
        layout
        image {
          id
          url
          description
        }
        logotype {
          id
          url
        }
        requirements
        requirementsHTML
        merits
        meritsHTML
        recruitment {
          id
          title
        }
        views
        meta
      }
      selection(orderBy: { order: asc }) {
        id
        text
        type
        order
        answers(orderBy: { position: asc }) {
          id
          position
          questionId
          value
        }
        company {
          id
        }
      }
      process(orderBy: { order: { sort: asc } }) {
        id
        order
        type
        subType
        state
        start
        stop
        text
        backing
        backingHTML
      }
      profileFile {
        id
        name
        url
      }
      updatedAt
      updatedCount
    }
  }
`
export const UPDATE_RECRUITMENT_AND_AD = gql`
  mutation UpdateRecruitmentAndAd($id: String!, $jobAdId: String!, $recruitment: RecruitmentUpdateInput!, $jobAd: JobAdUpdateInput!) {
    updateJobAd(where: { id: $jobAdId }, data: $jobAd) {
      id
      ingress
      text
      publishStart
      publishStop
      layout
      image {
        id
        url
        description
      }
      logotype {
        id
        url
      }
      requirements
      requirementsHTML
      merits
      meritsHTML
      meta
    }
    updateRecruitment(where: { id: $id }, data: $recruitment) {
      id
      applyStart
      applyStop
      applyUrl
      state
      title
      interest
      blocked {
        id
        firstName
        lastName
      }
      company {
        id
      }
      recruiter {
        id
        firstName
        lastName
      }
      recruiterGuests {
        id
        firstName
        lastName
      }
      recruiterExternals {
        id
        firstName
        lastName
      }
      occupationalAreas {
        id
        name
      }
      occupationalGroups {
        id
        name
      }
      regions {
        id
        name
      }
      addons {
        id
        name
        type
      }
      profile
      requirements
      merits
      profileHTML
      requirementsHTML
      meritsHTML
      jobAd {
        id
        ingress
        text
        textHTML
        layout
        image {
          id
          url
          description
        }
        logotype {
          id
          url
        }
        requirements
        merits
        requirementsHTML
        meritsHTML
        recruitment {
          id
          title
        }
        views
        meta
      }
      selection(orderBy: { order: asc }) {
        id
        text
        type
        order
        answers(orderBy: { position: asc }) {
          id
          position
          questionId
          value
        }
        company {
          id
        }
      }
      process(orderBy: { order: { sort: asc } }) {
        id
        order
        type
        subType
        state
        start
        stop
        text
        backing
        backingHTML
      }
      profileFile {
        id
        name
        url
      }
      publishPackageTypes {
        id
        value
        position
      }
      publishChannelsOther
      updatedAt
      updatedCount
    }
  }
`
export const UPDATE_PROCESS_MUTATION = gql`
  mutation UpdateProcess($id: String!, $data: ProcessStepUpdateInput!) {
    updateProcessStep(where: { id: $id }, data: $data) {
      id
    }
  }
`
export const UPSERT_RECRUITMENT_INFO_MUTATION = gql`
  mutation UpsertOneRecruitmentInfo($where: RecruitmentInfoWhereUniqueInput!, $create: RecruitmentInfoCreateInput!, $update: RecruitmentInfoUpdateInput!) {
    upsertOneRecruitmentInfo(where: $where, create: $create, update: $update) {
      id
    }
  }
`
export const COMPANY_QUERY = gql`
  query CompanyQuery($urlName: String!) {
    company(where: { urlName: $urlName }) {
      id
      name
      description
      logotype {
        id
        url
      }
      gallery {
        id
        name
        url
        description
        contentType
        size
      }
    }
  }
`
export const GROUPS_QUERY = gql`
  query CompanyQuery($urlNames: [String]!) {
    companies(where: { urlName: { in: $urlNames } }) {
      id
      name
      description
      logotype {
        id
        url
      }
      gallery {
        id
        name
        url
        description
        contentType
        size
      }
    }
  }
`
export const ALL_COMPANIES = gql`
  query CompaniesQuery {
    companies(where: { group: { equals: false } }) {
      id
      name
      urlName
    }
  }
`
export const ALL_ADDONS = gql`
  query AllAddonsQuery {
    addons(orderBy: { sort: { sort: asc } }) {
      id
      name
      type
    }
  }
`
export const GALLERY_UPDATE_MUTATION = gql`
  mutation UpdateCompany($id: String!, $galleryIds: [FileWhereUniqueInput!]) {
    updateCompany(where: { id: $id }, data: { gallery: { set: $galleryIds } }) {
      id
      gallery {
        id
        name
        description
        url
      }
    }
  }
`
export const GALLERY_UPDATE_DESCRIPTION = gql`
  mutation UpdateFile($id: String!, $description: String!) {
    updateFile(where: { id: $id }, data: { description: { set: $description } }) {
      id
      name
      description
      url
    }
  }
`
export const GALLERY_DELETE_IMAGE = gql`
  mutation DeleteFile($id: String!) {
    deleteFile(where: { id: $id }) {
      id
    }
  }
`
export const CREATE_JOB_AD = gql`
  mutation CreateJobAd($recruitmentId: String!, $layout: String!, $logotype: FileCreateNestedOneWithoutJobAdLogoInput, $requirementsHTML: String, $meritsHTML: String) {
    createJobAd(
      data: { recruitment: { connect: { id: $recruitmentId } }, layout: $layout, logotype: $logotype, requirementsHTML: $requirementsHTML, meritsHTML: $meritsHTML, meta: { description: "" } }
    ) {
      id
    }
  }
`
export const COPY_FILE = gql`
  mutation CopyFile($id: String!, $name: String!, $contentType: String!, $size: Int!) {
    copyFile(id: $id, name: $name, contentType: $contentType, size: $size) {
      id
    }
  }
`
export const COPY_JOB_AD = gql`
  mutation CreateJobAd(
    $image: FileCreateNestedOneWithoutJobAdInput
    $ingress: String
    $textHTML: String
    $publishStart: DateTime
    $publishStop: DateTime
    $layout: String
    $logotype: FileCreateNestedOneWithoutJobAdLogoInput
    $requirementsHTML: String
    $meritsHTML: String
  ) {
    createJobAd(
      data: {
        image: $image
        ingress: $ingress
        textHTML: $textHTML
        publishStart: $publishStart
        publishStop: $publishStop
        layout: $layout
        logotype: $logotype
        requirementsHTML: $requirementsHTML
        meritsHTML: $meritsHTML
      }
    ) {
      id
    }
  }
`
export const JOB_AD_QUERY = gql`
  query JobAdQuery($id: String!) {
    jobAd(where: { id: $id }) {
      id
      views
      ingress
      text
      textHTML
      publishStart
      publishStop
      layout
      image {
        id
        url
        description
      }
      logotype {
        id
        url
      }
      requirements
      requirementsHTML
      merits
      meritsHTML
      recruitment {
        id
        title
        applyStart
        applyStop
        applyUrl
        company {
          id
          name
          description
          gallery {
            id
            url
          }
          logotype {
            id
            url
          }
        }
        regions {
          id
          name
        }
        occupationalAreas {
          id
          name
        }
        occupationalGroups {
          id
          name
        }
        state
      }
    }
  }
`
export const UPDATE_JOB_AD_VIEWS = gql`
  mutation UpdateJobAd($jobAdId: String!) {
    updateJobAdViewCount(jobAdId: $jobAdId) {
      id
      views
    }
  }
`
export const SELECTION_QUERY = gql`
  query SelectionQuery($urlName: String!) {
    company(where: { urlName: $urlName }) {
      id
      questions {
        id
        type
        text
        answers(orderBy: { position: asc }) {
          id
          position
          questionId
          value
        }
      }
    }
  }
`
export const COMPANY_SELECTION_QUERY = gql`
  query CompanySelectionQuery($companyId: String!) {
    company(where: { id: $companyId }) {
      id
      questions(orderBy: { text: { sort: asc } }) {
        id
        type
        text
        order
        answers(orderBy: { position: asc }) {
          id
          position
          questionId
          value
        }
        company {
          id
        }
      }
    }
  }
`
export const DELETE_QUESTION = gql`
  mutation DeleteQuestion($id: String!) {
    deleteQuestion(where: { id: $id }) {
      id
    }
  }
`
export const CREATE_COMPANY_QUESTION = gql`
  mutation CreateQuestion($questionInput: QuestionCreateInput!) {
    createQuestion(data: $questionInput) {
      id
      text
      type
      order
      answers(orderBy: { position: asc }) {
        id
        position
        questionId
        value
      }
    }
  }
`
export const CREATE_RECRUITMENT_QUESTION = gql`
  mutation CreateQuestion($questionInput: QuestionCreateInput!) {
    createQuestion(data: $questionInput) {
      id
      text
      type
      order
      answers(orderBy: { position: asc }) {
        id
        position
        questionId
        value
      }
      recruitments {
        id
      }
    }
  }
`
export const COPY_RECRUITMENT_QUESTION = gql`
  mutation CreateQuestion($questionInput: QuestionCreateInput!) {
    createQuestion(data: $questionInput) {
      id
    }
  }
`
export const UPDATE_QUESTION = gql`
  mutation UpdateQuestion($id: String!, $questionInput: QuestionUpdateInput!) {
    updateQuestion(where: { id: $id }, data: $questionInput) {
      id
      company {
        id
      }
      order
      text
      type
      answers(orderBy: { position: asc }) {
        id
        position
        questionId
        value
      }
      recruitments {
        id
      }
    }
  }
`
export const DASHBOARD = gql`
  query Dashboard($where: RecruitmentWhereInput!, $take: Int, $skip: Int, $companyId: String!) {
    dashboardRecruitments(where: $where, take: $take, skip: $skip, orderBy: { applyStart: { sort: desc } }, companyId: $companyId) {
      id
      applyStart
      applyStop
      applyUrl
      state
      title
      company {
        id
        urlName
        name
        jobbetContacts {
          id
          firstName
          lastName
        }
      }
      recruiter {
        id
        firstName
        lastName
      }
      recruiterGuests {
        id
        firstName
        lastName
      }
      recruiterExternals {
        id
        firstName
        lastName
      }
      jobAd {
        id
        ingress
        text
        textHTML
        publishStart
        publishStop
        layout
        image {
          id
          url
          description
        }
        logotype {
          id
          url
        }
        requirements
        requirementsHTML
        merits
        meritsHTML
        recruitment {
          id
          title
        }
        views
        meta
      }
      process(orderBy: { order: { sort: asc } }) {
        id
        order
        type
        subType
        state
        start
        stop
        text
        backing
        backingHTML
      }
      regions {
        id
        name
      }
      occupationalAreas {
        id
        name
      }
      occupationalGroups {
        id
        name
      }
      applications {
        id
      }
      profileFile {
        id
        name
        url
        description
        contentType
        size
      }
      recruitmentSelectedPublishChannels {
        id
        activated
        publishChannel {
          id
          title
          sort
        }
      }
      addons {
        id
        name
        type
      }
      publishChannelsOther
      profile
      requirements
      merits
      profileHTML
      requirementsHTML
      meritsHTML
      selection {
        id
        order
        company {
          id
        }
        recruitments {
          id
        }
        answers(orderBy: { position: asc }) {
          id
          position
          questionId
          value
        }
        text
        type
      }
      symbols {
        id
        name
        text
        order
        editable
        origin
      }
      updatedAt
      updatedCount
      activeInterview1
      activeTest
      activeInterview2
      activeReferences
      activeOther
      activeClosing
    }
  }
`

// recruitmentStats @client {
export const CLIENT_RECRUITMENTS_STATS = gql`
  query RecruitmentStats {
    recruitmentStats {
      countRecruitmentsIsStale
      activeRecruitmentsIsStale
      draftRecruitmentsIsStale
      closedRecruitmentsIsStale
    }
  }
`
export const RECRUITMENTS_COUNT = gql`
  query RecruitmentsCount($companyId: String!) {
    recruitmentsCount(companyId: $companyId) {
      activeCount
      draftCount
      closedCount
    }
  }
`
export const ALL_RECRUITMENTS_QUERY = gql`
  query RecruitmentsQuery($state: RecruitmentState) {
    recruitments(where: { state: { equals: $state } }, orderBy: { updatedAt: { sort: desc } }) {
      id
      applyStart
      applyStop
      applyUrl
      state
      title
      company {
        id
        urlName
        name
        jobbetContacts {
          id
        }
      }
      recruiter {
        id
        firstName
        lastName
      }
      recruiterGuests {
        id
        firstName
        lastName
      }
      recruiterExternals {
        id
        firstName
        lastName
      }
      jobAd {
        id
        ingress
        text
        textHTML
        publishStart
        publishStop
        layout
        image {
          id
          url
          description
        }
        logotype {
          id
          url
        }
        requirements
        requirementsHTML
        merits
        meritsHTML
        recruitment {
          id
          title
        }
        views
        meta
      }
      process(orderBy: { order: { sort: asc } }) {
        id
        order
        type
        subType
        state
        start
        stop
        text
        backing
        backingHTML
      }
      regions {
        id
        name
      }
      occupationalAreas {
        id
        name
      }
      occupationalGroups {
        id
        name
      }
      applications {
        id
      }
      profileFile {
        id
        name
        url
      }
      profile
      requirements
      merits
      profileHTML
      requirementsHTML
      meritsHTML
      symbols {
        id
        name
        text
        order
        editable
        origin
      }
      updatedAt
      updatedCount
    }
  }
`
export const COMPANY_RECRUITMENTS_QUERY = gql`
  query RecruitmentsQuery($state: RecruitmentState, $urlName: String) {
    recruitments(where: { AND: [{ state: { equals: $state } }, { company: { urlName: $urlName } }] }, orderBy: { updatedAt: { sort: desc } }) {
      id
      applyStart
      applyStop
      applyUrl
      state
      title
      company {
        id
        urlName
        name
        jobbetContacts {
          id
        }
      }
      recruiter {
        id
        firstName
        lastName
      }
      recruiterGuests {
        id
        firstName
        lastName
      }
      recruiterExternals {
        id
        firstName
        lastName
      }
      jobAd {
        id
        ingress
        text
        textHTML
        publishStart
        publishStop
        layout
        image {
          id
          url
          description
        }
        logotype {
          id
          url
        }
        requirements
        requirementsHTML
        merits
        meritsHTML
        recruitment {
          id
          title
        }
        views
        meta
      }
      process(orderBy: { order: { sort: asc } }) {
        id
        order
        type
        subType
        state
        start
        stop
        text
        backing
        backingHTML
      }
      regions {
        id
        name
      }
      occupationalAreas {
        id
        name
      }
      occupationalGroups {
        id
        name
      }
      applications {
        id
      }
      profileFile {
        id
        name
        url
      }
      profile
      requirements
      merits
      profileHTML
      requirementsHTML
      meritsHTML
      symbols {
        id
        name
        text
        order
        editable
        origin
      }
      updatedAt
      updatedCount
    }
  }
`
export const COMPANY_CONTACTS = gql`
  query CompanyContacts($companyId: String!) {
    company(where: { id: $companyId }) {
      id
      employees(where: { disabled: { equals: false } }) {
        id
        firstName
        lastName
        email
      }
    }
  }
`
export const APP_SYMBOLS_QUERY = gql`
  query AppSymbols {
    symbols(where: { origin: { equals: true } }) {
      id
    }
  }
`
export const DELETE_RECRUITMENT = gql`
  mutation DeleteRecruitment($id: String!) {
    deleteFullRecruitment(id: $id) {
      id
    }
  }
`
export const SEND_MAIL = gql`
  mutation SendMail($from: String!, $to: String!, $cc: String, $bcc: String, $subject: String, $text: String, $html: String, $group: Boolean, $showFooter: Boolean) {
    sendMail(from: $from, to: $to, cc: $cc, bcc: $bcc, subject: $subject, text: $text, html: $html, group: $group, showFooter: $showFooter) {
      message
    }
  }
`
export const SEND_RECRUITMENT_MAIL = gql`
  mutation SendMail($from: String!, $to: String!, $cc: String, $bcc: String, $subject: String, $text: String, $html: String, $group: Boolean, $showFooter: Boolean, $recruitmentId: String) {
    sendRecruitmentMail(from: $from, to: $to, cc: $cc, bcc: $bcc, subject: $subject, text: $text, html: $html, group: $group, showFooter: $showFooter, recruitmentId: $recruitmentId) {
      message
    }
  }
`
export const AUDITLOGS_QUERY = gql`
  query ($entityName: String!, $entityId: String!, $correlationId: String) {
    logEntries: getAuditLogsByEntityNameId(entityName: $entityName, entityId: $entityId, correlationId: $correlationId) {
      id
      createdAt
      createdByUser {
        firstName
        lastName
        role
      }
      kind
      message
      messageData
      propertyName
      propertyValueFrom
      propertyValueTo
    }
  }
`
