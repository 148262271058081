import React from "react"
import { NavLink } from "react-router-dom"
import { graphql } from "@apollo/client/react/hoc"
import { compose } from "react-recompose"
import styled from "styled-components"
import draftToHtml from "draftjs-to-html"
import withRouter from "_hoc/withRouter"
import * as EmailValidator from "email-validator"
import { map, uniq, isUndefined, isNil, isEqual, isEmpty } from "lodash/fp"
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import sv from "date-fns/locale/sv"
import { isValid, format, parseISO, setHours, differenceInCalendarDays } from "date-fns"
import CalendarIconSrc from "_images/calendar-icon.svg"
import ArrowRightSrc from "_images/arrow_right.svg"
import { PrimaryButton, SecondaryButton, MiniButton } from "_layout/buttons"
import { Spinner, FlexRow, Input, TextArea, UserPicker, Checkbox } from "_layout/form-elements"
import AdTop from "_images/layout-1.svg"
import AdSide from "_images/layout-2.svg"
import AdText from "_images/layout-3.svg"
import AdTopActive from "_images/layout-1-active.svg"
import AdSideActive from "_images/layout-2-active.svg"
import AdTextActive from "_images/layout-3-active.svg"
import JobAdEditor from "./job-ad-editor"
import Modal from "_components/modal"
import TinyEditor from "_components/tiny-editor"
import Selection from "_components/recruit/selection"
import ReviewMessageLog from "_components/recruit/review-messagelog"
import SelectionFetch from "_components/recruit/selection-fetch"
import { symbols as icons, RecruitmentState } from "_root/constants"
import { CREATE_JOB_AD, COMPANY_CONTACTS, SEND_RECRUITMENT_MAIL, AUDITLOGS_QUERY } from "_containers/recruitment/recruitment-ql"
import { REKRYTERING, JOBB, URVAL } from "_routes/url-names"
import { isLive } from "_services/util"
import inject from "_services/inject"
import to from "_services/await.to"
import * as url from "_routes/url-names";
import Debounce from "debounce-decorator"
import message from "_root/components/message"

//#region Styles
const Section = styled.section`
  margin: 0;
  padding: 2em 40px;

  &:nth-child(even) {
    background: var(--color-bg-bright);
  }

  &:nth-child(odd) {
    background: var(--color-bg-light);
  }

  &.job-ad-section {
    padding-left: 100px;
    padding-right: 100px;
  }

  &.last {
    padding-bottom: 4em;
  }

  > div {
    margin-left: auto;
    margin-right: auto;
  }

  p {
    max-width: 100%;
  }

  &:last-of-type {
    height: 100px;

    button {
      margin: 0 auto;
      position: relative;
      top: -53px;
    }
  }
`
const Title = styled.h2`
  margin-top: 20px;
  text-align: center;
  font-weight: 400;
`
const Layout = styled.div`
  cursor: pointer;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  max-width: 60px;
  margin: 0 20px;
  padding-bottom: 20px;
  border-bottom: 5px solid ${props => (props.$active ? "#E5575C" : "transparent")};

  &:hover {
    opacity: 0.5;
  }

  img {
    width: 100%;
    height: 100%;
    transform: rotateY(180deg);
  }
`
const DateBox = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  max-width: 960px;
  padding: 10px 20px 10px 40px;
  margin-bottom: 20px;
  border: 1px solid var(--color-bg-grey);
  background: var(--color-bg-white);
  z-index: 2;

  .react-datepicker-wrapper {
    display: block;

    .react-datepicker__input-container {
      display: block;

      &:before {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 0;
        padding-left: 50px;
        transform: translateY(-50%);
      }

      input {
        width: 100%;
        padding-left: 95px;
        color: var(--color-text-medium);
        background: url(${CalendarIconSrc}) no-repeat scroll 15px 50%;
        background-size: 20px 20px;
      }
    }
  }

  .react-datepicker-popper {
    z-index: 1000;
  }
`
const DatePickerStart = styled.div`
  flex: 1 0 auto;

  .react-datepicker__input-container {
    &:before {
      content: "Från:";
    }
  }
`
const DatePickerStop = styled.div`
  flex: 1 0 auto;

  .react-datepicker__input-container {
    &:before {
      content: "Till:";
    }
  }
`
const ArrowRight = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 15px;
  background: url(${ArrowRightSrc}) no-repeat scroll 50% 50%;
  background-size: 20px 20px;
`
const StyledTextArea = styled(TextArea)`
  width: 500px;
  min-height: 120px;
`
const Summary = styled.div`
  flex: 1;
  padding: 20px 40px 40px 40px;
  max-width: 1100px;
  background: var(--color-bg-white);

  strong {
    margin-right: 1em;
  }
`
const SummaryRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 15px 0 5px 0;
  border-bottom: 2px solid var(--color-bg-light);
`
const SummaryButtons = styled.div`
  padding-top: 20px;
  text-align: center;
`
const MailSymbol = styled.i`
  cursor: pointer;
  display: inline-block;
  margin-left: 12px;
  width: 30px;
  height: 16px;
  vertical-align: middle;
`
const Recipients = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;

  li {
    margin-bottom: 10px;
    input {
      margin-bottom: 0;
    }
    button {
      visibility: hidden;
    }
    &:hover {
      button {
        visibility: visible;
      }
    }
  }
`
//#endregion

@compose(
  withRouter,
  inject("user"),
  graphql(CREATE_JOB_AD, { name: "createJobAd" }),
  graphql(SEND_RECRUITMENT_MAIL, { name: "sendRecruitmentMail" }),
  graphql(AUDITLOGS_QUERY, { name: "auditLogsQuery", options: props => ({ variables: { entityId: props.recruitment.id, entityName: "Recruitment" } }) }),
  graphql(COMPANY_CONTACTS, { name: "companyContacts", options: props => ({ variables: { companyId: props.recruitment.company.id } }) })
)
export class AdvertiseClass extends React.PureComponent {
  constructor(props) {
    super(props)
    registerLocale("sv", sv)
    this.recruitmentId = props.match.params.recruitment
    const jobAd = this.initialJobAd(props)
    const { user, recruitment } = props

    this.state = {
      jobAd,
      applyStart: (recruitment?.applyStart && parseISO(recruitment.applyStart)) || null,
      applyStop: (recruitment?.applyStop && parseISO(recruitment.applyStop)) || null,
      applyUrl: (recruitment && recruitment.applyUrl) || null,
      modalOpen: false,
      showConfirmLeavePageModal: false,
      recruitment: recruitment,
      selectionVersion: 0,
      attachSelection: true,
      messageLogModalOpen: false,
      reviewCompanyContacts: [],
      otherContacts: [""],
      content: `Hej<br/><br/>Klicka på länkarna nedan för att se korrektur på platsannonsen samt de urvalsfrågor som är kopplade till annonsen. Vill du göra korrigeringar, hör av dig till mig per mail eller telefon.<br/><br/>Hälsningar ${user.firstName} ${user.lastName}<br/>`,
    }
    this.layoutSelected = this.state.jobAd && !!this.state.jobAd.layout
  }

  initialJobAd = props => {
    let jobAd = props?.recruitment?.jobAd
    jobAd = jobAd ? { ...jobAd, title: props.recruitment?.title } : { recruitmentId: this.recruitmentId, title: props.recruitment?.title, layout: "" }
    if (!jobAd.title) {
      jobAd.title = props.recruitment?.title
    }
    if (jobAd.text && (!jobAd.textHTML || !jobAd.textHTML.length)) {
      jobAd.textHTML = draftToHtml(JSON.parse(jobAd.text))
    }
    if (!jobAd.requirementsHTML) {
      if (jobAd.requirementsHTML === null && jobAd.requirements === null && props.requirementsHTML) {
        jobAd.requirementsHTML = props.requirementsHTML
      } else if (jobAd.requirements || props.recruitment.requirements || props.requirements) {
        const requirements = jobAd.requirements || props.recruitment.requirements || props.requirements
        jobAd.requirementsHTML = draftToHtml(JSON.parse(requirements))
      }
    }
    if (!jobAd.meritsHTML) {
      if (jobAd.meritsHTML === null && jobAd.merits === null && props.meritsHTML) {
        jobAd.meritsHTML = props.meritsHTML
      } else if (jobAd.merits || props.recruitment.merits || props.merits) {
        const merits = jobAd.merits || props.recruitment.merits || props.merits
        jobAd.meritsHTML = draftToHtml(JSON.parse(merits))
      }
    }
    jobAd.recruitment = props.recruitment ? { ...props.recruitment } : { id: this.recruitmentId, title: jobAd.title }
    if (!jobAd.meta) {
      jobAd.meta = { description: "" }
    }
    return jobAd
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    const nextState = {}
    if (!prevState.jobAd) {
      nextState.jobAd = this.initialJobAd(nextProps)
    }
    if (!prevState.auditLogs || !isEqual(nextProps.auditLogs)(prevState.auditLogs)) {
      const { logEntries } = nextProps.auditLogsQuery
      if (logEntries && logEntries.length) nextState.auditLogs = logEntries
    }
    if (!prevState.recruitment || !isEqual(nextProps.recruitment)(prevState.recruitment)) {
      nextState.jobAd = { ...prevState.jobAd, recruitment: nextProps.recruitment }
      nextState.recruitment = nextProps.recruitment
      if (prevState.futureExternalReload) {
        nextState.futureExternalReload = undefined
        nextState.selectionVersion = prevState.selectionVersion + 1
      }
    }
    return isEmpty(nextState) ? null : nextState
  }

  componentDidMount() {
    document.getElementById("advertise-content")?.scrollIntoView(true)
  }

  createJobAd = async (layout) => {
    const jobAd = { ...this.state.jobAd, layout }
    if (jobAd.id) return
    const { recruitment } = this.props
    if (!jobAd.requirementsHTML) {
      if (this.props.requirementsHTML) {
        jobAd.requirementsHTML = this.props.requirementsHTML
      } else {
        const requirements = jobAd.requirements || recruitment.requirements || this.props.requirements
        jobAd.requirementsHTML = requirements ? draftToHtml(JSON.parse(requirements)) : null
      }
    }
    if (!jobAd.meritsHTML) {
      if (this.props.meritsHTML) {
        jobAd.meritsHTML = this.props.meritsHTML
      } else {
        const merits = jobAd.merits || recruitment.merits || this.props.merits
        jobAd.meritsHTML = merits ? draftToHtml(JSON.parse(merits)) : null
      }
    }
    const variables = { ...jobAd }
    if (!variables.logotype && recruitment && recruitment.company && recruitment.company.logotype) {
      variables.logotype = { connect: { id: recruitment.company.logotype.id } }
    }
    const [error, result] = await to(this.props.createJobAd({ variables }))
    if (error) {
      console.error("createJobAd:error:", error)
      return null
    }
    return { ...jobAd, id: result.data.createJobAd.id }
  }

  persistRecruitment = recruitmentWithjobAd => {
    let recruitment = {}
    if (recruitmentWithjobAd && recruitmentWithjobAd.jobAdId) {
      recruitment = { ...recruitment, ...recruitmentWithjobAd }
    }
    if (this.state.applyStart) {
      recruitment.applyStart = setHours(this.state.applyStart, 12)
    }
    if (this.state.applyStop) {
      recruitment.applyStop = setHours(this.state.applyStop, 12)
    }
    if (this.state.applyUrl !== this.props.recruitment.applyUrl) {
      recruitment.applyUrl = !!this.state.applyUrl || this.state.applyUrl?.length === 0 ? this.state.applyUrl.trim() : null
      if (recruitment.applyUrl && recruitment.applyUrl.indexOf("http") !== 0) {
        recruitment.applyUrl = "https://" + recruitment.applyUrl
        this.setState({ applyUrl: recruitment.applyUrl })
      }
    }
    this.props.onChange(recruitment)
  }

  saveJobAd = (jobAd) => {
    if (!jobAd || !jobAd.id) return
    const recruitment = { jobAdId: jobAd.id }
    if (jobAd.image) recruitment.jobAdImage = jobAd.image.id
    if (jobAd.logotype) recruitment.jobAdLogo = jobAd.logotype.id
    if (jobAd.layout) recruitment.jobAdLayout = jobAd.layout
    if (!isUndefined(jobAd.title)) recruitment.title = jobAd.title
    if (!isUndefined(jobAd.ingress)) recruitment.jobAdIngress = jobAd.ingress
    if (!isUndefined(jobAd.textHTML)) {
      recruitment.jobAdTextHTML = jobAd.textHTML
    }
    if (!isNil(jobAd.requirementsHTML)) {
      recruitment.jobAdRequirementsHTML = jobAd.requirementsHTML
    }
    if (!isNil(jobAd.meritsHTML)) {
      recruitment.jobAdMeritsHTML = jobAd.meritsHTML
    }
    recruitment.jobAdMeta = jobAd.meta
    this.dirty = false
    this.setState({ jobAd }, () => {
      this.persistRecruitment(recruitment)
    })
  }

  setApplyStart = (date) => {
    if (!date) return
    if (differenceInCalendarDays(date, this.state.applyStop) > 0) {
      message("Startdatum måste vara före slutdatum")
    } else {
      this.setState({ applyStart: setHours(date, 12) }, this.persistRecruitment)
    }
  }

  setApplyEnd = (date) => {
    if (!date) return
    if (differenceInCalendarDays(this.state.applyStart, date) > 0) {
      message("Slutdatum måste vara efter startdatum")
    } else {
      this.setState({ applyStop: setHours(date, 12) }, this.persistRecruitment)
    }
  }

  setExternalUrl = (url) => {
    this.setState({ applyUrl: url }, () => this.onDirty())
  }

  onDirty = (state) => {
    this.dirty = true
    this.props.onDirty && this.props.onDirty(state)
  }

  onLayoutSelect = async (layout) => {
    this.layoutSelected = true
    this.dirty = true
    let jobAd = { ...this.state.jobAd, layout: layout }
    if (!jobAd.id) {
      jobAd = await this.createJobAd(layout)
    }
    this.saveJobAd(jobAd)
  }

  selectionFetchChange = (e) => {
    this.props.onChange && this.props.onChange(e)
    this.setState({ futureExternalReload: true })
  }

  onMetaDescription = (e) => {
    const { jobAd } = this.state
    this.setState({ jobAd: { ...jobAd, meta: { description: e.target.value } } }, () => this.persistJobAndRecruitment(this.state.jobAd))
  }

  @Debounce(750)
  persistJobAndRecruitment(jobAd) {
    this.saveJobAd(jobAd)
  }

  confirmLeavePage = () => {
    if (!this.props.recruitment.applyStop) {
      message("Sista ansökningsdag måste anges för att kunna aktivera denna rekrytering!")
    } else {
      this.props.navigate(this.props.urlBase + url.REK_KANALER)
    }
  }

  slug = (input) => {
    return (
      input &&
      input
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/[^a-zA-Z0-9\s]/g, "")
        .replace(/\s+/g, "-")
        .toLowerCase()
    )
  }

  getPreviewSelectionUrl = () => {
    const { jobAd } = this.state
    const selectionUrl = jobAd && `${window.location.origin}${URVAL}/${jobAd.id}`
    return selectionUrl
  }

  onPreviewSelection = (e) => {
    e.preventDefault()
    window.open(this.getPreviewSelectionUrl(), `s-${this.state.jobAd.id}`, "width=1200,height=800,scrollbars=1")
  }

  onReviewHistory = (e) => {
    this.state.auditLogs && this.setState({ messageLogModalOpen: true })
  }

  onReviewHistoryClose = (e) => {
    this.setState({ messageLogModalOpen: false })
  }

  getPreviewAdUrl = () => {
    const { jobAd } = this.state
    const { recruitment } = this.props
    const titleSlug = this.slug(recruitment.title)
    const companySlug = this.slug(recruitment.company.name)
    const adUrl = isLive() ? `https://www.jobbet.se${JOBB}/${companySlug}/${titleSlug}/${jobAd.id}` : `http://jobbet.devserver.phosdev.se${JOBB}/${companySlug}/${titleSlug}/${jobAd.id}`
    return adUrl
  }

  onPreviewAd = (e) => {
    e.preventDefault()
    window.open(this.getPreviewAdUrl(), `p-${this.state.jobAd.id}`, "width=1200,height=800,scrollbars=1")
  }

  onModalClose = () => {
    this.setState({ modalOpen: false })
  }

  onSelectedCompanyContact = (selected) => {
    this.setState({ reviewCompanyContacts: [...selected] })
  }

  onAddRecipient = (e) => {
    const { otherContacts } = this.state
    const invalidEmail = !!otherContacts.find((oc) => !EmailValidator.validate(oc))
    const disableAdd = invalidEmail || otherContacts.includes("")
    if (disableAdd) return
    otherContacts.push("")
    this.setState({ otherContacts: [...otherContacts] })
  }

  onRemoveRecipient = (e) => {
    const { otherContacts } = this.state
    if (otherContacts.length === 1) {
      this.setState({ otherContacts: [""] })
    } else {
      otherContacts.splice(e.currentTarget.dataset.index, 1)
      this.setState({ otherContacts: [...otherContacts] })
    }
  }

  setOtherContact = (e) => {
    const { otherContacts } = this.state
    if (!otherContacts.length) {
      this.setState({ otherContacts: [e.currentTarget.value] })
    } else {
      otherContacts[e.currentTarget.dataset.index] = e.currentTarget.value
      this.setState({ otherContacts: [...otherContacts] })
    }
  }

  onContentChange = (content) => {
    this.setState({ content })
  }

  onAttachSelection = () => {
    this.setState({ attachSelection: !this.state.attachSelection })
  }

  onSendReview = async () => {
    const { reviewCompanyContacts, otherContacts, jobAd, attachSelection } = this.state
    if (!jobAd) {
      message("Platsannons saknas!")
      return
    }
    if (!reviewCompanyContacts.length && otherContacts.length === 1 && !otherContacts[0].trim().length) {
      message("Välj minst en kontakt!")
      return
    }
    const invalidOther = otherContacts.find((oc) => !EmailValidator.validate(oc))
    if (invalidOther) {
      message("Ogiltig e-post angiven.")
      this.setState({ sending: false })
      return
    }
    const { content } = this.state
    const recipients = map((cc) => cc.email)(reviewCompanyContacts).concat(uniq(otherContacts))
    const adUrl = this.getPreviewAdUrl()
    const selectionUrl = `${window.location.origin}${URVAL}/${jobAd.id}`
    const subject = `Korrektur för tjänsten: ${jobAd.recruitment.title}`
    const text = `Se korrektur av annons och urvalsfrågor här:\n\n${adUrl}\n\n${selectionUrl}`
    let html = `<h2>Korrektur</h2>${content}<p><a href="${adUrl}">Länk till annons</a></p>`
    if (attachSelection) html += `<p><a href="${selectionUrl}">Länk till urvalsfrågor</a></p>`
    const variables = { from: this.props.user.email, to: recipients.join(","), subject, text, html, showFooter: true, recruitmentId: this.recruitmentId, group: true }
    const [error, result] = await to(this.props.sendRecruitmentMail({ variables: variables }))
    if (error || !result) {
      message("Något gick fel när korrekturen skulle skickas!")
      return
    }
    this.props.auditLogsQuery.refetch()
    this.onModalClose()
    message("E-post om korrektur har skickats till valda mottagare.")
  }

  render() {
    const { user, recruitment } = this.props
    const { company } = recruitment
    if (!recruitment) {
      return (
        <div id="advertise-content">
          <Spinner />
        </div>
      )
    }
    const { jobAd, applyStart, applyStop, selectionVersion, applyUrl, reviewCompanyContacts, otherContacts, auditLogs, messageLogModalOpen, attachSelection } = this.state
    const layout = jobAd && jobAd.layout
    const baseUrl = `/${this.props.match.params.company}${REKRYTERING}/${this.props.match.params.state}/` + recruitment.id
    const regionsString = map((r) => r.name)(recruitment.regions).join(", ")
    const occupationalAreasString = map((a) => a.name)(recruitment.occupationalAreas).join(", ")
    const { companyContacts: ccQuery } = this.props
    const companyContacts = !ccQuery.loading && ccQuery.company ? ccQuery.company.employees : []
    const previewAdUrl = this.getPreviewAdUrl()
    const previewSelectionUrl = this.getPreviewSelectionUrl()
    const disableAdd = otherContacts.find((oc) => !EmailValidator.validate(oc)) || otherContacts.includes("")

    return <div id="advertise-content">
      <Section id="applyspan" className="job-ad-section tc">
        <Title>Ansökningstid</Title>
        <DateBox>
          <DatePickerStart data-tooltip-content="Första ansökningsdag" data-tooltip-id="root-tooltip" data-tooltip-place="right">
            <DatePicker selected={applyStart} maxDate={applyStop} onChange={this.setApplyStart} locale="sv" dateFormat="yy-MM-dd" showWeekNumbers />
          </DatePickerStart>
          <ArrowRight />
          <DatePickerStop data-tooltip-content="Sista ansökningsdag" data-tooltip-id="root-tooltip" data-tooltip-place="right">
            <DatePicker selected={applyStop} minDate={applyStart} onChange={this.setApplyEnd} locale="sv" dateFormat="yy-MM-dd" showWeekNumbers />
          </DatePickerStop>
        </DateBox>
      </Section>
      <Section id="layout" className="job-ad-section">
        <Title>Välj annonslayout</Title>
        <FlexRow $center>
          <Layout onClick={(e) => this.onLayoutSelect("column")} $active={layout === "column"}>
            <img src={layout === "column" ? AdTopActive : AdTop} alt="Liggande bild överst" data-tooltip-content="Liggande bild överst" data-tooltip-id="root-tooltip" />
          </Layout>
          <Layout onClick={(e) => this.onLayoutSelect("inline")} $active={layout === "inline"}>
            <img src={layout === "inline" ? AdSideActive : AdSide} alt="Infälld bild till höger" data-tooltip-content="Infälld bild till höger" data-tooltip-id="root-tooltip" />
          </Layout>
          <Layout onClick={(e) => this.onLayoutSelect("noimage")} $active={layout === "noimage"}>
            <img src={layout === "noimage" ? AdTextActive : AdText} alt="Endast text" data-tooltip-content="Endast text" data-tooltip-id="root-tooltip" />
          </Layout>
        </FlexRow>
        {layout && <JobAdEditor white company={company} jobAd={jobAd} onSave={this.saveJobAd} onDirty={this.props.onDirty} border />}
        <SummaryButtons>
          <SecondaryButton disabled={!jobAd} onClick={this.onPreviewAd}>
            Granska annons
          </SecondaryButton>
        </SummaryButtons>
      </Section>
      <Section id="urval" className="job-ad-section tc last">
        <Title>Välj urvalsfrågor</Title>
        <p>
          Dra frågor från tillgängliga till valda urvalsfrågor för att bygga ditt urval eller klicka på skapa urvalsfråga.
          <br />
          Du kan även dra tillbaka valda frågor eller klicka på lägg till eller ta bort vid respektive fråga.
        </p>
        {user.superAdmin && user.employer.id !== recruitment.company.id && (
          <SelectionFetch className="mb3" title={recruitment.title} recruitment={recruitment} onChange={this.selectionFetchChange} />
        )}
        <Selection version={selectionVersion} onChange={this.props.onChange} recruitment={recruitment} companyId={company.id} />
      </Section>
      <Section>
        <Title>Sammanställning</Title>
        <Summary>
          <SummaryRow>
            <span>Rekryteringsansvarig</span>
            <NavLink to={baseUrl} data-tooltip-content="Klicka för att redigera" data-tooltip-id="root-tooltip">
              {recruitment.recruiter ? `${recruitment.recruiter.firstName} ${recruitment.recruiter.lastName}` : "-"}
            </NavLink>
          </SummaryRow>
          <SummaryRow>
            <span>Region</span>
            <NavLink to={baseUrl} data-tooltip-content="Klicka för att redigera" data-tooltip-id="root-tooltip">
              {regionsString}
            </NavLink>
          </SummaryRow>
          <SummaryRow>
            <span>Bransch</span>
            <NavLink to={baseUrl} data-tooltip-content="Klicka för att redigera" data-tooltip-id="root-tooltip">
              {occupationalAreasString}
            </NavLink>
          </SummaryRow>
          <SummaryRow>
            <span>Ansökningstid för denna tjänst:</span>
            {applyStart || applyStop ? (
              <span>
                {isValid(applyStart) ? format(applyStart, "yy-MM-dd") : "Startdatum saknas"} till {isValid(applyStop) ? format(applyStop, "yy-MM-dd") : "Slutdatum saknas"}
              </span>
            ) : (
              <span>Inga datum angivna</span>
            )}
          </SummaryRow>
        </Summary>
        <SummaryButtons>
          <SecondaryButton disabled={!jobAd} onClick={this.onPreviewAd} className="mr3">
            Granska annons
          </SecondaryButton>
          <SecondaryButton onClick={this.onPreviewSelection} className="mr3">
            Granska urvalsfrågor
          </SecondaryButton>
          <SecondaryButton disabled={!jobAd} onClick={(e) => this.setState({ modalOpen: true })}>
            Skicka korrektur
          </SecondaryButton>
          {auditLogs && !!auditLogs.length && (
            <MailSymbol data-tooltip-content="Tidigare skickad korrektur" data-tooltip-id="root-tooltip" onClick={this.onReviewHistory}>
              <img src={icons["message"]} className="message" alt="" />
            </MailSymbol>
          )}
        </SummaryButtons>
      </Section>
      {user.superAdmin && (
        <React.Fragment>
          <Section id="external-apply" className="job-ad-section tc">
            <Title>Länk till extern ansökan</Title>
            <p>Om jobbansökan ska ske utanför Jobbet.se så ange dess adress nedan.</p>
            <Input type="text" className="mb1" placeholder="" value={applyUrl || ""} onBlur={() => this.persistRecruitment()} onChange={(e) => this.setExternalUrl(e.target.value)} />
            <br />
            {applyUrl && <small>(töm detta fält för att ansöka via Jobbet.se)</small>}
          </Section>
          <Section id="meta" className="job-ad-section tc last">
            <Title>Extra information för sökmotorer</Title>
            <p>Ange beskrivning av tjänsten nedan så att sökmotorer som Google kan hitta den bättre.</p>
            <StyledTextArea onChange={this.onMetaDescription} placeholder="Kortfattad beskrivning av tjänsten (max 160 tecken)" value={jobAd.meta.description} maxLength={160} />
          </Section>
        </React.Fragment>
      )}
      <Section className="tc">
        <PrimaryButton onClick={this.confirmLeavePage} data-tooltip-content={this.props.saved ? "Dina ändringar har sparats" : null} data-tooltip-id="root-tooltip">
          Gå vidare till annonsera{this.props.state !== RecruitmentState.ACTIVE ? "/aktivera" : ""}
        </PrimaryButton>
      </Section>
      <Modal isOpen={this.state.modalOpen} onRequestClose={this.onModalClose} className="pa4">
        <h2 className="mb4">Välj mottagare och skicka annonslänk för korrektur.</h2>
        {companyContacts && companyContacts.length > 0 && (
          <div>
            <h3>Medarbetare på {recruitment.company.name}</h3>
            <UserPicker
              className="mr3"
              users={companyContacts}
              placeholder="Välj mottagare..."
              selected={reviewCompanyContacts}
              onSelected={this.onSelectedCompanyContact}
              selectedText="person(er) vald(a)"
              noSelected="Inga personer valda"
              noUsers="Inga valbara personer"
            />{" "}
            eller
          </div>
        )}
        <h3>Fyll i mottagares e-postadress</h3>
        <Recipients>
          {otherContacts.map((oc, index) => (
            <li key={"contact-" + index}>
              <Input className="mr3" placeholder="Skriv e-postadress" value={oc} data-index={index} onChange={this.setOtherContact} />
              <MiniButton data-index={index} onClick={this.onRemoveRecipient}>
                Ta bort
              </MiniButton>
            </li>
          ))}
        </Recipients>
        <div className="mb4">
          <MiniButton disabled={disableAdd} onClick={this.onAddRecipient}>
            Lägg till fler
          </MiniButton>
        </div>
        <h3>Meddelande (annonslänk bifogas längst ner)</h3>
        <TinyEditor
          form
          placeholder="Meddelande..."
          content={this.state.content}
          style={{ width: "80vw", maxWidth: 1024, height: "100vh", maxHeight: "calc(95vh - 260px)", minHeight: 300 }}
          onChange={this.onContentChange}
          onDirty={this.props.onDirty}
        />
        <p>Se korrektur av annons här:</p>
        <p>
          <a onClick={this.onPreviewAd}>{previewAdUrl}</a>
        </p>
        <p>Se urvalsfrågor för annons här:</p>
        <p>
          <a onClick={this.onPreviewSelection}>{previewSelectionUrl}</a>
          <br />
          <br />
          <Checkbox value={attachSelection} label="Bifoga urvalslänk i mail" checked={attachSelection} onChange={this.onAttachSelection} />
        </p>
        <div className="tr">
          <PrimaryButton onClick={this.onSendReview} disabled={!jobAd}>
            Skicka korrektur
          </PrimaryButton>
        </div>
      </Modal>
      {auditLogs && !!auditLogs.length && (
        <Modal isOpen={messageLogModalOpen} onRequestClose={this.onReviewHistoryClose}>
          <ReviewMessageLog logs={auditLogs} onClose={this.onReviewHistoryClose} />
        </Modal>
      )}
    </div>
  }
}
