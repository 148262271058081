import { gql } from '@apollo/client'

export const ALL_SUPERADMIN_QUERY = gql`
query UserQuery {
  users(where: { role: { in: [SUPERADMIN, SITEADMIN] }, disabled: { equals: false } }) {
      id
      firstName
      lastName
  }
}
`
export const ALL_COMPANIES = gql`
query CompanyQuery {
  companies(where: { group: { equals: false } }) {
    id
    name
    urlName
  }
}
`
export const GET_USER = gql`
query GetUser(
    $email: String!
) {
    user(where: {email: $email}) {
        id
    }
}
`
export const CREATE_ADDRESS = gql`
mutation CreateAddress(
    $address: String,
    $postalCode: String
    $postalCity: String
) {
    createAddress(data: {
        address: $address,
        postalCode: $postalCode,
        postalCity: $postalCity
    }) {
        id
    }
}
`
export const CREATE_COMPANY_MUTATION = gql`
mutation CreateCompanyMutation(
    $name: String!,
    $urlName: String!,
    $billingInfo: String,
    $subscription: String,
    $description: String,
    $careerPage: Boolean!,
    $logotype: FileCreateNestedOneWithoutLogoCompanyInput,
    $jobbetContactsIds: [UserWhereUniqueInput!]!
) {
    createCompany(data: {
        active: true,
        group: false,
        careerPage: $careerPage,
        name: $name,
        urlName: $urlName,
        billingInfo: $billingInfo,
        subscription: $subscription,
        description: $description,
        logotype: $logotype,
        candidateSearch: false,
        jobbetContacts: { connect: $jobbetContactsIds }
    }) {
        id
    }
}
`
export const CREATE_ADMIN_MUTATION = gql`
mutation CreateAdminMutation(
    $firstName: String!,
    $lastName: String!,
    $companyId: String!,
    $companyName: String!,
    $email: String!,
    $phone: String,
    $mobile: String,
    $notify: Boolean
) {
    signupAdmin(
        firstName: $firstName,
        lastName: $lastName,
        companyId: $companyId,
        companyName: $companyName,
        policyCompanyId: $companyId,
        phone: $phone,
        mobile: $mobile,
        email: $email,
        notify: $notify,
        group: false
    )
}
`
