import React from "react"
import { gql } from "@apollo/client"
import { withApollo, graphql } from "@apollo/client/react/hoc"
import { compose } from "react-recompose"
import { NavLink, Outlet } from "react-router-dom"
import withRouter from "_hoc/withRouter"
import styled from "styled-components"
import { filter, get, isEmpty, find, some } from "lodash/fp"
import { debounce } from "lodash"
import confirm from "_components/confirm"
import Modal from "_components/modal"
import { FlexRow, FlexCol, Spinner, TextArea } from "_layout/form-elements"
import SymbolPicker from "_components/applications/symbol-picker"
import { APPLICATION_QUERY, APPLICATIONS_PROCESS_QUERY, APPLICATION_WORKING_DOCS_QUERY } from "_containers/recruitment/application-ql"
import { GET_COMPANY_CANDIDATE_APPLICATIONS_COUNT } from "_root/containers/member/members-ql"
import { Process, UserRoles, RecruitmentState, TemplateType, REC_STATS_ID } from "_root/constants"
import * as url from "_routes/url-names"
import inject from "_services/inject"
import to from "_services/await.to"
import withRecruitmentSymbols from "_modules/recruitments/hoc/withRecruitmentSymbols"
import withSetSymbolTextMutation from "_modules/recruitments/hoc/withSetSymbolTextMutation"
import { withUpdateQueryOnBroadcast, withUpdateCacheOnBroadcast } from "_root/apollo-client/links/window-broadcast-link"
import { SecondaryButton } from "_root/layout/buttons"
import ApplicationMailing from "_components/applications/application-mailing"
import ApplicationInterviews from "_components/applications/application-interviews"
import ApplicationBooking from "_components/applications/application-booking"
import ApplicationSharing from "_components/applications/application-sharing"
import ProcessList from "_components/applications/process-list"
import GradeList from "_components/applications/grade-list"
import cuid from "cuid"

//#region Styles
const Main = styled.div`
  overflow-x: auto;
  display: flex;
  padding-right: 420px;
  height: 100vh;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;

  @media screen and (max-width: 767px) {
    padding-right: unset;
  }
`
const Header = styled.div`
  position: relative;
  padding: 20px 40px;
  height: 126px;
  background: var(--color-bg-white);

  @media screen and (max-width: 767px) {
    padding: 20px;
  }
`
const Title = styled.h1`
  position: relative;
  font-weight: 400;

  &.expired {
    color: var(--color-text);
    text-decoration: line-through;
  }

  span {
    position: absolute;
    bottom: 110%;
    font-size: 0.5em;
    font-weight: 400;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
  }
`
const ManageButton = styled.a`
  position: fixed;
  top: 50%;
  right: 0;
  display: flex;
  justify-content: center;
  width: 40px;
  height: 200px;
  box-shadow: -2px 0px 10px var(--color-line-dark);
  background: ${props => (props.$open ? "var(--color-brand-black)" : "var(--color-brand-red)")};
  transform: translateY(-50%);
  z-index: 30000;

  span {
    color: var(--color-text-white);
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    writing-mode: vertical-rl;
  }

  &:hover {
    text-decoration: none;
  }
`
const Menu = styled.ul`
  position: absolute;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  top: 100%;
  left: 40px;
  right: 40px;
  margin: 0;
  padding: 0;
  font-size: var(--font-size-small);
  box-shadow: 0px 0px 15px var(--color-line-dark);
  list-style-type: none;
  transform: translateY(-50%);
  background: var(--color-bg-white);
  z-index: 1000;
`
const MenuItem = styled.li`
  background: var(--color-bg-white);

  &.wide {
    flex: 1 1 auto;
    text-align: right;
  }
`
const MenuLink = styled(NavLink)`
  cursor: pointer;
  display: inline-block;
  margin: 0 10px;
  padding: 10px 0 5px 0;
  color: var(--color-text-dark);
  text-decoration: none;
  white-space: nowrap;
  border-bottom: 5px solid transparent;

  &.active,
  &.active:hover {
    border-bottom: 5px solid var(--color-brand-red-light);
  }

  &:hover {
    text-decoration: none;
    border-bottom: 5px solid var(--color-bg-grey);
  }
`
const Content = styled.div`
  position: relative;
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-flow: column nowrap;
  padding: ${props => (props.$padding ? "0 20px 40px 20px" : "0")};
  height: calc(100vh - 126px);
  background: var(--color-bg-white);

  @media screen and (max-width: 767px) {
    overflow-y: unset;
    padding-right: 40px;
  }
`
const SideBar = styled.div`
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 420px;
  padding: 40px;
  background: var(--color-bg-lightest);
  box-shadow: -2px 0 2px 1px var(--color-bg-light);
  z-index: 1000;

  h4 {
    font-size: 0.8em;
    text-transform: uppercase;
  }

  @media screen and (max-width: 767px) {
    left: 100%;
    right: unset;
    transition: left 0.25s ease-out;
    padding: 20px;
    width: 100vw;

    &.show {
      left: 0;
      transition: left 0.25s ease-out;
    }
  }
`
const Symbols = styled.div`
  background: var(--color-bg-lightest);
`
//#endregion

@compose(
  withRouter,
  withApollo,
  inject("user"),
  graphql(APPLICATION_QUERY, {
    name: "applicationQuery",
    options: props => ({
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      variables: { id: props.match.params.application },
    }),
  }),
  graphql(GET_COMPANY_CANDIDATE_APPLICATIONS_COUNT, {
    name: "applicationCountQuery",
    skip: props => !get("applicationQuery.application.candidate.id")(props),
    options: props => ({
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      variables: { candidateId: props.applicationQuery.application.candidate.id },
    }),
  }),
  graphql(APPLICATION_WORKING_DOCS_QUERY, {
    skip: props => !get("applicationQuery.application.id")(props),
    name: "workingDocsQuery",
    options: props => ({
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      variables: { id: props.applicationQuery.application.id },
    }),
  }),
  withRecruitmentSymbols({
    skip: props => !get("applicationQuery.application.recruitment.id")(props),
    options: props => ({
      variables: {
        recruitmentId: props.applicationQuery.application.recruitment.id,
      },
    }),
  }),
  withSetSymbolTextMutation,
  withUpdateQueryOnBroadcast({
    applicationQuery: ["UpdateProcessStep"], // Update applicationQuery above when any UpdateApplication mutation happens in childwindow.
    recruitmentSymbolsQuery: ["SymbolTextMutation"],
  }),
  withUpdateCacheOnBroadcast({
    applicationQuery: ["UpdateProcessStep"], // Update recruitment cache when any UpdateApplication or UpdateProcessStep mutation happens in childwindow.
    recruitmentSymbolsQuery: ["SymbolTextMutation"],
  })
)
export default class RecruitmentApplication extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mobile: window.innerWidth < 768,
      application: null,
      recruitment: null,
      process: [],
      emailTemplates: [],
      selected: [],
      notes: null,
    }
    this.baseUrl = `/${this.props.match.params.company}${url.REK_APPLICATION}/${props.match.params.application}`
  }

  componentDidMount = () => {
    document.title = "Laddar ansökan... - Jobbet.se"
    this.fetchApplications()
  }

  componentDidUpdate = async () => {
    this.fetchApplications()
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    let nextState = {}
    const { user, applicationQuery, applicationCountQuery, workingDocsQuery } = nextProps
    if (applicationCountQuery?.loading === false && !!applicationCountQuery?.aggregateApplication?._count) {
      const appCount = applicationCountQuery.aggregateApplication._count._all
      if (appCount != prevState.appCount && appCount > 0) nextState.appCount = appCount - 1
    }
    if (workingDocsQuery?.loading === false && workingDocsQuery?.application) {
      let {
        application: { workingDocs = [] },
      } = workingDocsQuery
      if (!user.superAdmin) {
        workingDocs = filter(doc => find(c => c.id === user.employer.id)(doc.visible))(workingDocs)
      }

      if (workingDocs.length != prevState.docCount) nextState.docCount = workingDocs.length
    }
    if (!applicationQuery.loading && applicationQuery.application) {
      const application = applicationQuery.application
      if (application.candidate && application.candidate.user) {
        document.title = `${application.candidate.user.firstName} ${application.candidate.user.lastName} - ${application.recruitment.title} - ${application.recruitment.company.name}`
      }
      const process = filter(s => s.order > 0 && s.state !== Process.INVALID)(application.process)
      const recruitment = application.recruitment
      const notes = prevState.notes === null ? application.notes : prevState.notes
      const emailTemplates = filter(t => t.type === TemplateType.APPLICANT_EMAIL)(recruitment.company.templates)
      nextState = {
        ...nextState,
        application,
        process,
        recruitment,
        notes,
        selected: [application],
        emailTemplates,
      }
    }
    return !isEmpty(nextState) ? nextState : null
  }

  fetchApplications = async () => {
    if (this.state.processApplications || !this.state.application) return
    const [error, result] = await to(
      this.props.client.query({
        query: APPLICATIONS_PROCESS_QUERY,
        variables: { recruitmentId: this.state.recruitment.id },
      })
    )
    if (error) {
      console.error("recruitment-application:fetchApplications:error", error)
      const reload = await confirm("Ett oväntat fel inträffade.", { text: "Klicka på OK för att ladda om sidan." })
      if (reload) window.location.reload()
    } else {
      return this.setState({ processApplications: result.data.applications })
    }
  }

  onGrade = async (grade, callback) => {
    const appId = this.state.application.id
    const processId = this.state.recruitment.process[0].id
    const mutation = gql`
      mutation UpdateApplication($id: String!, $grade: Int!, $processId: String!) {
        updateApplication(where: { id: $id }, data: { grade: { set: $grade } }) {
          id
        }
        updateProcessStep(where: { id: $processId }, data: { state: { set: ACTIVE } }) {
          id
        }
      }
    `
    const variables = { id: appId, grade: grade, processId: processId }
    const [error, result] = await to(this.props.client.mutate({ mutation, variables }))
    callback(!!result)
    if (error || !result) {
      console.error("recruitment-application:onGrade:error", error)
      const reload = await confirm("Ett oväntat fel inträffade.", { text: "Klicka på OK för att ladda om sidan." })
      if (reload) window.location.reload()
    } else {
      const application = { ...this.state.application, grade }
      this.setState({
        application,
        selected: [application],
      })
      const recruitmentStats = {
        __typename: "RecruitmentStats",
        id: REC_STATS_ID,
        countRecruitmentsIsStale: true,
        activeRecruitmentsIsStale: false,
        draftRecruitmentsIsStale: false,
        closedRecruitmentsIsStale: false,
      }
      switch (this.state.recruitment.state) {
        case RecruitmentState.ACTIVE:
          recruitmentStats.activeRecruitmentsIsStale = true
          break
        case RecruitmentState.DRAFT:
          recruitmentStats.draftRecruitmentsIsStale = true
          break
        default:
          recruitmentStats.closedRecruitmentsIsStale = true
      }
      this.props.client.writeQuery({
        query: gql`
          query RecruitmentStats {
            recruitmentStats {
              countRecruitmentsIsStale
              activeRecruitmentsIsStale
              draftRecruitmentsIsStale
              closedRecruitmentsIsStale
            }
          }
        `,
        data: {
          recruitmentStats,
        },
      })
      this.props.applicationQuery.refetch()
    }
  }

  onNotesChange = e => {
    this.setState({ notes: e.target.value || "" }, this.onNotesSave)
  }

  onNotesSave = debounce(async () => {
    const { application, notes } = this.state
    const variables = { appId: application.id, notes: notes || "" }
    const mutation = gql`
      mutation UpdateApplication($appId: String!, $notes: String!) {
        updateApplication(where: { id: $appId }, data: { notes: { set: $notes } }) {
          id
        }
      }
    `
    const [error, result] = await to(this.props.client.mutate({ mutation, variables }))
    if (error || !result) {
      console.error("recruitment-application:onNotesSave:error", error)
      const reload = await confirm("Ett oväntat fel inträffade.", { text: "Klicka på OK för att ladda om sidan." })
      if (reload) window.location.reload()
    }
  }, 1000)

  handleSymbolTextChange = async (symbol, newText, callback) => {
    const [error, result] = await to(
      this.props.setSymbolText({
        symboldId: symbol.id,
        symbolTextId: symbol.customTextId || cuid(),
        recruitmentId: this.state.recruitment.id,
        text: newText,
      })
    )
    if (error || !result) {
      console.error("recruitment-application:handleSymbolTextChange:error", error)
      const reload = await confirm("Ett oväntat fel inträffade.", { text: "Klicka på OK för att ladda om sidan." })
      if (reload) window.location.reload()
    }
    callback(!!result)
  }

  hasWriteAccess = () => {
    return this.props.user && [UserRoles.GROUPVIEWER, UserRoles.VIEWER].includes(this.props.user.role) === false
  }

  toggleManage = () => {
    this.state.mobile && this.setState({ manageOpen: !this.state.manageOpen })
  }

  sendEmail = e => {
    this.openModal("email")
  }

  bookInterview = e => {
    this.openModal("booking")
  }

  rebookInterview = e => {
    this.openModal("rebook")
  }

  shareApplications = e => {
    this.openModal("share")
  }

  openModal = dialog => {
    let newState = {
      emailModalOpen: false,
      bookModalOpen: false,
      rebookModalOpen: false,
      shareModalOpen: false,
    }
    switch (dialog) {
      case "email":
        newState.emailModalOpen = true
        break
      case "share":
        newState.shareModalOpen = true
        break
      case "booking":
        newState.bookModalOpen = true
        break
      case "rebook":
        newState.rebookModalOpen = true
        break
      default:
        break
    }
    this.setState(newState)
  }

  closeModal = () => {
    this.openModal(null)
  }

  deleteApplication = async e => {
    const confirmed = await confirm(`Vill du ta bort denna ansökan?`)
    if (!confirmed) return
    const deleteApplications = this.state.selected
      .map(a => {
        return `${a.id}: deleteApplication(where: { id: "${a.id}" }) { id }`
      })
      .join("\n")
    const mutation = gql`mutation DeleteApplications {
            ${deleteApplications}
        }`
    const [error, result] = await to(this.props.client.mutate({ mutation }))
    if (error || !result) {
      console.error("application-list:deleteApplications:error", error)
      const reload = await confirm("Ett oväntat fel inträffade.", { text: "Klicka på OK för att ladda om sidan." })
      if (reload) window.location.reload()
    } else {
      this.setState({ application: null }, () => {
        window.close()
      })
    }
  }

  onModalClose = e => {
    this.closeModal()
  }

  applicationMailingOnUpdate = e => {
    window.location.reload()
  }

  render() {
    const { mobile, application, recruitment, processApplications, manageOpen } = this.state

    const { applicationQuery, user } = this.props

    if (!application && applicationQuery.loading) {
      return (
        <Main>
          <Content>
            <Spinner />
          </Content>
        </Main>
      )
    }

    if (!application) {
      return (
        <Main>
          <Header>
            <Title>Ansökan har tagits bort.</Title>
          </Header>
        </Main>
      )
    }

    const symbols = get("recruitmentSymbolsQuery.recruitmentSymbols")(this.props)

    const { emailModalOpen, shareModalOpen, bookModalOpen, rebookModalOpen, emailTemplates, selected, appCount, docCount } = this.state

    const titlePrefix = recruitment.title
    const { baseUrl } = this
    const { company } = this.props.match.params
    const isBooked = !!application.interviews.filter(i => i.status === "BOOKED").length
    const { recruiter, recruiterGuests, recruiterExternals } = recruitment
    const userAdmin = user.superAdmin || user.groupAdmin || user.isAdmin
    const hasPermission = userAdmin || recruiter.id === user.id || some({ id: user.id })(recruiterExternals) || some({ id: user.id })(recruiterGuests)

    if (!hasPermission) {
      return (
        <Main>
          <Header>
            <Title>Du har inte behörighet att se denna ansökan.</Title>
          </Header>
        </Main>
      )
    }

    return (
      <Main readOnly={!this.hasWriteAccess()}>
        {mobile && (
          <ManageButton $open={manageOpen} onClick={this.toggleManage}>
            <span>{manageOpen ? "Läs ansökan" : "Hantera ansökan"}</span>
          </ManageButton>
        )}
        <Header>
          <Title className={application.expired ? "expired" : null} title={application.expired ? "Utgången ansökan" : null}>
            <span>{titlePrefix}</span>
            {!application.candidate && `Utgången ansökan`}
            {application.candidate && `${application.candidate.user.firstName} ${application.candidate.user.lastName}`}
          </Title>
          {this.props.applicationQuery.loading && <Spinner />}
          {!mobile && (
            <Menu>
              <MenuItem>
                <MenuLink to={baseUrl} end>
                  Aktuell ansökan
                </MenuLink>
              </MenuItem>
              {this.hasWriteAccess() && (
                <MenuItem>
                  <MenuLink to={baseUrl + url.REK_APP_HISTORY}>Tidigare ansökningar ({appCount || "-"})</MenuLink>
                </MenuItem>
              )}
              <MenuItem>
                <MenuLink to={baseUrl + url.REK_APP_DOK}>Intervju- och referensmallar ({docCount || "-"})</MenuLink>
              </MenuItem>
              {this.hasWriteAccess() && (
                <MenuItem className="wide">
                  <MenuLink to={baseUrl + url.REK_LOGGBOK}>Loggbok</MenuLink>
                </MenuItem>
              )}
            </Menu>
          )}
        </Header>
        <Content id="main-content" $padding={this.props.padding}>
          <Outlet context={[recruitment, application, company]} />
        </Content>
        {this.hasWriteAccess() && processApplications && (
          <SideBar className={manageOpen ? "show" : ""}>
            <h2>Hantera ansökan</h2>
            <FlexRow $nowrap $between>
              <FlexCol>
                <h4>Rekryteringsstatus</h4>
                <ProcessList application={application} applications={processApplications} />
              </FlexCol>
              <FlexCol>
                <h4>Gradera kandidat</h4>
                <GradeList application={application} onGrade={this.onGrade} />
              </FlexCol>
            </FlexRow>
            <FlexRow>
              <FlexCol $full $stretch>
                <h4>Symboler</h4>
                <Symbols>
                  <SymbolPicker recruitmentId={recruitment.id} applications={[application]} symbols={symbols} onSymbolTextChange={this.handleSymbolTextChange} />
                </Symbols>
                <h4 className="mt4">Noteringar om kandidat</h4>
                <TextArea placeholder="Skriv dina kommentarer..." style={{ minHeight: 100, width: "100%" }} value={this.state.notes || ""} onBlur={this.onNotesSave} onChange={this.onNotesChange} />
              </FlexCol>
            </FlexRow>
            <FlexRow>
              {application.candidate && (
                <FlexCol $full $stretch>
                  <SecondaryButton className="mh4 mb3" onClick={this.sendEmail}>
                    E-post till kandidat
                  </SecondaryButton>
                  {!isBooked && (
                    <SecondaryButton className="mh4 mb3" onClick={this.bookInterview}>
                      Boka tid
                    </SecondaryButton>
                  )}
                  {isBooked && (
                    <SecondaryButton className="mh4 mb3" onClick={this.rebookInterview}>
                      Boka om
                    </SecondaryButton>
                  )}
                  <SecondaryButton className="mh4 mb3" onClick={this.shareApplications}>
                    Dela ansökan
                  </SecondaryButton>
                  {user.superAdmin && (
                    <SecondaryButton className="mh4 mb3" onClick={this.deleteApplication}>
                      Ta bort ansökan
                    </SecondaryButton>
                  )}
                </FlexCol>
              )}
            </FlexRow>
            <Modal full={mobile} isOpen={emailModalOpen} onRequestClose={this.onModalClose}>
              <ApplicationMailing
                companyId={recruitment.company.id}
                recruitmentId={recruitment.id}
                templates={emailTemplates}
                applications={selected}
                onClose={this.onModalClose}
                onUpdate={this.applicationMailingOnUpdate}
              />
            </Modal>
            <Modal full={mobile} isOpen={bookModalOpen} onRequestClose={this.onModalClose}>
              <ApplicationInterviews
                companyId={recruitment.company.id}
                application={application}
                recruitmentId={recruitment.id}
                templates={emailTemplates}
                applications={selected}
                onClose={this.onModalClose}
                onUpdate={this.applicationMailingOnUpdate}
              />
            </Modal>
            <Modal full={mobile} isOpen={rebookModalOpen} onRequestClose={this.onModalClose}>
              <ApplicationBooking
                companyId={recruitment.company.id}
                application={application}
                recruitmentId={recruitment.id}
                templates={emailTemplates}
                applications={selected}
                onClose={this.onModalClose}
                onUpdate={this.applicationMailingOnUpdate}
              />
            </Modal>
            <Modal full={mobile} isOpen={shareModalOpen} onRequestClose={this.onModalClose} className="pa4" overflow>
              <ApplicationSharing recruitmentId={recruitment.id} title={recruitment.title} company={recruitment.company} applications={selected} onClose={this.onModalClose} />
            </Modal>
          </SideBar>
        )}
      </Main>
    )
  }
}
