function handleClickOutside(refs, callback) {
  const handleOutsideClick = (e) => {
    const clickedOutside = refs.every(ref => ref.current && !ref.current.contains(e.target))
    if (clickedOutside) {
      callback()
    }
  }
  
  document.addEventListener('click', handleOutsideClick)
  
  return () => {
    document.removeEventListener('click', handleOutsideClick)
  }
}

export default handleClickOutside