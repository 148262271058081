import React from "react"
import MainLayout from "_layout/main-layout"
import { H2, FlexRow, FlexCol } from "_layout/form-elements"
import { render } from "react-dom"

// export default class AdminTemplates extends React.Component {
//   render() {
//     return (
//       <MainLayout padding maxHeight scroll>
//         <div>
//           <H2>Mallar</H2>
//           <FlexRow className="margin20">
//             <FlexCol>Här kommer du att kunna administrerar mallar för olika ändamål.</FlexCol>
//           </FlexRow>
//         </div>
//       </MainLayout>
//     )
//   }
// }

const AdminTemplates = () => {
  return (
    <MainLayout padding maxHeight scroll>
      <div>
        <H2>Mallar</H2>
        <FlexRow className="margin20">
          <FlexCol>Här kommer du att kunna administrerar mallar för olika ändamål.</FlexCol>
        </FlexRow>
      </div>
    </MainLayout>
  )
}
export default AdminTemplates
