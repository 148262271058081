import React from 'react'
import MainLayout from '_layout/main-layout'
import { H2, FlexRow, FlexCol } from '_layout/form-elements'

const Departments = () => {
  return (
    <MainLayout padding>
      <H2>Avdelningar</H2>
      <FlexRow className='margin20'>
        <FlexCol>
          Här kommer du kunna skapa avdelningar för din organisation.
        </FlexCol>
      </FlexRow>
    </MainLayout>
  )
}
export default Departments
