import React from "react"
import { graphql, withApollo } from "@apollo/client/react/hoc"
import { compose } from "react-recompose"
import withRouter from "_hoc/withRouter"
import styled from "styled-components"
import { cloneDeep } from "lodash/fp"
import StateToggle from "_components/menu/state-toggle"
import PackageInformation from "./package-information"
import { InternetPackage, YoungPackage, SocialMediaPackage } from "./publication-package"
import PublishChannel from "./publish-channel"
import PublishCareer from "./publish-career"
import PublishOther from "./publish-other"
import ShareableLink from "./sharable-link"
import {
  RECRUITMENT_PUBLICATIONCHANNELS,
  RECRUITMENT_ADD_CHANNELSELECTION,
  RECRUITMENT_REMOVE_CHANNELSELECTION,
  RECRUITMENT_UPDATE_OTHERCHANNEL,
  RECRUITMENT_TOGGLE_CHANNELOFFER,
  RECRUITMENT_SET_CHANNELACTIVESTATE,
  RECRUITMENT_GET_PUBLISHEDDURATION,
  RECRUITMENT_SET_PUBLISHEDDURATION,
} from "_modules/recruitments/publicationchannel-ql"
import to from "_services/await.to"
import inject from "_services/inject"

//#region Styles
const SectionContent = styled.div`
  max-width: 960px;

  & > p {
    text-align: center;
  }
`
const BoxContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
`
const PackagesContainer = styled.div`
  margin-top: 20px;
  padding: 30px 20px;
  background: var(--color-bg-white);
  box-shadow: 0 12px 20px var(--color-bg-grey);

  h2 {
    font-size: 1.75rem;
    text-align: center;
    margin-bottom: 1rem;
  }

  h3 {
    text-align: center;
    margin-bottom: 2rem;
  }

  > div {
    display: flex;
    flex-flow: row nowrap;
  }
`
const Publish = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin-right: -24px;
`
const CareerChannelWrapper = styled.div`
  width: 304px;
  margin: 0 24px 0 0;
  opacity: ${props => props.$opacity};

  & > * {
    height: 100%;
  }
`
const PublishChannelWrapper = styled.div`
  flex-grow: ${props => props.$flexGrow};
  flex-shrink: ${props => props.$flexShrink};
  flex-basis: ${props => props.$flexBasis};
  min-width: ${props => props.$minWidth};
  max-width: ${props => props.$maxWidth};
  order: ${props => props.$flexOrder};
  opacity: ${props => props.$opacity};
  margin: 0 24px 24px 0;

  & > * {
    height: 100%;
  }
`
const Statistics = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 24px;
`
const BlocketJobb = styled.div`
  flex-basis: 45%;
  padding: 20px;
  margin-right: 20px;
  border-bottom: 2px solid var(--color-line-dark);
  background-color: var(--color-bg-white);

  > p {
    font-size: 18px;
    margin-top: 0;
  }

  .graph {
    max-height: 200px;
  }

  > img:last-child {
    width: 160px;
    margin-top: 10px;
    margin-left: -3px;
  }
`
const LinkedIn = styled.div`
  flex: 1;
  padding: 20px;
  border-bottom: 2px solid var(--color-line-dark);
  background-color: var(--color-bg-white);

  > img:first-child {
    width: 130px;
    margin-right: 30px;
  }

  .graph {
    max-height: 200px;
  }

  > div {
    display: inline-block;
    width: 60%;
  }

  p {
    margin: 0;
    font-size: 18px;
  }

  /* @media (-webkit-min-device-pixel-ratio: 1.5) {
    p {
      font-size: 14px;
    }
  } */
`
const Activation = styled.div`
  display: inline-block;
  width: 304px;
  margin-right: 24px;
  border-bottom: 2px solid var(--color-line-dark);
  background-color: var(--color-bg-white);
`
const ActivationHeader = styled.div`
  position: relative;
  height: 40px;
  background-color: var(--color-bg-lightest);
`
const ActivationContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 24px;

  header {
    font-weight: bold;
    margin-bottom: 20px;
  }
`
const HorizontalLineTitle = styled.h3`
  overflow: hidden;
  text-align: center;
  margin: 24px 0;
  font-size: 1.125rem;
  line-height: 1.5rem;
  text-transform: uppercase;

  &:after,
  &:before {
    content: "";
    display: inline-block;
    position: relative;
    border-bottom: 1px solid var(--color-line-dark);
    height: 0;
    vertical-align: middle;
    width: 50%;
  }

  &:before {
    right: 15px;
    margin-left: -50%;
  }

  &:after {
    left: 15px;
    margin-right: -50%;
  }
`
//#endregion Styles

const withChannelSelectMutation = graphql(RECRUITMENT_ADD_CHANNELSELECTION, {
  props: ({ ownProps, mutate }) => ({
    select: ({ channelId, activate = false }) => {
      return mutate({
        variables: { channelId, recruitmentId: ownProps.match.params.recruitment, activate },
      })
    },
  }),
})

const withChannelDeselectMutation = graphql(RECRUITMENT_REMOVE_CHANNELSELECTION, {
  props: ({ mutate }) => ({
    deselect: ({ selectionId }) => {
      return mutate({
        variables: { selectionId },
      })
    },
  }),
})

const withChannelToggleOfferMutation = graphql(RECRUITMENT_TOGGLE_CHANNELOFFER, {
  props: ({ ownProps, mutate }) => ({
    toggleOffer: ({ channelId, selectionId, selectedOffer }) => {
      return mutate({
        variables: { selectionId, selectedOffer },
        update: (
          cache,
          {
            data: {
              updateRecruitmentSelectedPublishChannel: { activated },
            },
          }
        ) => {
          const { variables } = ownProps.publicationChannels
          const data = cloneDeep(cache.readQuery({ query: RECRUITMENT_PUBLICATIONCHANNELS, variables }))
          cache.writeQuery({ query: RECRUITMENT_PUBLICATIONCHANNELS, data, variables })
        },
      })
    },
  }),
})

const withUpdateOtherChannelMutation = graphql(RECRUITMENT_UPDATE_OTHERCHANNEL, {
  props: ({ ownProps, mutate }) => ({
    saveOtherChannelsText: ({ commentText }) => {
      return mutate({
        variables: { recruitmentId: ownProps.match.params.recruitment, commentText },
      })
    },
  }),
})

const withChannelActivationMutation = graphql(RECRUITMENT_SET_CHANNELACTIVESTATE, {
  props: ({ ownProps, mutate }) => ({
    setChannelActiveState: ({ selectionId, channelId, activated }) => {
      if (!selectionId) return
      return mutate({
        variables: { selectionId, activated },
        update: (cache, _) => {
          const variables = { recruitmentId: ownProps.match.params.recruitment }
          const data = cloneDeep(cache.readQuery({ query: RECRUITMENT_PUBLICATIONCHANNELS, variables }))
          cache.writeQuery({ query: RECRUITMENT_PUBLICATIONCHANNELS, data, variables })
        },
      })
    },
  }),
})

const withJobAdDurationMutation = graphql(RECRUITMENT_SET_PUBLISHEDDURATION, {
  props: ({ ownProps, mutate }) => ({
    setJobAdDurationAndActiveState: ({ selectionId, channelId, activated, publishStart, publishStop }) => {
      const {
        recruitment: {
          jobAd: { id: jobId },
        },
      } = ownProps.jobAdDuration
      return mutate({
        variables: {
          jobId,
          publishStart,
          publishStop,
          selectionId,
          activated,
        },
        update: (cache, _) => {
          const variables = { recruitmentId: ownProps.match.params.recruitment }
          const data = cloneDeep(cache.readQuery({ query: RECRUITMENT_PUBLICATIONCHANNELS, variables }))
          cache.writeQuery({ query: RECRUITMENT_PUBLICATIONCHANNELS, data, variables })
        },
      })
    },
  }),
})

const withPublicationChannelData = graphql(RECRUITMENT_PUBLICATIONCHANNELS, {
  name: "publicationChannelsQuery",
  options: props => ({
    fetchPolicy: "network-only",
    variables: {
      recruitmentId: props.match.params.recruitment,
    },
  }),
})

const withJobAdDurationData = graphql(RECRUITMENT_GET_PUBLISHEDDURATION, {
  name: "jobAdDuration",
  options: props => ({
    variables: {
      recruitmentId: props.match.params.recruitment,
    },
  }),
})

@compose(
  inject("user"),
  withApollo,
  withRouter,
  withPublicationChannelData,
  withJobAdDurationData,
  withChannelSelectMutation,
  withChannelDeselectMutation,
  withChannelToggleOfferMutation,
  withChannelSelectMutation,
  withUpdateOtherChannelMutation,
  withChannelActivationMutation,
  withJobAdDurationMutation
)
export default class PublicationChannelSection extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      selectedDeals: props.selectedDeals || [],
      state: props.state,
    }

    this.onUpdateCallback = this.props.onPublishSelectionChange ? this.props.onPublishSelectionChange : () => {}
  }

  onPackageSelectionChange = (packageName) => {
    this.props.onPackageSelectionChange(packageName)
  }

  onPublishChannelCheckedChange = async ({ checkedState, channelId, selectionId, activate = false }) => {
    this.onUpdateCallback("PENDING")
    if (checkedState) {
      const [err] = await to(this.props.select({ channelId, activate }))
      if (!err) {
        this.onUpdateCallback("DONE")
        this.props.publicationChannelsQuery.refetch()
      }
    } else {
      const [err] = await to(this.props.deselect({ channelId, selectionId }))
      if (!err) {
        this.onUpdateCallback("DONE")
        this.props.publicationChannelsQuery.refetch()
      }
    }
  }

  onPublishChannelOfferChange = async ({ selectedOffer, channelId, selectionId }) => {
    let tempSelectionId = selectionId
    let err, result
    if (selectedOffer && !selectionId) {
      ;[err, result] = await to(this.props.select({ channelId }))
      if (result) {
        const {
          data: {
            createRecruitmentSelectedPublishChannel: { id: createdId },
          },
        } = result
        tempSelectionId = createdId
      }
    }
    if (tempSelectionId) {
      ;[err] = await to(this.props.toggleOffer({ channelId, selectionId: tempSelectionId, selectedOffer }))
    }
    if (!err) {
      this.onUpdateCallback("DONE")
    }
  }

  onPersistDuration = (selectionContext) => {
    const { setJobAdDurationAndActiveState } = this.props
    const {
      recruitment: {
        jobAd: { id: jobId },
      },
    } = this.props.jobAdDuration
    return setJobAdDurationAndActiveState({
      ...selectionContext,
      jobId,
    })
  }

  render() {
    const { loading: channelsLoading, publishChannels } = this.props.publicationChannelsQuery

    const careerChannel = publishChannels && publishChannels.find((pc) => pc.title === "Karriärsida")
    const careerChannelSelected = careerChannel && !!careerChannel.selected.length && careerChannel.selected[0]
    const commonChannels = publishChannels && publishChannels.filter((pc) => pc.id !== careerChannel.id)

    const {
      state,
      user,
      packageSelection: publishPackageTypes,
      jobAdDuration: { loading: jobAdLoading, recruitment: { jobAd = {}, title = "", company = {} } = {} },
      recruitmentDuration,
      publishChannelsOther,
    } = this.props

    const readOnly = state === "CLOSED"
    const loading = channelsLoading || jobAdLoading

    return (
      <SectionContent>
        {!user.companyView.isJobbet && <PackageInformation recruitment={this.props.recruitment} />}
        <BoxContainer>
          <Activation>
            <ActivationHeader></ActivationHeader>
            <ActivationContent>
              <header>Rekryteringsstatus</header>
              <StateToggle state={state} onToggle={this.props.onStateChange} />
            </ActivationContent>
          </Activation>
          {careerChannel && (
            <CareerChannelWrapper key={careerChannel.id} $opacity={!company.careerPage ? 0.5 : 1}>
              <PublishCareer
                id={careerChannel.id}
                readOnly={readOnly}
                logoUrl={careerChannel?.logo?.url}
                title={careerChannel.title}
                selected={!!careerChannelSelected}
                selectionId={!!careerChannelSelected && careerChannelSelected.id}
                activated={!!careerChannelSelected && careerChannelSelected.activated}
                disableCareer={!company.careerPage}
                onChange={this.onPublishChannelCheckedChange}
              />
            </CareerChannelWrapper>
          )}
          {jobAd.id && title && company.name && <ShareableLink jobAdId={jobAd.id} jobAdTitle={title} companyName={company.name} />}
        </BoxContainer>
        <PackagesContainer>
          <h2>Vi rekommenderar följande annonspaket!</h2>
          <h3>Skräddarsydd marknadsföring och annonsering för att nå rätt målgrupp</h3>
          <div>
            <SocialMediaPackage
              readOnly={readOnly}
              onClick={({ checkedState }) => this.onPackageSelectionChange("SOCIALMEDIA", checkedState)}
              selected={!!publishPackageTypes && !!publishPackageTypes.find((p) => p.value === "SOCIALMEDIA")}
            />
            <YoungPackage
              readOnly={readOnly}
              onClick={({ checkedState }) => this.onPackageSelectionChange("YOUNG", checkedState)}
              selected={!!publishPackageTypes && !!publishPackageTypes.find((p) => p.value === "YOUNG")}
            />
            <InternetPackage
              readOnly={readOnly}
              onClick={({ checkedState }) => this.onPackageSelectionChange("INTERNET", checkedState)}
              selected={!!publishPackageTypes && !!publishPackageTypes.find((p) => p.value === "INTERNET")}
            />
          </div>
        </PackagesContainer>
        <HorizontalLineTitle>Individuella annonskanaler</HorizontalLineTitle>
        {!loading && (
          <Publish>
            {commonChannels &&
              commonChannels.map((publishChannel) => {
                const { selected: [selection] = [] } = publishChannel
                return (
                  <PublishChannelWrapper key={publishChannel.id} $flexOrder={publishChannel.sort} $flexShrink="1.5" $flexBasis="304px" $flexGrow="1.5">
                    <PublishChannel
                      id={publishChannel.id}
                      readOnly={readOnly}
                      logoUrl={publishChannel?.logo?.url}
                      title={publishChannel.title}
                      duration={publishChannel.publishedDuration}
                      price={publishChannel.price}
                      offer={publishChannel.offer}
                      description={publishChannel.description}
                      selected={!!selection}
                      selectionId={selection && selection.id}
                      activated={selection && selection.activated}
                      selectedOffer={!!selection && selection.selectedOffer}
                      onActivationChange={this.props.setChannelActiveState}
                      onPersistDuration={this.onPersistDuration}
                      onChange={this.onPublishChannelCheckedChange}
                      onOffer={this.onPublishChannelOfferChange}
                      recruitmentDuration={recruitmentDuration}
                      jobAdDuration={this.props.jobAdDuration}
                    />
                  </PublishChannelWrapper>
                )
              })}
            <PublishChannelWrapper className="mb0" $flexOrder="90" $flexShrink="1.5" $flexBasis="468px" $flexGrow="1.5">
              <PublishOther readOnly={readOnly} text={publishChannelsOther} onChange={this.props.onOtherChannelChange} />
            </PublishChannelWrapper>
          </Publish>
        )}
      </SectionContent>
    )
  }
}
