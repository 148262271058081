import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import filter from 'lodash/fp/filter'
import differenceBy from 'lodash/fp/differenceBy'
import sortBy from 'lodash/fp/sortBy'
import { getHidingParent } from '_services/util'
import { Checkbox } from '_layout/form-elements'
import Falafel from '_images/menu-dots.svg'
const remove = require('lodash/fp/remove').convert({ immutable: false })
import handleKeyPress from '_utils/handleKeyPress'
import handleClickOutside from '_utils/handleClickOutside'

//#region Styles
const Content = styled.div`
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
  box-shadow: 0px 2px 0px var(--color-line);
  width: ${props => props.$width ? props.$width : 'min(300px, 100%)'};

  input[type=text] {
    width: 100%;
  }

  &:hover {
    box-shadow: 0px 2px 0px var(--color-line-dark);
  }
`
const Dropdown = styled.div`
  display: flex;
  flex-flow: column nowrap;
  position: absolute;
  left: 0;
  min-width: min(300px, 100%);
  background: var(--color-bg-white);
  border: 1px solid var(--color-line-dark);
  box-shadow: 2px 2px 10px var(--color-line-dark);
  z-index: 10000;

  &:focus {
    outline: none;
  }
`
const UserList = styled.ul`
    margin: 0;
    padding: 0;
    overflow-y: auto;
    list-style-type: none;
`
const ListItem = styled.li`
    color: var(--color-text-dark);

    > label {
        cursor: pointer;
        position: relative;
        display: block;
        padding: 10px 10px 10px 36px;

        > label {
            position: absolute;
            top: 50%;
            left: 10px;
            transform: translateY(-50%);
        }
    }

    &.no-users {
        padding: 10px;
    }

    &:nth-child(even) {
        background: var(--color-bg-lightest);
    }

    &:hover {
        color: var(--color-text-white);
        background: var(--color-nav-1);
    }
`
const Title = styled.h4`
    flex: none;
    margin: 0;
    padding: 5px 10px;
    font-weight: 400;
    color: var(--color-text-white);
    background: var(--color-nav-2);
`
const Input = styled.input`
    cursor: pointer;
    width: 100%;
    background: url(${Falafel}) scroll 100% 50% no-repeat var(--color-bg-white);
    background-size: 24px 16px;

    &[type=text] {
        padding-right: 10%;
    }

    &::placeholder, ::placeholder {
        color: var(--color-text-dark);
    }

    &:focus {
        cursor: text;
    }

    &[disabled] {
        background: var(--color-bg-lightest);
    }
`
//#endregion

const UserPicker = ({ selectedText = 'person(er) vald(a)', noUsers = 'Inga valbara personer', noSelected = 'Inga personer valda', placeholder = 'Välj kontakt på Jobbet.se...', readOnly = false, onSelected, width, className, users, selected: initialSelected }) => {

  const textInput = useRef(null)
  const dropdownRef = useRef(null)

  const [showList, setShowList] = useState(false)
  const [filterText, setFilterText] = useState('')
  const [selectable, setSelectable] = useState(() => sortBy(['firstName'])(differenceBy('id')(users)(initialSelected)))
  const [selected, setSelected] = useState(() => sortBy(['firstName'])(initialSelected) || [])
  const [placeholderState, setPlaceholderState] = useState(placeholder)
  const [dropStyle, setDropStyle] = useState({})
  const [sublistStyle, setSublistStyle] = useState({})

  useEffect(() => {
    setSelectable(sortBy(['firstName'])(differenceBy('id')(users)(selected)))
  }, [users, selected])

  useEffect(() => {
    if (selected.length === 0) {
      setPlaceholderState(placeholder)
      return
    }

    const selectedNames = selected.map((s) => `${s.firstName} ${s.lastName}`).join(", ")
    setPlaceholderState(selectedNames)

  }, [selected, placeholder])

  useEffect(() => {
    if (!showList) return

    return handleKeyPress(['Escape', 'Enter'], closeList)
  }, [showList])

  useEffect(() => {
    if (!showList) return

    return handleClickOutside([textInput, dropdownRef], closeList)
  }, [showList])

  const closeList = () => {
    setShowList(false)
    setFilterText('')

    if (textInput.current) {
      textInput.current.blur()
    }
  }

  const userInput = (e) => {
    if (readOnly) return
    setFilterText(e.target.value.trim().toLowerCase())
  }

  const inputFocus = () => {
    if (textInput.current) {
      const hidingParent = getHidingParent(textInput.current)
      const docHeight = hidingParent ? hidingParent.offsetHeight : document.body.offsetHeight
      const inputRect = textInput.current.getBoundingClientRect()
      const topOffset = inputRect.top - (hidingParent ? hidingParent.getBoundingClientRect().top : 0)
      const showListAbove = topOffset > docHeight / 2
      const listMaxHeight = (showListAbove ? topOffset : docHeight - topOffset - inputRect.height) - 10
      const dropStyle = showListAbove
        ? { maxHeight: listMaxHeight, bottom: '100%' }
        : { maxHeight: listMaxHeight, top: '100%' }
      const sublistMaxHeight = (listMaxHeight / 2) - 28

      setDropStyle(dropStyle)
      setSublistStyle({ maxHeight: sublistMaxHeight })
      setShowList(true)
    }
  }

  const selectUser = (e) => {
    const picked = remove(user => user.id.toString() === e.target.value)([...selectable])
    if (picked) {
      const newSelected = sortBy(['firstName'])([...picked, ...selected])
      setSelected(newSelected)
      onSelected && onSelected(newSelected)
    }
  }

  const removeUser = (e) => {
    const userId = e.target.value
    const picked = selected.find(user => user.id.toString() === userId)

    if (picked) {
      const newSelected = selected.filter(user => user.id.toString() !== userId)
      const newSelectable = sortBy(['firstName'])([...selectable, picked])

      setSelected(newSelected)
      setSelectable(newSelectable)

      onSelected && onSelected(newSelected)
    }
  }

  const filteredSelectable = filterText
    ? filter(user => `${user.firstName} ${user.lastName}`.toLowerCase().includes(filterText))(selectable)
    : selectable

  return (
    <Content $width={width} className={className}>
      <Input
        type='text'
        placeholder={placeholderState}
        ref={textInput}
        value={filterText || ''}
        onChange={userInput}
        onFocus={inputFocus}
      />
      {showList && (
        <Dropdown ref={dropdownRef} className='user-picker-dropdown' style={dropStyle} onBlur={closeList}>
          <>
            <Title>Valda personer</Title>
            {selected.length > 0 ? (
              <UserList style={sublistStyle}>
                {selected.map(user => (
                  <ListItem key={user.id}>
                    <label>
                      <Checkbox
                        value={user.id}
                        onChange={removeUser}
                        checked={true}
                        disabled={readOnly}
                      />
                      <span>{`${user.firstName} ${user.lastName}`}</span>
                    </label>
                  </ListItem>
                ))}
              </UserList>
            ) : (
              <UserList style={sublistStyle}>
                <ListItem className='no-users'>
                  <span>{noSelected}</span>
                </ListItem>
              </UserList>
            )}
          </>

          {!readOnly && (
            <>
              <Title>Välj personer...</Title>
              <UserList style={sublistStyle}>
                {filteredSelectable.length > 0 ? (
                  filteredSelectable.map(user => (
                    <ListItem key={user.id}>
                      <label>
                        <Checkbox
                          value={user.id}
                          onChange={selectUser}
                        />
                        <span>{`${user.firstName} ${user.lastName}`}</span>
                      </label>
                    </ListItem>
                  ))
                ) : (
                  <ListItem className="no-users">
                    <span>{noUsers}</span>
                  </ListItem>
                )}
              </UserList>
            </>
          )}
        </Dropdown>
      )}
    </Content>
  )
}
export default UserPicker