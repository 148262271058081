function handleKeyPress (keys, callback) {
  const keyFunction = (e) => {
    if (keys.includes(e.key)) {
      e.preventDefault()
      callback()
    }
  }

  document.addEventListener('keydown', keyFunction)

  return () => {
    document.removeEventListener('keydown', keyFunction)
  }
}

export default handleKeyPress